/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the collapsibletable component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * React
 */
import * as React from 'react';

import {
    makeStyles,
    Theme,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
    CircularProgress
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';

import { default as pluralize } from 'pluralize';

/*
 * Used to type request state
 */
import { RequestState } from '@ngt/request-utilities';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typings
 */
import * as Dtos from '../../../api/dtos'

/*
 * Used to create a table
 */
import CollapsibleTable, { ICollapsibleTableProps } from '../CollapsibleTable';

/*
 * Used to get patients loaded into the context.
 */
import PatientsContext from '../../../contexts/data/PatientsContext';

/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading'>;

export interface IPatientCollapsibleTableProps<Type extends Dtos.IPatient = Dtos.IPatient> extends OmitData<ICollapsibleTableProps<Type>> {
    data?: Type[];
    title?: string;
    pluralizeTitle?: boolean;
    loading?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientCollapsibleTable = <Type extends Dtos.IPatient>({
    data,
    loading,
    title,
    pluralizeTitle,
    ...tableProps
}: IPatientCollapsibleTableProps<Type>) => {


    const { patients: contextPatients, loadState: patientsLoadState } = React.useContext(PatientsContext);

    const patientsToUse: Type[] = (data === undefined ? contextPatients as Type[] : data) ?? [];

    const patientsLoadingToUse = data === undefined && loading === undefined ?
        patientsLoadState.state === RequestState.None || patientsLoadState.state === RequestState.Pending :
        loading ?? false;

    const titleToUse = title ?? 'Patients';

    return (
        <CollapsibleTable
            data={patientsToUse}
            title={titleToUse}
            pluralizeTitle={pluralizeTitle}
            loading={patientsLoadingToUse}
            {...tableProps}
        />
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientCollapsibleTable;
