/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';

import { faFileMedicalAlt } from '@fortawesome/pro-duotone-svg-icons/faFileMedicalAlt';
import { faFileUser } from '@fortawesome/pro-duotone-svg-icons/faFileUser';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import Breadcrumbs, { IBreadcrumb } from './Breadcrumbs';
import * as Dtos from '../../api/dtos';
import PatientContext from '../../contexts/data/PatientContext';
import { ALL_INSTITUTIONS, ALL_INSTITUTIONS_CODE } from '../../constants/institution';
import InstitutionContext from '../../contexts/data/InstitutionContext';
import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';
import InstitutionsContext from '../../contexts/data/InstitutionsContext';
import { useParams } from 'react-router-dom';
import { DEFAULT_INSTITUTION_BREADCRUMB_CREATOR } from './InstitutionBreadcrumbs';
import FormContext from '../../contexts/data/FormContext';
import FormDefinitionContext from '../../contexts/configuration/FormDefinitionContext';
import EventDefinitionContext from '../../contexts/configuration/EventDefinitionContext';
import EventContext from '../../contexts/data/EventContext';
import { DEFAULT_PATIENT_BREADCRUMB_CREATOR } from './PatientBreadcrumbs';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormBreadcrumbs component.
 */
export interface IFormBreadcrumbsProps {
    institution?: Dtos.IInstitution | null;
    patient?: Dtos.IPatient | null;
    eventDefinition?: Dtos.IEventDefinition | null;
    event?: Dtos.IEvent | null;
    formDefinition?: Dtos.IFormDefinition | null;
    form?: Dtos.IForm | null;
    createInstitutionLink?: (institution?: Dtos.IInstitution | null) => string;
    createPatientLink?: (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null) => string;
    createFormLink?: (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null, eventDefinition?: Dtos.IEventDefinition | null, event?: Dtos.IEvent | null, formDefinition?: Dtos.IFormDefinition | null, form?: Dtos.IForm | null) => string;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

export const DEFAULT_FORM_BREADCRUMB_CREATOR = (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null, eventDefinition?: Dtos.IEventDefinition | null, event?: Dtos.IEvent | null, formDefinition?: Dtos.IFormDefinition | null, form?: Dtos.IForm | null) => {
    if (!patient) {
        if (!institution) {
            return '/registration/';
        }

        return `/registration/${institution?.code ?? ALL_INSTITUTIONS_CODE}/add-patient`
    }

    if (!eventDefinition || !formDefinition) {
        return '/registration';
    }


    return `/registration/${institution?.code ?? ALL_INSTITUTIONS_CODE}/${patient.studyNumber}/${eventDefinition.code}/${event?.repeat ?? 1}/${formDefinition.code}/${form?.repeat}`;
}

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormBreadcrumbs: React.FunctionComponent<IFormBreadcrumbsProps> = ({
    children,
    institution,
    patient,
    eventDefinition,
    event,
    formDefinition,
    form,
    createInstitutionLink,
    createPatientLink,
    createFormLink
}) => {

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);
    const contextInstitution = React.useContext(InstitutionContext);
    const contextInstitutions = React.useContext(InstitutionsContext);
    const contextPatient = React.useContext(PatientContext);
    const contextEventDefinition = React.useContext(EventDefinitionContext);
    const contextEvent = React.useContext(EventContext);
    const contextFormDefinition = React.useContext(FormDefinitionContext);
    const contextForm = React.useContext(FormContext);
    const params = useParams<Record<string, string>>();

    const breadcrumbs = React.useMemo(() => {
        let i = institution;

        if (i === undefined) {
            const institutionCode = params[onlinePatientManagement.routeParameters.institutionCode];
            const institutionId = params[onlinePatientManagement.routeParameters.institutionId];

            if (contextInstitution.institution && (contextInstitution.institution.code === institutionCode || contextInstitution.institution.id?.toString() === institutionId)) {
                i = contextInstitution.institution;
            }
            else if (contextInstitutions.institutions) {
                i = contextInstitutions.institutions.find(inst => inst.code === institutionCode || inst.id?.toString() === institutionId);
            }
        }

        const b: IBreadcrumb[] = [];

        if (i) {
            b.push({
                icon: faBuilding,
                label: i.name,
                to: !!createInstitutionLink ? createInstitutionLink(i) : DEFAULT_INSTITUTION_BREADCRUMB_CREATOR(i)
            });
        }
        else {
            b.push({
                icon: faBuilding,
                label: ALL_INSTITUTIONS,
                to: !!createInstitutionLink ? createInstitutionLink(i) : DEFAULT_INSTITUTION_BREADCRUMB_CREATOR(i)
            });
        }

        const p = patient === undefined ? contextPatient.patient : patient;

        if (p) {
            b.push({
                icon: faUserCircle,
                label: p.studyNumber,
                to: !!createPatientLink ? createPatientLink(i, p) : DEFAULT_PATIENT_BREADCRUMB_CREATOR(i, p)
            });

            const ed = eventDefinition === undefined ? contextEventDefinition.eventDefinition : eventDefinition;
            const e = event === undefined ? contextEvent.event : event;
            const fd = formDefinition === undefined ? contextFormDefinition.formDefinition : formDefinition;
            const f = form === undefined ? contextForm.form : form;

            if (ed && fd) {
                b.push({
                    icon: fd?.type === Dtos.FormType.Pro ? faFileUser : faFileMedicalAlt,
                    label: fd.name ?? 'Form',
                    to: !!createFormLink ? createFormLink(i, p, ed, e, fd, f) : DEFAULT_FORM_BREADCRUMB_CREATOR(i, p, ed, e, fd, f)
                });
            }
        }
        else if (i) {
            b.push({
                icon: faUserCircle,
                label: 'Add Patient',
                to: !!createFormLink ? createFormLink(i, p) : DEFAULT_FORM_BREADCRUMB_CREATOR(i, p)
            });
        }

        return b;
    }, [
            contextInstitution,
            contextInstitutions,
            institution,
            createInstitutionLink,
            params,
            onlinePatientManagement,
            createPatientLink,
            contextPatient.patient,
            contextEventDefinition,
            contextEvent,
            contextFormDefinition,
            contextForm,
            eventDefinition,
            event,
            formDefinition,
            form,
            createFormLink
    ]);

    return (
        <Breadcrumbs
            breadcrumbs={breadcrumbs}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormBreadcrumbs;