/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an form by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { FormReducer, useFormSelector, formSelectors, IFormStore } from '../../store/modules/data/form';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg, OmitFirstEightArgs, OmitFirstSevenArgs, OmitFirstFiveArgs } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * Used to get online patient management settings from context.
 */
import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';
import useAsyncFunction from '../useAsyncFunction';
import { TypedFunction } from '../../utilities/functionTypes';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseFormByCodesActions {
    load: BoundActionCreator<OmitFirstEightArgs<ActionCreators<typeof FormReducer>['loadByCodes']>>;
    save: BoundActionCreator<OmitFirstSevenArgs<ActionCreators<typeof FormReducer>['saveByCodes']>>;
    clear: BoundActionCreator<OmitFirstFiveArgs<ActionCreators<typeof FormReducer>['clearByCodes']>>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<OmitFirstSevenArgs<ActionCreators<typeof FormReducer>['saveByCodes']>>>, Promise<Dtos.IForm | undefined>>
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useFormByCodes = <FormType extends Dtos.IForm>(
    formDefinitionIdentifier: string | number,
    institutionCode?: string | null,
    patientStudyNumber?: string | null,
    eventDefinitionCode?: string | null,
    eventRepeat?: number | null,
    formRepeat?: number | null,
    createPatient?: boolean | null,
    createEvent?: boolean | null,
    createForm?: boolean | null,
    autoLoad?: boolean
): [
    FormType | null,
    IRequestState<Dtos.ResponseStatus>,
    IRequestState<Dtos.ResponseStatus>,
    IUseFormByCodesActions
] => {
    const dispatch = useDispatch();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const formPropertyName = React.useMemo(() => {
        if (typeof formDefinitionIdentifier === 'number') {
            return onlinePatientManagement.formMetadata.find(fm => fm.formDefinitionId === formDefinitionIdentifier)?.propertyName;
        }

        return onlinePatientManagement.formMetadata.find(fm => fm.formDefinitionCode === formDefinitionIdentifier)?.propertyName;
    }, [onlinePatientManagement.formMetadata, formDefinitionIdentifier]);

    if (!formPropertyName) {
        throw new Error("Form Metadata not found for provided Form Definition Identifier.");
    }

    const unboundActions = React.useMemo(() => {
        return onlinePatientManagement.formReducerRegistry.getActions(formPropertyName);
    }, [onlinePatientManagement.formReducerRegistry, formPropertyName]);

    if (!unboundActions) {
        throw new Error("Form Reducer not found for provided Form Definition Identifier.");
    }

    const unboundAsyncSave = useAsyncFunction(unboundActions.saveByCodes, unboundActions.saveByCodesSuccess, unboundActions.saveByCodesFailure);

    const actions: IUseFormByCodesActions = React.useMemo(() => {
        const load = () => unboundActions.loadByCodes(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, createForm);
        load.type = unboundActions.loadByCodes.type;

        const save = (form?: Dtos.IForm) => unboundActions.saveByCodes(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, form);
        save.type = unboundActions.saveByCodes.type;

        const asyncSave = async (form?: Dtos.IForm) => {
            const [, , , , , savedForm] = await unboundAsyncSave([institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, form]);

            return savedForm;
        };

        const clear = () => unboundActions.clearByCodes(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);
        clear.type = unboundActions.clearByCodes.type;

        return {
            ...bindActionCreatorsWithType({
                load,
                save,
                clear
            }, dispatch),
            asyncSave
        };
    }, [unboundActions, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, createForm, dispatch, unboundAsyncSave]);

    const formSelector = React.useCallback((state: IFormStore) => {
        return formSelectors.formByCodes(state, formPropertyName, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);
    }, [formSelectors.formByCodes, formPropertyName, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat]);

    const loadStateSelector = React.useCallback((state: IFormStore) => {
        return formSelectors.loadStateByCodes(state, formPropertyName, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);
    }, [formSelectors.loadStateByCodes, formPropertyName, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat]);

    const saveStateSelector = React.useCallback((state: IFormStore) => {
        return formSelectors.saveStateByCodes(state, formPropertyName, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);
    }, [formSelectors.saveStateByCodes, formPropertyName, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat]);

    const form = useFormSelector(formSelector);

    const loadState = useFormSelector(loadStateSelector);

    const saveState = useFormSelector(saveStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, createForm]);

    return [
        form as FormType | null,
        loadState,
        saveState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useFormByCodes;