/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the functions to debounce async functions.
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Import - External
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------------------------
 */

type PromiseArg<T> = T extends PromiseLike<infer U> ? U : T


const asyncDebounce = <T extends (...args: any) => Promise<any>>(inner: T, ms: number = 0) => {
    let timer: any = null;
    let resolves: any = [];

    return function (...args: Parameters<T>) {
        // Run the function after a certain amount of time
        clearTimeout(timer);

        timer = setTimeout(async () => {
            let relatedResolves = [...resolves];
            resolves = [];

            // Get the result of the inner function, then apply it to the resolve function of
            // each promise that has been created since the last time the inner function was run
            let result = await inner(...args);

            relatedResolves.forEach((r: (data: PromiseArg<ReturnType<T>>) => void) => r(result));
        }, ms);

        return new Promise<PromiseArg<ReturnType<T>>>(r => resolves.push(r));
    };
}


/*
 * --------------------------------------------------------------------------------
 * Default Export
 * --------------------------------------------------------------------------------
 */

export default asyncDebounce;
