/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an institutions by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { InstitutionsReducer, institutionsActions, useInstitutionsSelector, institutionsSelectors, IInstitutionsStore } from '../../store/modules/data/institutions';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg, OmitFirstThreeArgs } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseInstitutionsByCodeActions {
    load: BoundActionCreator<OmitFirstThreeArgs<typeof institutionsActions.loadByCodes>>;
    clear: BoundActionCreator<OmitFirstThreeArgs<typeof institutionsActions.clearByCodes>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useInstitutionsByCodes = <InstitutionsType extends Dtos.IInstitution>(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, autoLoad?: boolean): [
    InstitutionsType[] | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseInstitutionsByCodeActions
] => {
    const dispatch = useDispatch();

    const actions: IUseInstitutionsByCodeActions = React.useMemo(() => {
        const load = () => institutionsActions.loadByCodes(masterGroupCode, collaboratingGroupCode, countryCode);
        load.type = institutionsActions.loadByCodes.type;

        const clear = () => institutionsActions.clearByCodes(masterGroupCode, collaboratingGroupCode, countryCode);
        clear.type = institutionsActions.clearByCodes.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [institutionsActions, masterGroupCode, collaboratingGroupCode, countryCode, dispatch]);

    const institutionsSelector = React.useCallback((state: IInstitutionsStore) => {
        return institutionsSelectors.institutionsByCodes(state, masterGroupCode, collaboratingGroupCode, countryCode);
    }, [institutionsSelectors.institutionsByCodes, masterGroupCode, collaboratingGroupCode, countryCode]);

    const loadStateSelector = React.useCallback((state: IInstitutionsStore) => {
        return institutionsSelectors.loadStateByCodes(state, masterGroupCode, collaboratingGroupCode, countryCode);
    }, [institutionsSelectors.loadStateByCodes, masterGroupCode, collaboratingGroupCode, countryCode]);

    const institutions = useInstitutionsSelector(institutionsSelector);

    const loadState = useInstitutionsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, masterGroupCode, collaboratingGroupCode, countryCode]);

    return [
        institutions as InstitutionsType[] | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useInstitutionsByCodes;