/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the eventDefinitions reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IEventDefinitionsState {
    eventDefinitions: Dtos.IEventDefinition[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IEventDefinitionsStore {
    eventDefinitions: IEventDefinitionsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createContext = (eventDefinitionId?: number, collaboratingGroupId?: number, countryId?: number) => {
    return `${(eventDefinitionId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}`
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all eventDefinitions reducer state changes and actions. 
 */
export class EventDefinitionsReducer extends ImmerReducer<IEventDefinitionsState>
{
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(eventDefinitions?: Dtos.IEventDefinition[]) {
        this.draftState.eventDefinitions = eventDefinitions ? eventDefinitions : null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: Dtos.ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialEventDefinitionsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialEventDefinitionsState: IEventDefinitionsState = {
    eventDefinitions: null,
    loadState: {
        state: RequestState.None
    }
}

export const eventDefinitionsActions = createActionCreators(EventDefinitionsReducer);
export const eventDefinitionsReducer = createReducerFunction(EventDefinitionsReducer, initialEventDefinitionsState);

const createEventDefinitionsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.EventDefinitionGetCollection());
    }
});

const createEventDefinitionsLogic = (api: ReturnType<typeof createEventDefinitionsApi>) => {
    const logic = {
        load: createLogic<IEventDefinitionsStore, {}, undefined, string, ReturnType<typeof eventDefinitionsActions.load>>({
            type: eventDefinitionsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(eventDefinitionsActions.loadSuccess(
                        response.eventDefinitions
                    ));
                }
                catch (error) {
                    dispatch(eventDefinitionsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

export const useEventDefinitionsSelector: TypedUseSelectorHook<IEventDefinitionsStore> = useSelector;

export const eventDefinitionsSelectors = {
    eventDefinitions: (state: IEventDefinitionsStore) => state.eventDefinitions.eventDefinitions,
    loadState: (state: IEventDefinitionsStore) => state.eventDefinitions.loadState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerEventDefinitionsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createEventDefinitionsApi(client);

    const logic = createEventDefinitionsLogic(api);

    reducerRegistry.register('eventDefinitions', eventDefinitionsReducer as Reducer, logic as any);
};

export default registerEventDefinitionsReducer;