/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams, match } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load institutions into the InstitutionsContext
 */
import InstitutionsByCodesResolver from '../../resolver/data/InstitutionsByCodesResolver';

/**
 * Used to get OPMS settings
 */
import OnlinePatientManagementContext from '../../../contexts/OnlinePatientManagementContext';

import { ALL_COUNTRIES_CODE } from '../../../constants/country';

import { ALL_COLLABORATING_GROUPS_CODE } from '../../../constants/collaboratingGroup';

import { ALL_MASTER_GROUPS_CODE } from '../../../constants/masterGroup';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsInstitutionsRoute component.
 */
export interface IInstitutionsByCodesRouteProps extends RouteProps {
    masterGroupCode?: string;
    collaboratingGroupCode?: string;
    countryCode?: string;

    computedMatch?: match<Record<string, string>>;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const InstitutionsByCodesRoute: React.FunctionComponent<IInstitutionsByCodesRouteProps> = ({
    masterGroupCode,
    collaboratingGroupCode,
    countryCode,
    resolveBeforeLoad,
    children,
    ...routeProps
}) => {
    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);
    const params = routeProps.computedMatch?.params ?? useParams<Record<string, string>>();
    const paramMasterGroupCode = params[onlinePatientManagement.routeParameters.masterGroupCode];
    const paramCollaboratingGroupCode = params[onlinePatientManagement.routeParameters.collaboratingGroupCode];
    const paramCountryCode = params[onlinePatientManagement.routeParameters.countryCode];

    const masterGroupCodeToUse = paramMasterGroupCode ?? masterGroupCode ?? null;
    const collaboratingGroupCodeToUse = paramCollaboratingGroupCode ?? collaboratingGroupCode ?? null;
    const countryCodeToUse = paramCountryCode ?? countryCode ?? null;

    return <Route {...routeProps}>
        <InstitutionsByCodesResolver
            masterGroupCode={masterGroupCodeToUse === ALL_MASTER_GROUPS_CODE ? null : masterGroupCodeToUse}
            collaboratingGroupCode={collaboratingGroupCodeToUse === ALL_COLLABORATING_GROUPS_CODE ? null : collaboratingGroupCodeToUse}
            countryCode={countryCodeToUse === ALL_COUNTRIES_CODE ? null : countryCodeToUse}
            resolveBeforeLoad={resolveBeforeLoad}>
            {children}
        </InstitutionsByCodesResolver>
    </Route>
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default InstitutionsByCodesRoute;