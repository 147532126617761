
/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { ScreeningLog } from '../api/screeningLog';
import { Theme, makeStyles, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog, Typography } from '@material-ui/core';
import ScreeningLogForm from './ScreeningLogForm';
import ScreeningLogContext from '../context/ScreeningLogContext';
import useScreeningLogForm from '../hooks/useScreeningLogForm';
import { IFormLabel } from '@ngt/opms';
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export enum ScreeningLogDialogMode {
    cancel = 0,
    create = 1,
    update = 2,
    delete = 3
}

interface IScreeningLogDialogProps<TScreeningLog extends ScreeningLog = ScreeningLog> {
    mode?: ScreeningLogDialogMode,
    formCancel?: () => void,
    formFieldGroup?: any,
    formLabels?:  IFormLabel[]
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogTitleColor: {
        color: theme.palette.secondary.main
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningLogDialog = ({
    mode,
    formCancel,
    formFieldGroup,
    formLabels
}: IScreeningLogDialogProps) => {

    const classes = useStyles();

    const modeStr = React.useMemo(() => {
        switch (mode) {
            case ScreeningLogDialogMode.create: { return 'Add'; }
            case ScreeningLogDialogMode.update: { return 'Update'; }
            case ScreeningLogDialogMode.delete: { return 'Delete'; }
            default: { return ''; }
        }
    }, [mode]);

    const showDialog = React.useMemo(() => {
        return mode ?? ScreeningLogDialogMode.cancel > ScreeningLogDialogMode.cancel ? true : false;
    }, [mode]);

    const {
        handleSubmit,
        onFormCancel,
        onFormSubmit,
        onFormDelete,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate
    } = useScreeningLogForm({ formType: undefined, afterFormSave: undefined, onCancel: formCancel, readOnly: false });

    return (
        <>
            {
                !!mode && <Dialog open={showDialog} onClose={undefined} aria-labelledby="form-dialog-title" fullWidth={true}>
                    <DialogTitle id="form-dialog-title" className={classes.dialogTitleColor}>{modeStr} Screening Log</DialogTitle>
                    <DialogContent>
                        <ScreeningLogForm
                            formDisable={mode === ScreeningLogDialogMode.delete}
                            formLabels={formLabels}
                            formFieldGroup={formFieldGroup}
                            formMode={mode}
                            onFormSubmit={onFormSubmit}
                            handleSubmit={handleSubmit}
                            onFormDelete={onFormDelete}
                            onFormCancel={onFormCancel}
                            onSubmitFailed={onFormSubmitFailure}
                            onSubmitValidationFailure={onFormSubmitValidationFailure}
                            validate={validate}
                        />
                    </DialogContent>
                </Dialog>

            }
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningLogDialog;