/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae summary component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles, darken } from '@material-ui/core/styles';

import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableRowProps
} from '@material-ui/core';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import {
    RouteLoading,
    CollapsibleTable
} from '@ngt/opms';

import { RequestState } from '@ngt/request-utilities';

import { MTableBodyRow } from 'material-table';

import { blue } from '@material-ui/core/colors';

import classNames from 'classnames';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';
import Markdown from 'markdown-to-jsx';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeSummaryProps {
}

interface IOcGroup<T extends Dtos.ISaeSubform = any> {
    name: string;
    title: string;
    data: T[];
    columns: Array<Column<T>>;
    createRowProps?: ((data: any, index: number, level: number) => TableRowProps);
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

//const highlightColor = '#d9edf7';
const highlightColor = blue[100];

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    blueBgColor: {
        backgroundColor: `${highlightColor} !important`,
        minHeight: '20px',
        '&:nth-child(odd)': {
            background: `${darken(highlightColor, 0.07)} !important`
        }
    },
    cellHeight: {
        minHeight: '20px'
    },
    textWrap: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    },
    hidden: {
        display: 'none'
    },
    legend: {
        backgroundColor: darken(highlightColor, 0.07),
        height: '15px',
        width: '15px',
        position: 'absolute',
        top: '50%',
        marginTop: '-7.5px'
    },
    legendContainer: {
        position: 'relative',
        marginTop: theme.spacing(0),
    },
    legendInfo: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1),
        lineHeight: '1.0!important'
    },
    mb3: {
        marginBottom: theme.spacing(3),
    },
    mt4: {
        marginTop: theme.spacing(4)
    },
    mt3: {
        marginTop: theme.spacing(3),
    },
    mt2: {
        marginTop: theme.spacing(2),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    table: {
        marginTop: theme.spacing(2),
        borderTop: '1px',

    },
    tableHeader: {
        fontWeight: 'bold',
        width: '50%'
    },
    tableHeaderColor: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    row: {
        '&:nth-child(odd)': {
            background: theme.palette.grey[100]
        }
    },
    tableMedicalReview: {
        border: '1px solid rgba(224, 224, 224, 1)'
    },
    fontSize: {
        fontSize: '0.875rem'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const SaeSubform: React.FunctionComponent<IOcGroup> = ({
    data,
    columns,
    name,
    title,
    createRowProps
}) => {
    const classes = useStyles();

    return (
        <div className={classes.mt3}>
            <CollapsibleTable
                title={title}
                loading={false}
                data={data ?? []}
                columns={columns}
                entityName="Record"
                components={{
                    Row: (props) => {
                        const rowProps = createRowProps ? createRowProps(props.data, props.index, props.level) : {};

                        return <MTableBodyRow {...props} {...rowProps} />
                    }
                }}
            />
        </div>
    );
}

const SaeSummary: React.FunctionComponent<ISaeSummaryProps> = () => {
    const classes = useStyles();

    const { sae, loadState: saeLoadState } = React.useContext(SaeContext);

    const groups: IOcGroup[] = React.useMemo(() => {
        if (!sae) {
            return [];
        }

        const g: IOcGroup[] = [];

        Object
            .keys(sae.saeFormItems)
            .filter(key => key.includes('.'))
            .forEach(key => {
                const index = key.indexOf('.');
                const groupName = key.substring(0, index);

                const itemName = key.substring(index + 1);

                let group = g.find(x => x.name === groupName)

                if (!group) {

                    group = { title: sae.saeFormItems[groupName], name: groupName, data: (sae as any).form[groupName] ?? (sae as any)[groupName], columns: [] };

                    g.push(group);
                }

                group.createRowProps = (data: any, index: number, level: number) => {

                    let ecrfIndex: number = 0;

                    sae.ecrfs
                        .forEach((ecrf, i) => {
                            if ((ecrf as any)[groupName]?.length > 0) {
                                ecrfIndex = i;
                                return;
                            }
                        });

                    return {
                        className: classNames({ [classes.blueBgColor]: (sae as any).ecrfs[ecrfIndex][groupName]?.length > index && ecrfIndex > 0, [classes.row]: (sae as any).ecrfs[ecrfIndex][groupName]?.length <= index })
                    }
                };

                group.columns.push({
                    field: itemName,
                    title: sae.saeFormItems[key],
                    render: (data, type) => !!sae.form && !!sae.ecrfs && <div className={`${classes.cellHeight} ${classes.textWrap}`}>
                        {getTransformedValue(data[itemName]) ?? ''}
                    </div>
                })
            });

        return g;
    }, [sae]);

    if (saeLoadState.state === RequestState.None || saeLoadState.state === RequestState.Pending) {
        return <RouteLoading />;
    }

    return (
        <>
            <div className={classes.legendContainer}>
                <div className={classes.legend} />

                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.legendInfo}
                >
                    {'Latest value updated in the latest follow up'}
                </Typography>
            </div>

            <Typography
                variant="h2"
                color="primary"
                className={classes.mt1}
            >
                Current Serious Adverse Event Entry
            </Typography>

            <TableContainer className={classes.table} component={Paper} elevation={3}>
                <Table>
                    <TableBody>
                        {
                            !!sae && Object.keys(sae.saeFormItems!!)?.map((itemKey, itemIndex) => {

                                if (itemKey.includes('.')) {
                                    return
                                } else if (itemIndex + 1 < Object.keys(sae.saeFormItems!!)?.length &&
                                    Object.keys(sae.saeFormItems!!)[itemIndex + 1].includes('.')) {
                                    return
                                }

                                return <TableRow key={itemKey} className={hasChanged(sae.ecrfs, itemKey) ? classes.blueBgColor : classes.row}>
                                    <TableCell className={classes.tableHeader} component="th" scope="row">
                                        {sae.saeFormItems[itemKey]}
                                    </TableCell>
                                    <TableCell align="left">
                                        {
                                            <Typography variant="body2">
                                                <Markdown>
                                                    {getTransformedValue((sae as any).form[itemKey]) ?? ''}
                                                </Markdown>
                                            </Typography>
                                        }
                                    </TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {
                sae?.ecrfs && sae?.ecrfs.length > 1 &&
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.mt4}
                >
                    Initial Serious Adverse Event Entry
                    </Typography>
            }

            {
                sae?.ecrfs && sae?.ecrfs.length > 1 &&
                <TableContainer className={classes.table} component={Paper} elevation={3}>
                    <Table>
                        <TableBody>
                            {
                                !!sae && Object.keys(sae.saeFormItems!!)?.map((itemKey, itemIndex) => {
                                    if (itemKey.includes('.')) {
                                        return
                                    } else if (itemIndex + 1 < Object.keys(sae.saeFormItems!!)?.length &&
                                        Object.keys(sae.saeFormItems!!)[itemIndex + 1].includes('.')) {
                                        return
                                    }

                                    return <TableRow key={itemKey} className={classes.row}>
                                        <TableCell className={classes.tableHeader} component="th" scope="row">
                                            {sae.saeFormItems[itemKey]}
                                        </TableCell>
                                        <TableCell align="left">
                                            {
                                                getTransformedValue((sae as any).ecrfs[0][itemKey])
                                            }
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                !!sae && !!groups && groups.map((group, groupNumber) => {
                    return <SaeSubform key={group.name} {...group} />
                })
            }
        </>
    );
}

const hasChanged = (saes: Dtos.ISaeForm[], itemKey: string): boolean => {
    if (saes.length < 2) {
        return false;
    }

    if (getTransformedValue((saes as any)[saes.length - 1][itemKey]) !== '' && getTransformedValue((saes as any)[saes.length - 1][itemKey]) !== getTransformedValue((saes as any)[saes.length - 2][itemKey])) {
        return true;
    }

    return false;
}

const getTransformedValue = (property: any): string | null => {

    if (property == null) {
        return '';
    }

    if (typeof property === 'boolean') {
        return (property === true ? 'Yes' : '');
    } else if (typeof property === 'string') {
        if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{7}Z/.test(property)) {
            return DateTime.fromISO(property).toFormat('dd/MM/yyyy, hh:mm a');
        } else if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{7}/.test(property)) {
            return DateTime.fromISO(property).toFormat('dd/MM/yyyy');
        }
    } else if (typeof property === 'object') {
        // For lookup values
        return property.value;
    }

    return property?.toString().replace(/\<br\/\>\<br\/\>$/, '');
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeSummary;