/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the collaboratingGroup reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualCollaboratingGroupState {
    collaboratingGroup: Dtos.ICollaboratingGroup | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface ICollaboratingGroupState {
    byId: Record<number, IIndividualCollaboratingGroupState>;
    byCode: Record<string, IIndividualCollaboratingGroupState>;
}

export interface ICollaboratingGroupStore {
    collaboratingGroup: ICollaboratingGroupState;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all collaboratingGroup reducer state changes and actions. 
 */
export class CollaboratingGroupReducer extends ImmerReducer<ICollaboratingGroupState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualCollaboratingGroupState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, collaboratingGroup?: Dtos.ICollaboratingGroup) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualCollaboratingGroupState };
        }

        this.draftState.byId[id].collaboratingGroup = collaboratingGroup ? collaboratingGroup : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualCollaboratingGroupState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public loadByCode(code: string) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualCollaboratingGroupState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByCodeSuccess(code: string, collaboratingGroup?: Dtos.ICollaboratingGroup) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualCollaboratingGroupState };
        }

        this.draftState.byCode[code].collaboratingGroup = collaboratingGroup ? collaboratingGroup : null;

        this.draftState.byCode[code].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodeFailure(code: string, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byCode[code]) {
            this.draftState.byCode[code] = { ...initialIndividualCollaboratingGroupState };
        }

        this.draftState.byCode[code].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public clearByCode(code: string) {
        if (this.draftState.byCode[code]) {
            delete this.draftState.byCode[code];
        }
    }

    public clearAll() {
        this.draftState = { ...initialCollaboratingGroupState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualCollaboratingGroupState: IIndividualCollaboratingGroupState = {
    collaboratingGroup: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialCollaboratingGroupState: ICollaboratingGroupState = {
    byCode: {},
    byId: {}
}

export const collaboratingGroupActions = createActionCreators(CollaboratingGroupReducer);
export const collaboratingGroupReducer = createReducerFunction(CollaboratingGroupReducer, initialCollaboratingGroupState);

const createCollaboratingGroupApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.CollaboratingGroupGetSingleById({ id }));
    },
    loadByCode: (code: string) => {
        return client.get(new Dtos.CollaboratingGroupGetSingleByCode({ code }));
    }
});

export const createCollaboratingGroupLogic = (api: ReturnType<typeof createCollaboratingGroupApi>) => {
    const logics = {
        loadById: createLogic<ICollaboratingGroupStore, {}, undefined, string, ReturnType<typeof collaboratingGroupActions.loadById>>({
            type: collaboratingGroupActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(collaboratingGroupActions.loadByIdSuccess(
                        action.payload,
                        response.collaboratingGroup
                    ));
                }
                catch (error) {
                    dispatch(collaboratingGroupActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCode: createLogic<any, {}, undefined, string, ReturnType<typeof collaboratingGroupActions.loadByCode>>({
            type: collaboratingGroupActions.loadByCode.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadByCode(action.payload);
                    dispatch(collaboratingGroupActions.loadByCodeSuccess(
                        action.payload,
                        response.collaboratingGroup
                    ));
                }
                catch (error) {
                    dispatch(collaboratingGroupActions.loadByCodeFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByCode,
        logics.loadById
    ]
}

export const useCollaboratingGroupSelector: TypedUseSelectorHook<ICollaboratingGroupStore> = useSelector;

export const collaboratingGroupSelectors = {
    collaboratingGroupById: (state: ICollaboratingGroupStore, id: number) => state.collaboratingGroup.byId[id]?.collaboratingGroup ?? null,
    loadStateById: (state: ICollaboratingGroupStore, id: number) => state.collaboratingGroup.byId[id]?.loadState ?? initialIndividualCollaboratingGroupState.loadState,
    collaboratingGroupByCode: (state: ICollaboratingGroupStore, code: string) => state.collaboratingGroup.byCode[code]?.collaboratingGroup ?? null,
    loadStateByCode: (state: ICollaboratingGroupStore, code: string) => state.collaboratingGroup.byCode[code]?.loadState ?? initialIndividualCollaboratingGroupState.loadState,
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerCollaboratingGroupReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createCollaboratingGroupApi(client);

    const logic = createCollaboratingGroupLogic(api);

    reducerRegistry.register('collaboratingGroup', collaboratingGroupReducer as Reducer, logic as any);
};

export default registerCollaboratingGroupReducer;