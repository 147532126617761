/* Options:
Date: 2020-04-08 15:21:25
Version: 5.51
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://ctcae.breastcancertrials.org.au

//GlobalNamespace: 
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1, EmitDefaultValue=false)
    public errorCode: string;

    // @DataMember(Order=2, EmitDefaultValue=false)
    public fieldName: string;

    // @DataMember(Order=3, EmitDefaultValue=false)
    public message: string;

    // @DataMember(Order=4, EmitDefaultValue=false)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class Version
{
    public id: number;
    public code: string;
    public number: number;
    public name: string;

    public constructor(init?: Partial<Version>) { (Object as any).assign(this, init); }
}

export class Category
{
    public id: number;
    public versionId: number;
    public name: string;

    public constructor(init?: Partial<Category>) { (Object as any).assign(this, init); }
}

export class Term
{
    public id: number;
    public categoryId: number;
    public termId: number;
    public name: string;
    public description: string;
    public note: string;
    public medDraCode: string;
    public specifyOther: boolean;

    public constructor(init?: Partial<Term>) { (Object as any).assign(this, init); }
}

export class Grade
{
    public id: number;
    public termId: number;
    public name: string;
    public description: string;

    public constructor(init?: Partial<Grade>) { (Object as any).assign(this, init); }
}

export class SearchResult
{
    public version: Version;
    public category: Category;
    public term: Term;
    public subterm: Term;
    public grade: Grade;

    public constructor(init?: Partial<SearchResult>) { (Object as any).assign(this, init); }
}

export class GetCategoriesResponse
{
    public responseStatus: ResponseStatus;
    public version: Version;
    public categories: Category[];

    public constructor(init?: Partial<GetCategoriesResponse>) { (Object as any).assign(this, init); }
}

export class GetCategoryResponse
{
    public responseStatus: ResponseStatus;
    public version: Version;
    public category: Category;

    public constructor(init?: Partial<GetCategoryResponse>) { (Object as any).assign(this, init); }
}

export class GetGradesResponse
{
    public responseStatus: ResponseStatus;
    public version: Version;
    public category: Category;
    public parentTerm: Term;
    public term: Term;
    public grades: Grade[];

    public constructor(init?: Partial<GetGradesResponse>) { (Object as any).assign(this, init); }
}

export class GetGradeResponse
{
    public responseStatus: ResponseStatus;
    public version: Version;
    public category: Category;
    public parentTerm: Term;
    public term: Term;
    public grade: Grade;

    public constructor(init?: Partial<GetGradeResponse>) { (Object as any).assign(this, init); }
}

export class HelloResponse
{
    public result: string;

    public constructor(init?: Partial<HelloResponse>) { (Object as any).assign(this, init); }
}

export class SearchResponse
{
    public responseStatus: ResponseStatus;
    public results: SearchResult[];
    public totalCount: number;

    public constructor(init?: Partial<SearchResponse>) { (Object as any).assign(this, init); }
}

export class GetSubtermsAndGradesResponse
{
    public responseStatus: ResponseStatus;
    public version: Version;
    public category: Category;
    public parentTerm: Term;
    public term: Term;
    public terms: Term[];
    public grades: Grade[];

    public constructor(init?: Partial<GetSubtermsAndGradesResponse>) { (Object as any).assign(this, init); }
}

export class GetTermsResponse
{
    public responseStatus: ResponseStatus;
    public version: Version;
    public category: Category;
    public parentTerm: Term;
    public terms: Term[];

    public constructor(init?: Partial<GetTermsResponse>) { (Object as any).assign(this, init); }
}

export class GetTermResponse
{
    public responseStatus: ResponseStatus;
    public version: Version;
    public category: Category;
    public parentTerm: Term;
    public term: Term;

    public constructor(init?: Partial<GetTermResponse>) { (Object as any).assign(this, init); }
}

export class GetVersionsResponse
{
    public responseStatus: ResponseStatus;
    public versions: Version[];

    public constructor(init?: Partial<GetVersionsResponse>) { (Object as any).assign(this, init); }
}

export class GetVersionResponse
{
    public responseStatus: ResponseStatus;
    public version: Version;

    public constructor(init?: Partial<GetVersionResponse>) { (Object as any).assign(this, init); }
}

/**
* Categories
*/
// @Route("/categories/by-version-id/{VersionId}", "GET")
// @Api(Description="Categories")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCategoriesByVersionId implements IReturn<GetCategoriesResponse>
{
    /**
    * The ID of the version of CTCAE Categories to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the version of CTCAE Categories to load", Format="int32", Name="VersionID", ParameterType="path")
    public versionId: number;

    public constructor(init?: Partial<GetCategoriesByVersionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCategoriesResponse(); }
    public getTypeName() { return 'GetCategoriesByVersionId'; }
}

/**
* Categories
*/
// @Route("/category/by-id/{Id}", "GET")
// @Api(Description="Categories")
// @ApiResponse(Description="Could not locate what you are looking for", StatusCode=404)
// @ApiResponse(Description="Your request was not understood", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetCategoryById implements IReturn<GetCategoryResponse>
{
    /**
    * The ID of the CTCAE Category to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the CTCAE Category to load", Format="int32", Name="ID", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetCategoryById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCategoryResponse(); }
    public getTypeName() { return 'GetCategoryById'; }
}

/**
* Grades
*/
// @Route("/grades/by-term-id/{TermId}", "GET")
// @Api(Description="Grades")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGradesByTermId implements IReturn<GetGradesResponse>
{
    /**
    * The ID of the term of CTCAE Grades to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the term of CTCAE Grades to load", Format="int32", Name="TermID", ParameterType="path")
    public termId: number;

    public constructor(init?: Partial<GetGradesByTermId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGradesResponse(); }
    public getTypeName() { return 'GetGradesByTermId'; }
}

/**
* Grades
*/
// @Route("/grade/by-id/{Id}", "GET")
// @Api(Description="Grades")
// @ApiResponse(Description="Could not locate what you are looking for", StatusCode=404)
// @ApiResponse(Description="Your request was not understood", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetGradeById implements IReturn<GetGradeResponse>
{
    /**
    * The ID of the CTCAE Grade to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the CTCAE Grade to load", Format="int32", Name="ID", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetGradeById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGradeResponse(); }
    public getTypeName() { return 'GetGradeById'; }
}

// @Route("/hello")
// @Route("/hello/{Name}")
export class Hello implements IReturn<HelloResponse>
{
    public name: string;

    public constructor(init?: Partial<Hello>) { (Object as any).assign(this, init); }
    public createResponse() { return new HelloResponse(); }
    public getTypeName() { return 'Hello'; }
}

/**
* Terms
*/
// @Route("/search/by-version-id/{VersionId}", "GET")
// @Api(Description="Terms")
// @ApiResponse(Description="Could not locate what you are looking for", StatusCode=404)
// @ApiResponse(Description="Your request was not understood", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class Search implements IReturn<SearchResponse>
{
    /**
    * The ID of the version of CTCAE Categories to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the version of CTCAE Categories to load", Format="int32", Name="VersionID", ParameterType="path")
    public versionId: number;

    /**
    * The search query
    */
    // @ApiMember(DataType="string", Description="The search query", Name="Query", ParameterType="query")
    public query: string;

    /**
    * Number of results to return
    */
    // @ApiMember(DataType="int", Description="Number of results to return", Format="int32", Name="Limit", ParameterType="query")
    public limit: number;

    /**
    * The set of results to return
    */
    // @ApiMember(DataType="int", Description="The set of results to return", Format="int32", Name="Set", ParameterType="query")
    public set: number;

    public constructor(init?: Partial<Search>) { (Object as any).assign(this, init); }
    public createResponse() { return new SearchResponse(); }
    public getTypeName() { return 'Search'; }
}

/**
* Subterms and Grades
*/
// @Route("/subterms-and-grades/by-term-id/{TermId}", "GET")
// @Api(Description="Subterms and Grades")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetSubtermsAndGradesByTermId implements IReturn<GetSubtermsAndGradesResponse>
{
    /**
    * The ID of the term of CTCAE Grades and Subterms to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the term of CTCAE Grades and Subterms to load", Format="int32", Name="TermID", ParameterType="path")
    public termId: number;

    public constructor(init?: Partial<GetSubtermsAndGradesByTermId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSubtermsAndGradesResponse(); }
    public getTypeName() { return 'GetSubtermsAndGradesByTermId'; }
}

/**
* Terms
*/
// @Route("/terms/by-category-id/{CategoryId}", "GET")
// @Api(Description="Terms")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTermsByCategoryId implements IReturn<GetTermsResponse>
{
    /**
    * The ID of the category of CTCAE Terms to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the category of CTCAE Terms to load", Format="int32", Name="CategoryID", ParameterType="path")
    public categoryId: number;

    public constructor(init?: Partial<GetTermsByCategoryId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTermsResponse(); }
    public getTypeName() { return 'GetTermsByCategoryId'; }
}

/**
* Terms
*/
// @Route("/terms/by-term-id/{TermId}", "GET")
// @Api(Description="Terms")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTermsByTermId implements IReturn<GetTermsResponse>
{
    /**
    * The ID of the term of CTCAE Terms to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the term of CTCAE Terms to load", Format="int32", Name="TermID", ParameterType="path")
    public termId: number;

    public constructor(init?: Partial<GetTermsByTermId>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTermsResponse(); }
    public getTypeName() { return 'GetTermsByTermId'; }
}

/**
* Terms
*/
// @Route("/term/by-id/{Id}", "GET")
// @Api(Description="Terms")
// @ApiResponse(Description="Could not locate what you are looking for", StatusCode=404)
// @ApiResponse(Description="Your request was not understood", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetTermById implements IReturn<GetTermResponse>
{
    /**
    * The ID of the CTCAE Term to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the CTCAE Term to load", Format="int32", Name="ID", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetTermById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTermResponse(); }
    public getTypeName() { return 'GetTermById'; }
}

/**
* Versions
*/
// @Route("/versions", "GET")
// @Api(Description="Versions")
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetVersions implements IReturn<GetVersionsResponse>
{

    public constructor(init?: Partial<GetVersions>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVersionsResponse(); }
    public getTypeName() { return 'GetVersions'; }
}

/**
* Versions
*/
// @Route("/version/by-id/{Id}", "GET")
// @Api(Description="Versions")
// @ApiResponse(Description="Could not locate what you are looking for", StatusCode=404)
// @ApiResponse(Description="Your request was not understood", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetVersionById implements IReturn<GetVersionResponse>
{
    /**
    * The ID of the CTCAE Version to load
    */
    // @ApiMember(DataType="integer", Description="The ID of the CTCAE Version to load", Format="int32", Name="ID", ParameterType="path")
    public id: number;

    public constructor(init?: Partial<GetVersionById>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVersionResponse(); }
    public getTypeName() { return 'GetVersionById'; }
}

/**
* Versions
*/
// @Route("/version/by-code/{Code}", "GET")
// @Api(Description="Versions")
// @ApiResponse(Description="Could not locate what you are looking for", StatusCode=404)
// @ApiResponse(Description="Your request was not understood", StatusCode=400)
// @ApiResponse(Description="Oops, something broke", StatusCode=500)
export class GetVersionByCode implements IReturn<GetVersionResponse>
{
    /**
    * The code of the CTCAE Version to load
    */
    // @ApiMember(DataType="integer", Description="The code of the CTCAE Version to load", Format="int32", Name="Code", ParameterType="path")
    public code: string;

    public constructor(init?: Partial<GetVersionByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVersionResponse(); }
    public getTypeName() { return 'GetVersionByCode'; }
}

