/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the masterGroups reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IMasterGroupsState {
    masterGroups: Dtos.IMasterGroup[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IMasterGroupsStore {
    masterGroups: IMasterGroupsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createContext = (masterGroupId?: number, collaboratingGroupId?: number, countryId?: number) => {
    return `${(masterGroupId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}`
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all masterGroups reducer state changes and actions. 
 */
export class MasterGroupsReducer extends ImmerReducer<IMasterGroupsState>
{
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(masterGroups?: Dtos.IMasterGroup[]) {
        this.draftState.masterGroups = masterGroups ? masterGroups : null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: Dtos.ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialMasterGroupsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialMasterGroupsState: IMasterGroupsState = {
    masterGroups: null,
    loadState: {
        state: RequestState.None
    }
}

export const masterGroupsActions = createActionCreators(MasterGroupsReducer);
export const masterGroupsReducer = createReducerFunction(MasterGroupsReducer, initialMasterGroupsState);

const createMasterGroupsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.MasterGroupGetCollection());
    }
});

const createMasterGroupsLogic = (api: ReturnType<typeof createMasterGroupsApi>) => {
    const logic = {
        load: createLogic<IMasterGroupsStore, {}, undefined, string, ReturnType<typeof masterGroupsActions.load>>({
            type: masterGroupsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(masterGroupsActions.loadSuccess(
                        response.masterGroups
                    ));
                }
                catch (error) {
                    dispatch(masterGroupsActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

export const useMasterGroupsSelector: TypedUseSelectorHook<IMasterGroupsStore> = useSelector;

export const masterGroupsSelectors = {
    masterGroups: (state: IMasterGroupsStore) => state.masterGroups.masterGroups,
    loadState: (state: IMasterGroupsStore) => state.masterGroups.loadState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerMasterGroupsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createMasterGroupsApi(client);

    const logic = createMasterGroupsLogic(api);

    reducerRegistry.register('masterGroups', masterGroupsReducer as Reducer, logic as any);
};

export default registerMasterGroupsReducer;