/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an masterGroup by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { MasterGroupReducer, masterGroupActions, useMasterGroupSelector, masterGroupSelectors, IMasterGroupStore } from '../../store/modules/data/masterGroup';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseMasterGroupByCodeActions {
    load: BoundActionCreator<OmitFirstArg<typeof masterGroupActions.loadByCode>>;
    clear: BoundActionCreator<OmitFirstArg<typeof masterGroupActions.clearByCode>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useMasterGroupByCode = <MasterGroupType extends Dtos.IMasterGroup>(code: string, autoLoad?: boolean): [
    MasterGroupType | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseMasterGroupByCodeActions
] => {
    const dispatch = useDispatch();

    const actions: IUseMasterGroupByCodeActions = React.useMemo(() => {
        const load = () => masterGroupActions.loadByCode(code);
        load.type = masterGroupActions.loadByCode.type;

        const clear = () => masterGroupActions.clearByCode(code);
        clear.type = masterGroupActions.clearByCode.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [masterGroupActions, code, dispatch]);

    const masterGroupSelector = React.useCallback((state: IMasterGroupStore) => {
        return masterGroupSelectors.masterGroupByCode(state, code);
    }, [masterGroupSelectors.masterGroupByCode, code]);

    const loadStateSelector = React.useCallback((state: IMasterGroupStore) => {
        return masterGroupSelectors.loadStateByCode(state, code);
    }, [masterGroupSelectors.loadStateByCode, code]);

    const masterGroup = useMasterGroupSelector(masterGroupSelector);

    const loadState = useMasterGroupSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, code]);

    return [
        masterGroup as MasterGroupType | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useMasterGroupByCode;