/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an event by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { EventReducer, eventActions, useEventSelector, eventSelectors, IEventStore } from '../../store/modules/data/event';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg, OmitFirstSixArgs, OmitFirstFourArgs, OmitFirstFiveArgs } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseEventByCodesActions {
    load: BoundActionCreator<OmitFirstSixArgs<typeof eventActions.loadByCodes>>;
    save: BoundActionCreator<OmitFirstFiveArgs<typeof eventActions.saveByCodes>>;
    clear: BoundActionCreator<OmitFirstFourArgs<typeof eventActions.clearByCodes>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useEventByCodes = <EventType extends Dtos.IEvent>(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, createPatient?: boolean | null, createEvent?: boolean | null, autoLoad?: boolean): [
    EventType | null,
    IRequestState<Dtos.ResponseStatus>,
    IRequestState<Dtos.ResponseStatus>,
    IUseEventByCodesActions
] => {
    const dispatch = useDispatch();

    const actions: IUseEventByCodesActions = React.useMemo(() => {
        const load = () => eventActions.loadByCodes(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, createPatient, createEvent);
        load.type = eventActions.loadByCodes.type;

        const save = (event?: Dtos.IEvent) => eventActions.saveByCodes(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, createPatient, event);
        save.type = eventActions.saveByCodes.type;

        const clear = () => eventActions.clearByCodes(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);
        clear.type = eventActions.clearByCodes.type;

        return bindActionCreatorsWithType({
            load,
            save,
            clear
        }, dispatch);
    }, [eventActions, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, createPatient, createEvent, dispatch]);

    const eventSelector = React.useCallback((state: IEventStore) => {
        return eventSelectors.eventByCodes(state, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);
    }, [eventSelectors.eventByCodes, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat]);

    const loadStateSelector = React.useCallback((state: IEventStore) => {
        return eventSelectors.loadStateByCodes(state, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);
    }, [eventSelectors.loadStateByCodes, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat]);

    const saveStateSelector = React.useCallback((state: IEventStore) => {
        return eventSelectors.saveStateByCodes(state, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);
    }, [eventSelectors.saveStateByCodes, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat]);

    const event = useEventSelector(eventSelector);

    const loadState = useEventSelector(loadStateSelector);

    const saveState = useEventSelector(saveStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat]);

    return [
        event as EventType | null,
        loadState,
        saveState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useEventByCodes;