/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to apply styles and page layout
 */
import { makeStyles, CssBaseline, Container, Paper } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for the header layout.
 */
import Header, { IMenuItem } from './layout/Header';
import useAuthenticatedUser from '../hooks/useAuthenticatedUser';
import RouteLoading from './route/RouteLoading';
import OnlinePatientManagementContext from '../contexts/OnlinePatientManagementContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OnlinePatientManagementLayout component.
 */
export interface IOnlinePatientManagementLayoutProps {
    /**
     * Menu items to show in the header 
     */
    menuItems?: IMenuItem[];

    /**
     * Content to be displayed in the site footer. 
     */
    footer?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    paper: {
        marginBottom: theme.spacing(3),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0),
        }
    },
    footer: {
        padding: theme.spacing(1, 3),
        textAlign: 'center',
        borderTopColor: theme.palette.primary.main,
        borderTopWidth: theme.spacing(1),
        borderTopStyle: 'solid'
    }
 }))

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides the basic layout for the patient management system.
 * @param param0 component properties.
 */
const OnlinePatientManagementLayout: React.FunctionComponent<IOnlinePatientManagementLayoutProps> = ({
    menuItems,
    footer,
    children
}) => {
    const classes = useStyles();

    const [user] = useAuthenticatedUser();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);


    const compiledChildren = React.useMemo(() => {
        let c = children;

        onlinePatientManagement?.extensions?.reverse().forEach(extension => {
            if (extension.ContentComponent) {
                const Component = extension.ContentComponent;

                c = (
                    <Component>
                        {c}
                    </Component>
                );
            }
        })

        return c;
    }, [onlinePatientManagement.extensions, children]);

    if (!user) {
        return null;
    }

    return (
        <>
            <CssBaseline />
            <Header menuItems={menuItems} />
            <Container
                className={classes.container}
            >
                <Paper
                    className={classes.paper}
                >
                    {
                        compiledChildren
                    }
                    {
                        !!footer && (
                            <div className={classes.footer}>
                                {footer}
                            </div>
                        )
                    }
                </Paper>
            </Container>
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default OnlinePatientManagementLayout;