/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to apply styles and page layout
 */
import { makeStyles, CssBaseline, Container, Paper, AppBar, Grid, Button, Typography, Link, Toolbar, useTheme, useMediaQuery, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, SwipeableDrawer } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for typings
 */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/**
 * Used for the logout icon.
 */
import { faSignOut } from '@fortawesome/pro-duotone-svg-icons/faSignOut';

/**
 * Used for the logout icon.
 */
import { faAddressCard } from '@fortawesome/pro-duotone-svg-icons/faAddressCard';

/**
 * Used for the logout icon.
 */
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';

/**
 * Used to create app routes.
 */
import { NavLink } from 'react-router-dom';

/*
 * Used for conditional classes.
 */
import classNames from 'classnames';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/**
 * Used to get access to trial information.
 */
import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';

/**
 * Used to get access to the authenticated user
 */
import useAuthenticatedUser from '../../hooks/useAuthenticatedUser';
import { IAuthSession } from '@servicestack/client';
import useIsMobile from '../../hooks/useIsMobile';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IMenuItem {
    icon: IconDefinition;
    label: React.ReactNode;
    url: string;
    external: boolean;
    newTab: boolean;
}

/**
 * This interface defines the properties for the Header component.
 */
export interface IHeaderProps {
    /**
     * Menu Items to display in the header 
     */
    menuItems?: IMenuItem[];
}

interface IDesktopHeaderProps {
    /**
     * Menu Items to display in the header 
     */
    menuItems?: IMenuItem[];

    user?: IAuthSession | null;

    trialName: string;
    organisationName: string;
    organisationLogo: string | null;
}

interface IMobileHeaderProps {
    /**
     * Menu Items to display in the header 
     */
    menuItems?: IMenuItem[];

    user?: IAuthSession | null;

    trialName: string;
    organisationName: string;
    organisationLogo: string | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useMobileStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    navLinkActive: {
        background: theme.palette.grey[200]
    },
    infoContainer: {
        padding: theme.spacing(3)
    },
    logo: {
        maxWidth: 240
    }
}));


const useDesktopStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    logo: {
        maxWidth: '100%',
        height: 'auto'
    },
    rightColumn: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    displayName: {
        fontWeight: 'bold'
    },
    navigationBar: {
        minHeight: 5
    },
    navLink: {
        borderRadius: 0,
        textTransform: 'none',
        fontWeight: 'normal'
    },
    navLinkActive: {
        background: theme.palette.primary.light
    },
    border: {
        borderBottomColor: theme.palette.primary.main,
        borderBottomWidth: theme.spacing(1),
        borderBottomStyle: 'solid'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const MobileHeader: React.FunctionComponent<IDesktopHeaderProps> = ({
    organisationLogo,
    organisationName,
    trialName,
    children,
    menuItems,
    user
}) => {
    const classes = useMobileStyles();

    const [open, setOpen] = React.useState(false);


    const onOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen])

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen])

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={onOpen}
                    >
                        <FontAwesomeIcon fixedWidth icon={faBars} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        className={classes.title}
                        variantMapping={{
                            h6: 'span'
                        }}
                    >
                        {trialName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={open}
                onClose={onClose}
                onOpen={onOpen}
            >
                <div
                    className={classes.infoContainer}
                >
                    {
                        !!organisationLogo ? 
                            <img
                                className={classes.logo}
                                src={organisationLogo}
                                alt={organisationName}
                            /> :
                            <Typography
                                variant="h6"
                                className={classes.title}
                                variantMapping={{
                                    h6: 'span'
                                }}
                            >
                                {organisationName}
                            </Typography>
                        
                    }
                </div>
                <Divider />
                <List
                    disablePadding
                >
                    <ListItem
                        component="a"
                        color="inherit"
                    //className={classes.navLink}
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faAddressCard} fixedWidth />
                        </ListItemIcon>
                        <ListItemText
                            primary={user?.displayName}
                        />
                    </ListItem>
                    <Divider />
                    {
                        !!menuItems && menuItems.map((menuItem, i) => {

                            if (menuItem.external || menuItem.newTab) {
                                return (
                                    <React.Fragment
                                        key={i}
                                    >
                                        <ListItem
                                            component="a"
                                            button
                                            href={menuItem.url}
                                            color="inherit"
                                            target={menuItem.newTab ? "_blank" : undefined}
                                            //className={classes.navLink}
                                        >
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={menuItem.label}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                );
                            }

                            return (
                                <React.Fragment
                                    key={i}
                                >
                                    <ListItem
                                        component={NavLink}
                                        button
                                        to={menuItem.url}
                                        color="inherit"
                                        //className={classes.navLink}
                                        activeClassName={classes.navLinkActive}
                                    >
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={menuItem.label}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })
                    }
                    <ListItem
                        component="a"
                        button
                        href="/auth/logout"
                        color="inherit"
                    >
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faSignOut} fixedWidth />
                        </ListItemIcon>
                        <ListItemText
                            primary="Logout"
                        />
                    </ListItem>
                    <Divider />
                </List>
            </SwipeableDrawer>
        </>
    );
}

const DesktopHeader: React.FunctionComponent<IDesktopHeaderProps> = ({
    organisationLogo,
    organisationName,
    trialName,
    children,
    menuItems,
    user
}) => {

    const classes = useDesktopStyles();

    return (
        <AppBar
            position="static"
        >
            <Paper
                elevation={0}
                square
                className={classNames({ [classes.border]: !menuItems })}
            >
                <Container
                    className={classes.container}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            md={4}
                        >
                            {
                                !!organisationLogo ?
                                    <img
                                        className={classes.logo}
                                        src={organisationLogo}
                                        alt={organisationName}
                                    /> :
                                    <Typography
                                        variant="h1"
                                        className={classes.title}
                                        variantMapping={{
                                            h1: 'span'
                                        }}
                                    >
                                        {organisationName}
                                    </Typography>
                            }
                        </Grid>
                        <Grid
                            className={classes.rightColumn}
                            item
                            xs={12}
                            md={8}
                        >
                            <Typography
                                variant="h1"
                                className={classes.title}
                                variantMapping={{
                                    h1: 'span'
                                }}
                            >
                                {trialName}
                            </Typography>
                            <Typography
                                className={classes.displayName}
                            >
                                {user?.displayName}
                            </Typography>
                            {
                                !!user && (
                                    <Link
                                        component="a"
                                        href="/auth/logout"
                                    >
                                        Logout <FontAwesomeIcon icon={faSignOut} fixedWidth />
                                    </Link>
                                )
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
            {
                !!menuItems && (
                    <Toolbar
                        className={classes.navigationBar}
                    >
                        <Container>
                            {
                                menuItems.map((menuItem, i) => {

                                    if (menuItem.external || menuItem.newTab) {
                                        return (
                                            <Button
                                                key={i}
                                                component="a"
                                                href={menuItem.url}
                                                color="inherit"
                                                target={menuItem.newTab ? "_blank" : undefined}
                                                className={classes.navLink}
                                                startIcon={<FontAwesomeIcon icon={menuItem.icon} fixedWidth />}
                                                size="large"
                                            >
                                                {menuItem.label}
                                            </Button>
                                        );
                                    }

                                    return (
                                        <Button
                                            key={i}
                                            component={NavLink}
                                            to={menuItem.url}
                                            color="inherit"
                                            className={classes.navLink}
                                            activeClassName={classes.navLinkActive}
                                            startIcon={<FontAwesomeIcon icon={menuItem.icon} fixedWidth />}
                                            size="large"
                                        >
                                            {menuItem.label}
                                        </Button>
                                    );
                                })
                            }
                        </Container>
                    </Toolbar>
                )
            }
        </AppBar>
    )
};

/**
 * This component provides the basic layout for the patient management system.
 * @param param0 component properties.
 */
const Header: React.FunctionComponent<IHeaderProps> = ({
    menuItems,
    children
}) => {
    const [ user ] = useAuthenticatedUser();

    const { trialName, organisationName, organisationLogo } = React.useContext(OnlinePatientManagementContext)

    const isMobile = useIsMobile();

    if (isMobile) {
        return (
            <MobileHeader
                organisationLogo={organisationLogo}
                organisationName={organisationName}
                trialName={trialName}
                menuItems={menuItems}
                user={user}
            />
        );
    }

    return (
        <DesktopHeader
            organisationLogo={organisationLogo}
            organisationName={organisationName}
            trialName={trialName}
            menuItems={menuItems}
            user={user}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Header;