/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an permissions by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import { ResponseStatus } from '@ngt/opms';

import { BoundActionCreator } from '@ngt/opms/dist/utilities/BoundActionCreator';

import { OmitFirstFiveArgs } from '@ngt/opms/dist/utilities/OmitArgs';

import bindActionCreatorsWithType from '@ngt/opms/dist/utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { PermissionsReducer, permissionsActions, usePermissionsSelector, permissionsSelectors, IPermissionsStore } from '../../store/modules/utility/permissions';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUsePermissionsByIdActions {
    load: BoundActionCreator<OmitFirstFiveArgs<typeof permissionsActions.loadByIds>>;
    clear: BoundActionCreator<OmitFirstFiveArgs<typeof permissionsActions.clearByIds>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const usePermissionsByIds = (permissions: Dtos.Permission[],
    masterGroupId?: number | null,
    collaboratingGroupId?: number | null,
    institutionId?: number | null,
    patientId?: number | null,
    autoLoad?: boolean,
    autoClear: boolean = true): [
    boolean[],
    IRequestState<ResponseStatus>,
    IUsePermissionsByIdActions
] => {
    const dispatch = useDispatch();

    const actions: IUsePermissionsByIdActions = React.useMemo(() => {
        const load = () => permissionsActions.loadByIds(masterGroupId, collaboratingGroupId, institutionId, patientId, permissions);
        load.type = permissionsActions.loadByIds.type;

        const clear = () => permissionsActions.clearByIds(masterGroupId, collaboratingGroupId, institutionId, patientId, permissions);
        clear.type = permissionsActions.clearByIds.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [permissionsActions, masterGroupId, collaboratingGroupId, institutionId, patientId, permissions, dispatch]);

    const permissionsSelector = React.useCallback((state: IPermissionsStore) => {
        return permissionsSelectors.permissionsByIds(state, masterGroupId, collaboratingGroupId, institutionId, patientId, permissions);
    }, [permissionsSelectors.permissionsByIds, masterGroupId, collaboratingGroupId, institutionId, patientId, permissions]);

    const loadStateSelector = React.useCallback((state: IPermissionsStore) => {
        return permissionsSelectors.loadStateByIds(state, masterGroupId, collaboratingGroupId, institutionId, patientId, permissions);
    }, [permissionsSelectors.loadStateByIds, masterGroupId, collaboratingGroupId, institutionId, patientId, permissions]);

    const hasPermissions = usePermissionsSelector(permissionsSelector);

    const loadState = usePermissionsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            if (autoClear) {
                return () => {
                    actions.clear();
                };
            }
        }

        return () => { };
    }, [autoLoad, autoClear, masterGroupId, collaboratingGroupId, institutionId, patientId, permissions]);

    return [
        hasPermissions as boolean[],
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default usePermissionsByIds;