/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';

import Condition, { IConditionParameters, IConditionProps } from './Condition';

import MasterGroupContext from '../../contexts/data/MasterGroupContext';

import CollaboratingGroupContext from '../../contexts/data/CollaboratingGroupContext';

import CountryContext from '../../contexts/data/CountryContext';

import InstitutionContext from '../../contexts/data/InstitutionContext';

import PatientContext from '../../contexts/data/PatientContext';

import EventContext from '../../contexts/data/EventContext';

import EventDefinitionContext from '../../contexts/configuration/EventDefinitionContext';

import FormDefinitionContext from '../../contexts/configuration/FormDefinitionContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitCondition<T> = Omit<T, 'condition'>


export interface ICrfConditionParameters<TValues extends object = any> extends IConditionParameters<TValues> {
    masterGroup?: Dtos.IMasterGroup;
    collaboratingGroup?: Dtos.ICollaboratingGroup;
    country?: Dtos.ICountry;
    institution?: Dtos.IInstitution;
    patient?: Dtos.IPatient;
    event?: Dtos.IEvent;
    eventDefinition?: Dtos.IEventDefinition;
    formDefinition?: Dtos.IFormDefinition;
}

/**
 * This interface defines the properties for the Condition component.
 */
export interface ICrfConditionProps<TValues extends Object = any> extends OmitCondition<IConditionProps<TValues>> {
    condition: (params: ICrfConditionParameters<TValues>) => boolean
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const CrfCondition = <TValues extends Object = any>({
    condition,
    children,
    ...rest
}: ICrfConditionProps<TValues>) => {
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { country } = React.useContext(CountryContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    const { event } = React.useContext(EventContext);
    const { eventDefinition } = React.useContext(EventDefinitionContext);
    const { formDefinition } = React.useContext(FormDefinitionContext);


    const formCondition = React.useCallback((params: IConditionParameters<TValues>) => {
        return condition({
            formState: params?.formState,
            labels: params?.labels,
            lookups: params?.lookups,
            parentFieldName: params?.parentFieldName,
            masterGroup: masterGroup ?? undefined,
            collaboratingGroup: collaboratingGroup ?? undefined,
            country: country ?? undefined,
            institution: institution ?? undefined,
            patient: patient ?? undefined,
            event: event ?? undefined,
            eventDefinition: eventDefinition ?? undefined,
            formDefinition: formDefinition ?? undefined
        });
    }, [condition, masterGroup, collaboratingGroup, country, institution, patient, event, eventDefinition, formDefinition])

    return (
        <Condition
            {...rest}
            condition={formCondition}
        >
            {children}
        </Condition>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CrfCondition;