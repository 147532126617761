/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 * NewtonGreen Technologies Pty.Ltd.
 * Level 4, 175 Scott St.
 * Newcastle, NSW, 2300
    * Australia
    *
 * E - mail: support@newtongreen.com
 * Tel: (02) 4925 5288
    * Fax: (02) 4925 3068
        *
 * All Rights Reserved.
 * ---------------------------------------------------------------------------------
 * /

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the sae reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';
import { ScreeningLog } from '../api/screeningLog'

/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualScreeningLogState {
    screeningLog: ScreeningLog | null;
    loadState: IRequestState<ResponseStatus>;
    saveState: IRequestState<ResponseStatus>;
    deleteState: IRequestState<ResponseStatus>;
};

export interface IScreeningLogState {
    byContext: Record<string, IIndividualScreeningLogState>;
};

export interface IScreeningLogStore {
    screeningLog: IScreeningLogState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualScreeningLogState: IIndividualScreeningLogState = {
    screeningLog: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    },
    deleteState: {
        state: RequestState.None
    }
};

export const initialSaeState: IScreeningLogState = {
    byContext: {}
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdContext = (id?: number | null) => {
    return `${(id ?? 'new')}`;
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class ScreeningLogReducer extends ImmerReducer<IScreeningLogState> {
    public load(id: number) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(id: number, screeningLog?: ScreeningLog) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogState };
        }

        this.draftState.byContext[context].screeningLog = screeningLog ?? null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(id: number, responseStatus?: ResponseStatus) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public save(id?: number, screeningLog?: ScreeningLog) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogState };
        }

        this.draftState.byContext[context].saveState = {
            state: RequestState.Pending
        };
    }

    public saveSuccess(id?: number, screeningLog?: ScreeningLog) {
        let context = createIdContext(id);

        // if newly created, must clean up the 'new' context
        if (!id && this.draftState.byContext[context]) {
            delete this.draftState.byContext[context]
            context = createIdContext(screeningLog?.id);
        }

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogState };
        }

        this.draftState.byContext[context].screeningLog = screeningLog ?? null;

        this.draftState.byContext[context].saveState = {
            state: RequestState.Success
        };
    }

    public saveFailure(id?: number, responseStatus?: ResponseStatus) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogState };
        }

        this.draftState.byContext[context].saveState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public delete(id?: number, screeningLog?: ScreeningLog) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogState };
        }

        this.draftState.byContext[context].deleteState = {
            state: RequestState.Pending
        };
    }

    public deleteSuccess(id?: number) {
        let context = createIdContext(id);

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public deleteFailure(id?: number, responseStatus?: ResponseStatus) {
        const context = createIdContext(id);

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogState };
        }

        this.draftState.byContext[context].deleteState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(id: number) {
        const context = createIdContext(id);

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialSaeState };
    }
}

export const screeningLogActions = createActionCreators(ScreeningLogReducer);
export const screeningLogReducer = createReducerFunction(ScreeningLogReducer, initialSaeState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createScreeningLogApi = (client: JsonServiceClient) => ({
    load: (id: number) => {
        const request: Dtos.ScreeningLogGetSingleById = new Dtos.ScreeningLogGetSingleById({
            id
        });
        return client.get(request);
    },
    save: (screeningLog?: ScreeningLog) => {
        const request: Dtos.ScreeningLogPostSave = new Dtos.ScreeningLogPostSave({
            screeningLog
        });
        return client.post(request);
    },
    delete: (screeningLog?: ScreeningLog) => {
        const request: Dtos.ScreeningLogDelete = new Dtos.ScreeningLogDelete({
            screeningLogId: screeningLog?.id
        });
        return client.delete(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createScreeningLogLogic = (api: ReturnType<typeof createScreeningLogApi>) => {
    const logic = {
        load: createLogic<IScreeningLogStore, {}, undefined, string, ReturnType<typeof screeningLogActions.load>>({
            type: screeningLogActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload as unknown as number;

                try {
                    let response: Dtos.ScreeningLogSingleResponse | null = null;

                    response = await api.load(id);

                    dispatch(screeningLogActions.loadSuccess(
                        id,
                        response.screeningLog
                    ));
                }
                catch (error) {
                    dispatch(screeningLogActions.loadFailure(
                        id,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
        save: createLogic<IScreeningLogStore, {}, undefined, string, ReturnType<typeof screeningLogActions.save>>({
            type: screeningLogActions.save.type,
            process: async ({ action }, dispatch, done) => {
                const [id, screeningLog] = action.payload;

                try {
                    let response: Dtos.ScreeningLogSingleResponse | null = null;

                    response = await api.save(screeningLog);

                    dispatch(screeningLogActions.saveSuccess(
                        id,
                        response.screeningLog
                    ));
                }
                catch (error) {
                    dispatch(screeningLogActions.saveFailure(
                        id,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        }),
        delete: createLogic<IScreeningLogStore, {}, undefined, string, ReturnType<typeof screeningLogActions.delete>>({
            type: screeningLogActions.delete.type,
            process: async ({ action }, dispatch, done) => {
                const [id, screeningLog] = action.payload;

                try {
                    let response: Dtos.ScreeningLogDeleteResponse | null = null;

                    response = await api.delete(screeningLog);

                    dispatch(screeningLogActions.deleteSuccess(
                        id
                    ));
                }
                catch (error) {
                    dispatch(screeningLogActions.deleteFailure(
                        id,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load,
        logic.save,
        logic.delete
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useScreeningLogSelector: TypedUseSelectorHook<IScreeningLogStore> = useSelector;

export const screeningLogSelectors = {
    screeningLog: (state: IScreeningLogStore, id?: number) => {
        const context = createIdContext(id);

        return state.screeningLog?.byContext[context]?.screeningLog ?? initialIndividualScreeningLogState.screeningLog;
    },
    loadState: (state: IScreeningLogStore, id?: number) => {
        const context = createIdContext(id);

        return state.screeningLog?.byContext[context]?.loadState ?? initialIndividualScreeningLogState.loadState;
    },
    saveState: (state: IScreeningLogStore, id?: number) => {
        const context = createIdContext(id);

        return state.screeningLog?.byContext[context]?.saveState ?? initialIndividualScreeningLogState.saveState;
    },
    deleteState: (state: IScreeningLogStore, id?: number) => {
        const context = createIdContext(id);

        return state.screeningLog?.byContext[context]?.deleteState ?? initialIndividualScreeningLogState.deleteState;
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerScreeningLogReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createScreeningLogApi(client);

    const logic = createScreeningLogLogic(api);

    reducerRegistry.register('screeningLog', screeningLogReducer as Reducer, logic as any);
};

export default registerScreeningLogReducer;
