/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae investigator review form component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { makeStyles, Button, List, ListItem } from '@material-ui/core';

import { InstitutionContext, Form, SubmitButton, Field, TextArea, FieldGroup, FormGrid, IFormGridCell, Input } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';
import SaeReviewContext from '../context/SaeReviewContext';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeMedicalReviewFormProps {
}

interface ISaeRejectionForm {
    reason?: string;
    followUpNumber?: number;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    instruction: {
        fontWeight: "bold",
        marginTop: theme.spacing(2)
    },
    ml0: {
        marginLeft: theme.spacing(-1)
    },
    mt1: {
        marginTop: theme.spacing(3)
    },
    reviewButton: {
        textAlign: 'right',
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const SaeMedicalReviewForm: React.FunctionComponent<ISaeMedicalReviewFormProps> = () => {
    const classes = useStyles();

    const { sae, loadState: saeLoadState, actions: saeActions } = React.useContext(SaeContext);

    const showForm = React.useMemo(() => {
        return sae?.actionHistory?.find(a => a.actionType === Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers || a.actionType === Dtos.SaeWebActionTypeEnum.SubmittedMedicalReview)?.actionType === Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers
    }, [sae?.actionHistory]);

    const initialInvestigatorReviewForm: ISaeRejectionForm = React.useMemo(() => {
        return {
            reason: '',
            followUpNumber: sae?.form?.followUpNumber
        }
    }, [sae?.form?.followUpNumber]);

    const contextSaeReview = React.useContext(SaeReviewContext);

    const hasMedicalReview = contextSaeReview?.hasMedicalReview;

    //const initialMedicalReviewForm: Dtos.SaeMedicalReviewForm

    return (
        <>
            {
                showForm &&
                hasMedicalReview &&
                <>
                    <Form
                        initialValues={initialInvestigatorReviewForm}
                        onValidate={undefined}
                        allowSubmit={undefined}
                        fieldErrors={undefined}
                        onSubmit={undefined}
                        labels={undefined}
                        lookups={undefined}
                        readOnly={false}
                        onSubmitFailed={undefined}
                        onSubmitValidationFailed={undefined}
                    >
                        <FieldGroup>
                        <br />
                        <Typography
                                component={'span'}
                                className={classes.mt1}
                        >
                                An SAE has been updated for the Breast MRI trial.
                        </Typography>

                        <Typography
                            className={classes.instruction}
                        >
                                Please submit a medical review using the form below:
                        </Typography>

                        <Typography>
                            <br />
                            <u>For Australian Participants please note:</u>
                            <br />
                            <br />
                            <b>TGA Blue Card Notification:</b>
                            <br />
                            <br />
                            If a SAE has 'Relatedness' of "Possibly", "Probably" and "Related" and 'Expectedness' of "Unexpected", TGA notification within 15 days of BCT's knowledge of the event is required.
                            <br />
                            <br />
                            <b>TGA Death Notification:</b>
                            <br />
                            <br />
                            In case of SAE (with 'Relatedness' of "Possibly", "Probably" and "Related") resulting in 'Death', regardless of 'Expectedness', TGA notification within 7 days of BCT's knowledge of the event is required.
                         </Typography>

                        <div className={classes.reviewButton}>
                            <SubmitButton
                                variant="contained"
                                color="primary"
                                onClick={undefined}
                            >
                                        Submit Review
                            </SubmitButton>
                        </div>

                        <br />
                        </FieldGroup>
                    </Form>
                </>
            }
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeMedicalReviewForm;