/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to get the form context.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import FormContext, { IFormContext } from '../contexts/FormContext';
import { IFormSubscription, IFormState } from '../Form';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useFormState = <TValues extends object = any, TError = any>(subscription?: Partial<IFormSubscription>) => {
    const context: IFormContext<TValues, TError> | null = React.useContext(FormContext);

    if (context === null) {
        throw new Error("Form Context does not exist");
    }

    const [state, setState] = React.useState<IFormState<TValues, TError>>(() => {
        return {
            values: context.getValues(),
            dirty: context.getDirty(),
            fields: context.getFields(),
            errors: context.getErrors(),
            focused: context.getFocused(),
            initialValues: context.getInitialValues(),
            touched: context.getTouched(),
            validating: context.getValidating(),
            submitting: context.getSubmitting()
        }
    });

    React.useEffect(() => {
        setState({
            values: context.getValues(),
            dirty: context.getDirty(),
            fields: context.getFields(),
            errors: context.getErrors(),
            focused: context.getFocused(),
            initialValues: context.getInitialValues(),
            touched: context.getTouched(),
            validating: context.getValidating(),
            submitting: context.getSubmitting()
        });
    }, [context]);


    React.useEffect(() => {
        const unsubscribe = context.subscribe(
            (fieldState) => {
                setState(fieldState)
            },
            subscription as IFormSubscription | undefined
        );

        return () => {
            unsubscribe();
        }
    }, [context.subscribe, subscription])

    return state;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useFormState;