/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Checkbox, ICheckbox, IInputRenderProps, Field } from '@ngt/opms'

import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';

import { Grid, FormGroup, FormControlLabel } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Interfaces
 * ---------------------------------------------------------------------------------
 */

interface CheckboxListWrapperProps<T extends any> extends Partial<Omit<MuiCheckboxProps, 'type' | 'onChange'>>, IInputRenderProps<T[], any>{
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: T[]) => void;
    values: Array<{ value: T; label: string; }>
}

interface CheckboxInputProps<T extends any> extends Omit<MuiCheckboxProps, 'onChange' | 'value'> {
    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: T, checked: boolean) => void;
    value: T;
    label: string;
}

const CheckboxInput = <T extends any>({
    onChange,
    value,
    label,
    ...rest
}: CheckboxInputProps<T>) => {
    const onInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checkedValue: boolean) => {
        if (onChange) {
            onChange(event, value, checkedValue);
        }
    }, [value, onChange]);

    return (
        <>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        {...rest}
                        onChange={onInputChange}
                    />
                }
                label={label}
            />
        </>
    );
}

export const CheckboxListWrapper = <T extends any>({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    onChange,
    values,
    ...rest
}: CheckboxListWrapperProps<T>) => {
    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checkboxValue: T, checkedValue: boolean) => {
        const newValue = checkedValue ? [...value ?? [], checkboxValue] : value?.filter((v: T) => v !== checkboxValue);

        if (onChange) {
            onChange(event, newValue ?? []);
        }

        onInputChange(newValue);
    }, [value, onChange, onInputChange]);

    return (
        <>
            {
                values.map((v, index) => {
                    return (
                        <FormGroup
                            key={`${name}.${v.value as any}[${index}]`}
                        >
                            <CheckboxInput
                                {...rest}
                                name={name}
                                onChange={onChangeCombined}
                                value={v.value}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                checked={value?.includes(v.value) === true}
                                label={v.label}
                            />
                        </FormGroup>
                    );
                })
            }
        </>
    );
}