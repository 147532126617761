/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';

/**
 * typings
 */
import { CreateLogic } from 'redux-logic/definitions/logic';
import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';
import EventDefinitionContext from '../../contexts/configuration/EventDefinitionContext';
import InstitutionContext from '../../contexts/data/InstitutionContext';
import PatientContext from '../../contexts/data/PatientContext';
import EventContext from '../../contexts/data/EventContext';
import FormDefinitionContext from '../../contexts/configuration/FormDefinitionContext';
import FormContext from '../../contexts/data/FormContext';
import { IFormLabel } from '../../contexts/form/LabelsContext';
import { pascalToCameCasePropertyPath } from '../../utilities/pascalToCamelCase';
import LookupsContext from '../../contexts/utility/LookupsContext';
import { IFormState, IFormActions } from '../../form/Form';
import IDtoRequestClass from '../../utilities/IDtoRequestClass';
import * as Dtos from '../../api/dtos';
import asyncDebounce from '../../utilities/asyncDebounce';
import { useHistory, useParams } from 'react-router-dom';
import IDtoClass from '../../utilities/IDtoClass';
import { IFormContext } from '../../form/contexts/FormContext';
import useSnackbar, { SnackbarVariant } from '../useSnackbar';
import pluralize from 'pluralize';
import AlertTitle from '@material-ui/lab/AlertTitle';
import useAuthenticatedUser from '../useAuthenticatedUser';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/


/*
* ---------------------------------------------------------------------------------
* Interfaces
* ---------------------------------------------------------------------------------
*/


interface IFormPostValidateWithCodes<Type extends Dtos.IForm> {
    institutionCode?: string;
    patientStudyNumber?: string;
    eventDefinitionCode?: string;
    eventRepeat?: number;
    form?: Type;
}

interface IFormValidationResponse {
    responseStatus?: Dtos.ResponseStatus;
    validationResult?: Dtos.IFormValidationResult;
}

export interface IUsePatientFormOptions<Type extends Dtos.IForm> {
    formType: IDtoClass<Type>;
    afterFormSave?: (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null, event?: Dtos.IEvent | null, form?: Type | null) => void;
    afterFormSaveAndReturn?: (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null, event?: Dtos.IEvent | null, form?: Type | null) => void;
    onCancel?: () => void;
    readOnly?: boolean;
    isPro?: boolean;
}

const errorVariantMapping: Record<Dtos.ValidationErrorType, SnackbarVariant> = {
    [Dtos.ValidationErrorType.Warning]: 'warning',
    [Dtos.ValidationErrorType.Ineligible]: 'ineligible',
    [Dtos.ValidationErrorType.Normal]: 'error',
    [Dtos.ValidationErrorType.StratificationFailure]: 'stratification-failure',
    [Dtos.ValidationErrorType.Critical]: 'critical'
}

const errorTextMapping: Record<Dtos.ValidationErrorType, string> = {
    [Dtos.ValidationErrorType.Warning]: 'warning',
    [Dtos.ValidationErrorType.Ineligible]: 'ineligibility warning',
    [Dtos.ValidationErrorType.Normal]: 'error',
    [Dtos.ValidationErrorType.StratificationFailure]: 'stratification failure',
    [Dtos.ValidationErrorType.Critical]: 'critical error'
}

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const usePatientForm = <Type extends Dtos.IForm>({
    formType,
    afterFormSave,
    afterFormSaveAndReturn,
    onCancel,
    readOnly,
    isPro
}: IUsePatientFormOptions<Type>) => {
    // Get form name from the DTO class.

    const [user] = useAuthenticatedUser();

    const { enqueueSnackbar } = useSnackbar();

    const { formDefinition } = React.useContext(FormDefinitionContext);

    const formDefinitionCode = React.useMemo(() => {
        return formDefinition?.code;
    }, [formDefinition])

    // Pull in OPMS Context to get form meta data and DTO collection.
    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    // Get formDefinitionId using form name and form meta data.
    const formName = React.useMemo(() => {
        return onlinePatientManagement.formMetadata?.find(fm => fm.formDefinitionCode === formDefinitionCode)?.name;
    }, [formDefinitionCode, onlinePatientManagement.formMetadata]);

    // If form definition ID is not found in meta data this means that the form is not registered in the OPMS.
    if (!formName) {
        throw new Error('Form is not registered in OPMS.')
    }

    const { eventDefinition } = React.useContext(EventDefinitionContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    const { event } = React.useContext(EventContext);

    // get form definition from context.
    const { form, actions: formActions } = React.useContext(FormContext)

    const formLabels = React.useMemo(() => {
        const labels: IFormLabel[] = [];

        formDefinition?.properties?.forEach(p => labels.push({
            name: pascalToCameCasePropertyPath(p.propertyName),
            label: p.label,
            detailedLabel: p.detailedLabel
        }));

        if (formDefinition?.subformProperties) {
            Object.keys(formDefinition.subformProperties).forEach(key => {
                formDefinition.subformProperties[key].forEach(p => labels.push({
                    name: pascalToCameCasePropertyPath(`${key}.${p.propertyName}`),
                    label: p.label,
                    detailedLabel: p.detailedLabel
                }));
            })
        }

        return labels;
    }, [pascalToCameCasePropertyPath, formDefinition])



    // get lookups for form.
    const { lookups } = React.useContext(LookupsContext)

    // swap lookup property paths to camel case from pascal case.
    const processedLookups = React.useMemo(() => {
        return lookups?.map(lookup => ({ ...lookup, propertyName: pascalToCameCasePropertyPath(lookup.propertyName) }))
    }, [lookups, pascalToCameCasePropertyPath]);

    // Find the validate Request DTO and create a validate method for the form.
    const onValidate = React.useCallback(async (formState: IFormState<Type, Dtos.IValidationError>) => {
        // find the validate method based on form name and static suffix.
        const FormPostValidateWithCodes: IDtoRequestClass<IFormPostValidateWithCodes<Type>, IFormValidationResponse> = onlinePatientManagement.dtos[`${formName}PostValidateWithCodes`];

        // Send request to server.
        const response = await onlinePatientManagement
            .serviceStackClient
            .post(new FormPostValidateWithCodes({ institutionCode: institution?.code, patientStudyNumber: patient?.studyNumber, eventDefinitionCode: eventDefinition?.code, eventRepeat: event?.repeat, form: formState.values }));

        // parse errors into a format the form understands.
        const groupErrors = response.validationResult?.errors?.reduce((a: Record<string, Dtos.IFormValidationError[]>, b: Dtos.IFormValidationError) => {
            const propertyName = pascalToCameCasePropertyPath(b.property)

            if (!a[propertyName]) {
                a[propertyName] = [];
            }

            a[propertyName].push(b);

            return a;
        }, {}) ?? {};

        // return errors;
        return groupErrors;

    }, [onlinePatientManagement.dtos, onlinePatientManagement.serviceStackClient, formName, pascalToCameCasePropertyPath, eventDefinition, institution, patient, event]);

    // debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = React.useMemo(() => {
        return asyncDebounce(onValidate, 500);
    }, [onValidate]);

    // override the allow submit function so that submit is only blocked when there is a critical error.
    const allowSubmit = React.useCallback(async ({ errors }: IFormState<Type, Dtos.IValidationError>, formActions: IFormActions<Type, Dtos.IValidationError>) => {
        if (!errors) {
            return true;
        }

        return !Object.keys(errors).some(key => errors[key] && errors[key].some(e => e.type === Dtos.ValidationErrorType.Critical));
    }, []);

    const history = useHistory();
    const params = useParams<Record<string, string>>();

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors }: IFormState<Type, Dtos.IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    An error occurred while attempting to validate the form.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: Dtos.IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: Dtos.IValidationError[], e: Dtos.IValidationError) => {
                        if (e.type === Dtos.ValidationErrorType.Critical) {
                            return [ ...propertyArray, e ]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Not Saved
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the form again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const onFormSubmitFailure = React.useCallback(async (formState: IFormState<Type, Dtos.IValidationError>) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    Form Not Saved
                    </AlertTitle>
                    An error occurred while attempting to save the form.
            </>,
            { variant: 'critical' }
        );
    }, []);

    const handleSubmit = React.useCallback(async ({ values, errors }: IFormState<Type, Dtos.IValidationError>) => {
        const { submitType, ...form } = values as any;

        const result = await formActions.asyncSave(values);

        const allErrors = Object
            .keys(errors)
            .reduce((array: Dtos.IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: Dtos.IValidationError[], e: Dtos.IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        const maxErrorType = allErrors.reduce((maxError: Dtos.ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);

        if (maxErrorType) {

            const scopedErrors = allErrors.filter(e => e.type === maxErrorType);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Saved
                    </AlertTitle>
                    The form was successfully saved but contained {scopedErrors.length} {pluralize(errorTextMapping[maxErrorType], scopedErrors.length)}.
                </>,
                { variant: errorVariantMapping[maxErrorType] }
            );
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Form Saved
                    </AlertTitle>
                    The form was successfully saved.
                </>,
                { variant: 'success' }
            );
        }

        let patientToUse = patient;
        let eventToUse = event;

        if (!patient?.id) {
            patientToUse = (await onlinePatientManagement.serviceStackClient.get(new Dtos.PatientGetSingleById({ id: result?.patientId }))).patient
        }

        if (!event?.id) {
            eventToUse = (await onlinePatientManagement.serviceStackClient.get(new Dtos.EventGetSingleById({ id: result?.eventId }))).event
        }

        if (submitType === 'save') {
            if (afterFormSave) {
                afterFormSave(institution, patientToUse, eventToUse, result as Type);
            }
        }
        else {
            if (afterFormSaveAndReturn) {
                afterFormSaveAndReturn(institution, patientToUse, eventToUse, result as Type);
            }
            else {
                const institutionCode = params[onlinePatientManagement.routeParameters.institutionCode];

                if (user?.type === Dtos.UserType.Patient) {
                    history.push(`/next`);
                }
                else {
                    history.push(`/registration/${institutionCode}/${patientToUse?.studyNumber}`)
                }
            }
        }
    }, [formActions.asyncSave, institution, patient, event, afterFormSave, afterFormSaveAndReturn, onlinePatientManagement.serviceStackClient, history, params, onlinePatientManagement.routeParameters, user])

    const onFormSubmit = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<Type, Dtos.IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'save');
        }
    }, [])

    const onFormSubmitAndReturn = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<Type, Dtos.IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'saveAndReturn');
        }
    }, [])

    const onFormCancel = React.useCallback(() => {
        if (onCancel) {
            onCancel();
        }
        else {
            const institutionCode = params[onlinePatientManagement.routeParameters.institutionCode];

            if (user?.type === Dtos.UserType.Patient) {
                history.push(`/`);
            }
            else if (patient) {
                history.push(`/registration/${institutionCode}/${patient?.studyNumber}`)
            }
            else if (institutionCode) {
                history.push(`/registration/${institutionCode}`)
            }
            else {
                history.push(`/registration`);
            }
        }
    }, [history, params, onlinePatientManagement.routeParameters, onCancel, patient, user]);

    const formReadOnly = React.useMemo(() => {
        if (readOnly === true) {
            return true;
        }

        if (isPro && form) {
            const pro: any = form;

            if (pro.completed) {
                return true;
            }
        }

        const eventDefinitionFormDefinition = eventDefinition?.formDefinitions?.find(edfd => edfd.formDefinitionId === formDefinition?.id);
        const eventDefinitionFormDefinitionState = eventDefinitionFormDefinition?.states?.find(s => s.patientStateId === patient?.patientStateId);

        return eventDefinitionFormDefinitionState?.write !== true && !!patient;

    }, [readOnly, eventDefinition, formDefinition, patient?.patientStateId, isPro, form])

    return {
        form: form as Type | null,
        patient,
        formDefinition,
        formName: formDefinition?.name,
        labels: formLabels,
        lookups: processedLookups,
        handleSubmit,
        allowSubmit,
        validate: debouncedValidate,
        onFormSubmitAndReturn,
        onFormSubmit,
        onFormCancel,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        readOnly: formReadOnly
    }
};

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default usePatientForm;

