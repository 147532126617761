/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the event reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualEventState {
    event: Dtos.IEvent | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
    saveState: IRequestState<Dtos.ResponseStatus>;
}

export interface IEventState {
    byId: Record<number, IIndividualEventState>;
    byCodes: Record<string, IIndividualEventState>;
    byIds: Record<string, IIndividualEventState>;
}

export interface IEventStore {
    event: IEventState;
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) => {
    return `${(institutionId ?? 'null')}-${(patientId ?? 'null')}-${(eventDefinitionId ?? 'null')}-${(eventRepeat ?? 'null')}`;
}

const createCodesContext = (institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) => {
    return `${(institutionCode ?? 'null')}-${(patientStudyNumber ?? 'null')}-${(eventDefinitionCode ?? 'null')}-${(eventRepeat ?? 'null')}`;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all event reducer state changes and actions. 
 */
export class EventReducer extends ImmerReducer<IEventState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, event?: Dtos.IEvent) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventState };
        }

        this.draftState.byId[id].event = event ? event : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveById(id: number, event?: Dtos.IEvent) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventState };
        }

        this.draftState.byId[id].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByIdSuccess(id: number, event?: Dtos.IEvent) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventState };
        }

        this.draftState.byId[id].event = event ? event : null;

        this.draftState.byId[id].saveState = {
            state: RequestState.Success
        };
    }

    public saveByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualEventState };
        }

        this.draftState.byId[id].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public loadByIds(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, createPatient?: boolean | null, createEvent?: boolean | null) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdsSuccess(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, event?: Dtos.IEvent) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventState };
        }

        this.draftState.byIds[context].event = event ? event : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveByIds(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, createPatient?: boolean | null, event?: Dtos.IEvent) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventState };
        }

        this.draftState.byIds[context].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByIdsSuccess(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, event?: Dtos.IEvent) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventState };
        }

        this.draftState.byIds[context].event = event ? event : null;

        this.draftState.byIds[context].saveState = {
            state: RequestState.Success
        };
    }

    public saveByIdsFailure(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualEventState };
        }

        this.draftState.byIds[context].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (this.draftState.byIds[context]) {
            delete this.draftState.byIds[context];
        }
    }

    public loadByCodes(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, createPatient?: boolean | null, createEvent?: boolean | null) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByCodesSuccess(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, event?: Dtos.IEvent) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventState };
        }

        this.draftState.byCodes[context].event = event ? event : null;

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodesFailure(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveByCodes(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, createPatient?: boolean | null, event?: Dtos.IEvent) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventState };
        }

        this.draftState.byCodes[context].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByCodesSuccess(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, event?: Dtos.IEvent) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventState };
        }

        this.draftState.byCodes[context].event = event ? event : null;

        this.draftState.byCodes[context].saveState = {
            state: RequestState.Success
        };
    }

    public saveByCodesFailure(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualEventState };
        }

        this.draftState.byCodes[context].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByCodes(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (this.draftState.byCodes[context]) {
            delete this.draftState.byCodes[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialEventState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualEventState: IIndividualEventState = {
    event: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

export const initialEventState: IEventState = {
    byCodes: {},
    byIds: {},
    byId: {}
}

export const eventActions = createActionCreators(EventReducer);
export const eventReducer = createReducerFunction(EventReducer, initialEventState);

const createEventApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.EventGetSingleById({ id }));
    },
    save: (event?: Dtos.IEvent) => {
        return client.post(new Dtos.EventPostSave({ event }));
    },
    loadByIds: (institutionId?: number, patientId?: number, eventDefinitionId?: number, eventRepeat?: number, createPatient?: boolean, createEvent?: boolean) => {
        return client.get(new Dtos.EventGetSingleByIds({ institutionId, patientId, eventDefinitionId, eventRepeat, createPatient, createEvent }));
    },
    saveWithIds: (institutionId?: number, patientId?: number, eventDefinitionId?: number, eventRepeat?: number, createPatient?: boolean, event?: Dtos.IEvent) => {
        return client.post(new Dtos.EventPostSaveWithIds({ institutionId, patientId, eventDefinitionId, eventRepeat, createPatient, event }));
    },
    loadByCodes: (institutionCode?: string, patientStudyNumber?: string, eventDefinitionCode?: string, eventRepeat?: number, createPatient?: boolean, createEvent?: boolean) => {
        return client.get(new Dtos.EventGetSingleByCodes({ institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, createPatient, createEvent }));
    },
    saveWithCodes: (institutionCode?: string, patientStudyNumber?: string, eventDefinitionCode?: string, eventRepeat?: number, createPatient?: boolean, event?: Dtos.IEvent) => {
        return client.post(new Dtos.EventPostSaveWithCodes({ institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, createPatient, event }));
    }
});

export const createEventLogic = (api: ReturnType<typeof createEventApi>) => {
    const logics = {
        loadById: createLogic<IEventStore, {}, undefined, string, ReturnType<typeof eventActions.loadById>>({
            type: eventActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(eventActions.loadByIdSuccess(
                        action.payload,
                        response.event
                    ));
                }
                catch (error) {
                    dispatch(eventActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveById: createLogic<IEventStore, {}, undefined, string, ReturnType<typeof eventActions.saveById>>({
            type: eventActions.saveById.type,
            process: async ({ action }, dispatch, done) => {
                const [id, event] = action.payload;
                try {
                    const response = await api.save(event);

                    dispatch(eventActions.saveByIdSuccess(
                        id,
                        response.event
                    ));
                }
                catch (error) {
                    dispatch(eventActions.saveByIdFailure(id, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByIds: createLogic<any, {}, undefined, string, ReturnType<typeof eventActions.loadByIds>>({
            type: eventActions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [ institutionId, patientId, eventDefinitionId, eventRepeat, createPatient, createEvent ] = action.payload

                try {
                    const response = await api.loadByIds(institutionId ?? undefined, patientId ?? undefined, eventDefinitionId ?? undefined, eventRepeat ?? undefined, createPatient ?? undefined, createEvent ?? undefined);
                    dispatch(eventActions.loadByIdsSuccess(
                        institutionId,
                        patientId,
                        eventDefinitionId,
                        eventRepeat,
                        response.event
                    ));
                }
                catch (error) {
                    dispatch(eventActions.loadByIdsFailure(institutionId, patientId, eventDefinitionId, eventRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveByIds: createLogic<any, {}, undefined, string, ReturnType<typeof eventActions.saveByIds>>({
            type: eventActions.saveByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [ institutionId, patientId, eventDefinitionId, eventRepeat, createPatient, event ] = action.payload

                try {
                    const response = await api.saveWithIds(institutionId ?? undefined, patientId ?? undefined, eventDefinitionId ?? undefined, eventRepeat ?? undefined, createPatient ?? undefined, event);
                    dispatch(eventActions.saveByIdsSuccess(
                        institutionId,
                        patientId,
                        eventDefinitionId,
                        eventRepeat,
                        response.event
                    ));
                }
                catch (error) {
                    dispatch(eventActions.saveByIdsFailure(institutionId, patientId, eventDefinitionId, eventRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCodes: createLogic<any, {}, undefined, string, ReturnType<typeof eventActions.loadByCodes>>({
            type: eventActions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, createPatient, createEvent] = action.payload

                try {
                    const response = await api.loadByCodes(institutionCode ?? undefined, patientStudyNumber ?? undefined, eventDefinitionCode ?? undefined, eventRepeat ?? undefined, createPatient ?? undefined, createEvent ?? undefined);
                    dispatch(eventActions.loadByCodesSuccess(
                        institutionCode,
                        patientStudyNumber,
                        eventDefinitionCode,
                        eventRepeat,
                        response.event
                    ));
                }
                catch (error) {
                    dispatch(eventActions.loadByCodesFailure(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveByCodes: createLogic<any, {}, undefined, string, ReturnType<typeof eventActions.saveByCodes>>({
            type: eventActions.saveByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, createPatient, event] = action.payload

                try {
                    const response = await api.saveWithCodes(institutionCode ?? undefined, patientStudyNumber ?? undefined, eventDefinitionCode ?? undefined, eventRepeat ?? undefined, createPatient ?? undefined, event);
                    dispatch(eventActions.saveByCodesSuccess(
                        institutionCode,
                        patientStudyNumber,
                        eventDefinitionCode,
                        eventRepeat,
                        response.event
                    ));
                }
                catch (error) {
                    dispatch(eventActions.saveByCodesFailure(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByIds,
        logics.loadByCodes,
        logics.loadById,
        logics.saveById,
        logics.saveByIds,
        logics.saveByCodes
    ]
}

export const useEventSelector: TypedUseSelectorHook<IEventStore> = useSelector;

export const eventSelectors = {
    eventById: (state: IEventStore, id: number) => state.event.byId[id]?.event ?? null,
    loadStateById: (state: IEventStore, id: number) => state.event.byId[id]?.loadState ?? initialIndividualEventState.loadState,
    saveStateById: (state: IEventStore, id: number) => state.event.byId[id]?.saveState ?? initialIndividualEventState.saveState,
    eventByIds: (state: IEventStore, institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) => {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!state.event.byIds[context]) {
            return initialIndividualEventState.event;
        }

        return state.event.byIds[context].event;
    },
    loadStateByIds: (state: IEventStore, institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) => {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!state.event.byIds[context]) {
            return initialIndividualEventState.loadState;
        }

        return state.event.byIds[context].loadState;
    },
    saveStateByIds: (state: IEventStore, institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) => {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat);

        if (!state.event.byIds[context]) {
            return initialIndividualEventState.saveState;
        }

        return state.event.byIds[context].saveState;
    },
    eventByCodes: (state: IEventStore, institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) => {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!state.event.byCodes[context]) {
            return initialIndividualEventState.event;
        }

        return state.event.byCodes[context].event;
    },
    loadStateByCodes: (state: IEventStore, institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) => {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!state.event.byCodes[context]) {
            return initialIndividualEventState.loadState;
        }

        return state.event.byCodes[context].loadState;
    },
    saveStateByCodes: (state: IEventStore, institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) => {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!state.event.byCodes[context]) {
            return initialIndividualEventState.saveState;
        }

        return state.event.byCodes[context].saveState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerEventReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createEventApi(client);

    const logic = createEventLogic(api);

    reducerRegistry.register('event', eventReducer as Reducer, logic as any);
};

export default registerEventReducer;