/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { faChevronCircleDown } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleDown';

import { faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleUp';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';

import useFieldState from '../../form/hooks/useFieldState';

import useFormState from '../../form/hooks/useFormState';

import ErrorContext from '../../contexts/form/ErrorContext';

import ValidationErrorFlag from '../utility/ValidationErrorFlag';
import FormOptionsContext from '../../contexts/form/FormOptionsContext';
import { ShowErrorFunction } from './FormErrorDisplay';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export type ShowErrorFlagFunction = (errors: Dtos.IValidationError[], touched: boolean, focused: boolean, validating: boolean, isAlwaysHidden: boolean, isAlwaysShown: boolean) => boolean;

export interface IFieldErrorFlagProps {
    showMultiple?: boolean;
    showErrorFlag?: ShowErrorFlagFunction;
    showErrorFlagExt?: ShowErrorFunction;
}

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

export const DEFAULT_SHOW_ERROR_FLAG_FUNCTION: ShowErrorFlagFunction = (errors, touched, focused, validating, isAlwaysHidden, isAlwaysShown) => {
    if (isAlwaysHidden) {
        return false;
    }

    if (!isAlwaysShown && !touched && (!validating || !focused)) {
        return false;
    }

    if (!focused && (!errors || errors.length === 0) && validating) {
        return false;
    }

    return true;
}

const FieldErrorFlag: React.FunctionComponent<IFieldErrorFlagProps> = ({
    showMultiple,
    showErrorFlag,
    showErrorFlagExt
}) => {

    const { name, errors, touched, focused } = useFieldState<any, Dtos.IValidationError>({ errors: true, touched: true, focused: true });

    const { validating, errors: formErrors, touched: formTouched } = useFormState({ validating: true, touched: !!showErrorFlagExt, errors: !!showErrorFlagExt });

    const errorContext = React.useContext(ErrorContext);

    const onClick = React.useCallback(() => {
        errorContext?.setExpanded(!errorContext?.expanded);
    }, [errorContext?.expanded, errorContext?.setExpanded])

    const formsOptionsContext = React.useContext(FormOptionsContext);

    const isAlwaysShown = formsOptionsContext.fieldErrors === 'always';

    if (showErrorFlagExt) {
        if (!errors?.some(error => showErrorFlagExt(name, error, formTouched, formErrors, validating, isAlwaysShown))) {
            return null;
        }
    }
    else if (showErrorFlag) {
        if (!showErrorFlag(errors, touched, focused, validating, formsOptionsContext.fieldErrors === 'hide', formsOptionsContext.fieldErrors === 'always')) {
            return null;
        }
    }
    else if (!DEFAULT_SHOW_ERROR_FLAG_FUNCTION(errors, touched, focused, validating, formsOptionsContext.fieldErrors === 'hide', formsOptionsContext.fieldErrors === 'always')) {
        return null;
    }

    return (
        <ValidationErrorFlag
            validationErrors={errors}
            loading={validating}
            onClick={onClick}
            hoverIcon={errorContext?.expanded ? faChevronCircleUp : faChevronCircleDown}
            showMultiple={showMultiple}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FieldErrorFlag;
