/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an patient by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { PatientReducer, patientActions, usePatientSelector, patientSelectors, IPatientStore } from '../../store/modules/data/patient';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';
import useAsyncFunction from '../useAsyncFunction';
import { TypedFunction } from '../../utilities/functionTypes';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUsePatientByStudyNumberActions {
    load: BoundActionCreator<OmitFirstArg<typeof patientActions.loadByStudyNumber>>;
    save: BoundActionCreator<OmitFirstArg<typeof patientActions.saveByStudyNumber>>;
    clear: BoundActionCreator<OmitFirstArg<typeof patientActions.clearByStudyNumber>>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<OmitFirstArg<typeof patientActions.saveByStudyNumber>>>, Promise<Dtos.IPatient | undefined>>
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const usePatientByStudyNumber = <PatientType extends Dtos.IPatient>(studyNumber: string, autoLoad?: boolean): [
    PatientType | null,
    IRequestState<Dtos.ResponseStatus>,
    IRequestState<Dtos.ResponseStatus>,
    IUsePatientByStudyNumberActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(patientActions.saveByStudyNumber, patientActions.saveByStudyNumberSuccess, patientActions.saveByStudyNumberFailure);

    const actions: IUsePatientByStudyNumberActions = React.useMemo(() => {
        const load = () => patientActions.loadByStudyNumber(studyNumber);
        load.type = patientActions.loadByStudyNumber.type;

        const save = (patient?: Dtos.IPatient) => patientActions.saveByStudyNumber(studyNumber, patient);
        save.type = patientActions.saveByStudyNumber.type;

        const asyncSave = async (patient?: Dtos.IPatient) => {
            const [, savedPatient] = await unboundAsyncSave([studyNumber, patient]);

            return savedPatient;
        };

        const clear = () => patientActions.clearByStudyNumber(studyNumber);
        clear.type = patientActions.clearByStudyNumber.type;

        return {
            ...bindActionCreatorsWithType({
                load,
                save,
                clear
            }, dispatch),
            asyncSave
        };
    }, [patientActions, studyNumber, dispatch, unboundAsyncSave]);

    const patientSelector = React.useCallback((state: IPatientStore) => {
        return patientSelectors.patientByStudyNumber(state, studyNumber);
    }, [patientSelectors.patientByStudyNumber, studyNumber]);

    const loadStateSelector = React.useCallback((state: IPatientStore) => {
        return patientSelectors.loadStateByStudyNumber(state, studyNumber);
    }, [patientSelectors.loadStateByStudyNumber, studyNumber]);

    const saveStateSelector = React.useCallback((state: IPatientStore) => {
        return patientSelectors.saveStateByStudyNumber(state, studyNumber);
    }, [patientSelectors.saveStateByStudyNumber, studyNumber]);

    const patient = usePatientSelector(patientSelector);

    const loadState = usePatientSelector(loadStateSelector);

    const saveState = usePatientSelector(saveStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, studyNumber]);

    return [
        patient as PatientType | null,
        loadState,
        saveState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default usePatientByStudyNumber;