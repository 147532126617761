/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
    asyncDebounce,
    IForm,
    IFormContext,
    IFormState,
    IFormLabel,
    IInstitution,
    useAuthenticatedUser,
    useSnackbar,
    InstitutionContext,
    IValidationError,
    ValidationErrorType,
    IPatient,
    IFormValidationError,
    OnlinePatientManagementContext,
    pascalToCameCasePropertyPath,
    SnackbarVariant,
    IDtoClass
} from '@ngt/opms';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/

import * as Dtos from '../api/dtos';

import { ScreeningLog } from '../api/screeningLog';
import ScreeningLogContext from '../context/ScreeningLogContext';
import { useScreeningLogs } from './useScreeningLogs';

/*
* ---------------------------------------------------------------------------------
* Interfaces
* ---------------------------------------------------------------------------------
*/

export interface IUseScreeningLogFormOptions<TScreeningLog extends ScreeningLog = ScreeningLog> {
    formType?: IDtoClass<TScreeningLog>;
    afterFormSave?: (screeningLog?: TScreeningLog | null, form?: TScreeningLog | null) => void;
    onCancel?: () => void;
    readOnly?: boolean;
}

const errorVariantMapping: Record<ValidationErrorType, SnackbarVariant> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Ineligible]: 'ineligible',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.StratificationFailure]: 'stratification-failure',
    [ValidationErrorType.Critical]: 'critical'
}

const errorTextMapping: Record<ValidationErrorType, string> = {
    [ValidationErrorType.Warning]: 'warning',
    [ValidationErrorType.Ineligible]: 'ineligibility warning',
    [ValidationErrorType.Normal]: 'error',
    [ValidationErrorType.StratificationFailure]: 'stratification failure',
    [ValidationErrorType.Critical]: 'critical error'
}

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const useScreeningLogForm = <TScreeningLog extends ScreeningLog = ScreeningLog>({
    formType,
    afterFormSave,
    onCancel,
    readOnly
}: IUseScreeningLogFormOptions<TScreeningLog>) => {
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { institution, loadState: institutionLoadState, actions: institutionActions } = React.useContext(InstitutionContext);

    const { screeningLog, loadState, saveState, actions } = React.useContext(ScreeningLogContext);

    const [screeningLogs, screeningLogsLoadState, screeningLogsActions] = useScreeningLogs<TScreeningLog>(institution?.id, false);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const onFormCancel = React.useCallback(() => {
        if (onCancel) {
            onCancel();
        }

        actions.clear();

    }, [history, institution, onCancel]);

    const onFormSubmit = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningLog, IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'save');
        }
    }, []);

    const onFormDelete = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningLog, IValidationError>) => {
        if (!formActions?.getSubmitting()) {
            formActions?.setFieldValue('submitType', 'delete');
        }
    }, []);

    const onValidate = React.useCallback(async (formState: IFormState<TScreeningLog, IValidationError>) => {

        // Send request to server.
        const response = await onlinePatientManagement
            .serviceStackClient
            .post(new Dtos.ScreeningLogPostValidate({ screeningLog: formState.values }));

        // parse errors into a format the form understands.
        const groupErrors = response.validationResult?.errors?.reduce((a: Record<string, IFormValidationError[]>, b: IFormValidationError) => {
            const propertyName = pascalToCameCasePropertyPath(b.property)

            if (!a[propertyName]) {
                a[propertyName] = [];
            }

            a[propertyName].push(b);

            return a;
        }, {}) ?? {};

        return groupErrors;

    }, [onlinePatientManagement.serviceStackClient, pascalToCameCasePropertyPath]);

    // debounce validation functions to reduce calls to the server and associate lag.
    const debouncedValidate = React.useMemo(() => {
        return asyncDebounce(onValidate, 500);
    }, [onValidate]);

    const onFormSubmitValidationFailure = React.useCallback(async ({ errors }: IFormState<TScreeningLog, IValidationError>, validationError: boolean) => {
        if (validationError) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Screening Log Not Saved
                    </AlertTitle>
                    An error occurred while attempting to save the form.
                </>,
                { variant: 'critical' }
            );
        }
        else {
            const criticalErrors = Object
                .keys(errors)
                .reduce((array: IValidationError[], key: string) => {
                    const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                        if (e.type === ValidationErrorType.Normal) {
                            return [...propertyArray, e]
                        }

                        return propertyArray;
                    }, [])

                    return [...array, ...propertyErrors]
                }, []);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Notification Not Sent
                    </AlertTitle>
                    Please correct the {criticalErrors.length} blocking {pluralize('error', criticalErrors.length)} and submit the form again.
                </>,
                { variant: 'critical' }
            );
        }
    }, [enqueueSnackbar]);

    const onFormSubmitFailure = React.useCallback(async (formState: IFormState<TScreeningLog, IValidationError>) => {
        enqueueSnackbar(
            <>
                <AlertTitle>
                    Notification Not Sent
                    </AlertTitle>
                    An error occurred while attempting to send the notification.
            </>,
            { variant: 'critical' }
        );
    }, []);

    const handleSubmit = React.useCallback(async ({ values, errors }: IFormState<TScreeningLog, IValidationError>) => {
        const { submitType, ...form } = values as any;

        //add institution when creating a new screening log
        if (!values.id) {
            institutionActions.load();
            values.institutionId = institution?.id;
        }

        const result = submitType === 'save' ? await actions?.asyncSave(values as TScreeningLog) : await actions?.asyncDelete(values as TScreeningLog);
      
        const allErrors = Object
            .keys(errors)
            .reduce((array: IValidationError[], key: string) => {
                const propertyErrors = errors[key]?.reduce((propertyArray: IValidationError[], e: IValidationError) => {
                    return [...propertyArray, e]
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        const maxErrorType = allErrors.reduce((maxError: ValidationErrorType | undefined, error) => (error.type ?? 0) > (maxError ?? 0) ? error.type : maxError, undefined);

        if (!allErrors || allErrors.length == 0) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Screening Log {submitType === 'save' ? 'Saved' : 'Deleted'}
                    </AlertTitle>
                    The screening log was successfully {submitType === 'save' ? 'saved' : 'deleted'}.
                </>,
                { variant: 'success' }
            );

            if (onCancel) {
                onCancel();
            }

            screeningLogsActions?.load();
        }

        if (submitType === 'save') {
            if (afterFormSave) {
                afterFormSave(screeningLog as unknown as TScreeningLog, result as unknown as TScreeningLog);
            }
        }


        
    }, [institution, afterFormSave, history, actions?.asyncSave, onCancel])

    return {
        handleSubmit,
        onFormCancel,
        onFormSubmit,
        onFormDelete,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate: debouncedValidate
    }
}

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useScreeningLogForm;

