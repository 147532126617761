/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the form reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators, ActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to type special reducer registry.
 */
import ActionReducerRegistry from '../../../utilities/ActionRegistryReducer';

/*
 * Used to type Dto classes.
 */
import IDtoRequestClass from '../../../utilities/IDtoRequestClass';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualFormState {
    form: Dtos.IForm | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
    saveState: IRequestState<Dtos.ResponseStatus>;
}

export interface IFormState {
    byId: Record<number, IIndividualFormState>;
    byCodes: Record<string, IIndividualFormState>;
    byIds: Record<string, IIndividualFormState>;
}

export interface IFormStore {
    form: Record<string, IFormState>;
}

interface IFormSingleResponse {
    form?: Dtos.IForm;
    responseStatus?: Dtos.ResponseStatus;
}

interface IFormPostSave {
    form?: Dtos.IForm;
}

interface IFormPostSaveWithIds {
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    form?: Dtos.IForm;
}

interface IFormPostSaveWithCodes {
    institutionCode?: string;
    patientStudyNumber?: string;
    eventDefinitionCode?: string;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    form?: Dtos.IForm;
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null) => {
    return `${(institutionId ?? 'null')}-${(patientId ?? 'null')}-${(eventDefinitionId ?? 'null')}-${(eventRepeat ?? 'null')}-${(formRepeat ?? 'null')}`;
}

const createCodesContext = (institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null) => {
    return `${(institutionCode ?? 'null')}-${(patientStudyNumber ?? 'null')}-${(eventDefinitionCode ?? 'null')}-${(eventRepeat ?? 'null')}-${(formRepeat ?? 'null')}`;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all form reducer state changes and actions. 
 */
export class FormReducer extends ImmerReducer<IFormState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, form?: Dtos.IForm) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormState };
        }

        this.draftState.byId[id].form = form ? form : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveById(id: number, form?: Dtos.IForm) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormState };
        }

        this.draftState.byId[id].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByIdSuccess(id: number, form?: Dtos.IForm) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormState };
        }

        this.draftState.byId[id].form = form ? form : null;

        this.draftState.byId[id].saveState = {
            state: RequestState.Success
        };
    }

    public saveByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualFormState };
        }

        this.draftState.byId[id].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public loadByIds(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null, createPatient?: boolean | null, createEvent?: boolean | null, createForm?: boolean | null) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdsSuccess(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null, form?: Dtos.IForm) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormState };
        }

        this.draftState.byIds[context].form = form ? form : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveByIds(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null, createPatient?: boolean | null, createEvent?: boolean | null, form?: Dtos.IForm) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormState };
        }

        this.draftState.byIds[context].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByIdsSuccess(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null, form?: Dtos.IForm) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormState };
        }

        this.draftState.byIds[context].form = form ? form : null;

        this.draftState.byIds[context].saveState = {
            state: RequestState.Success
        };
    }

    public saveByIdsFailure(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormState };
        }

        this.draftState.byIds[context].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null) {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (this.draftState.byIds[context]) {
            delete this.draftState.byIds[context];
        }
    }

    public loadByCodes(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null, createPatient?: boolean | null, createEvent?: boolean | null, createForm?: boolean | null) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByCodesSuccess(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null, form?: Dtos.IForm) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormState };
        }

        this.draftState.byCodes[context].form = form ? form : null;

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodesFailure(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public saveByCodes(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null, createPatient?: boolean | null, createEvent?: boolean | null, form?: Dtos.IForm) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormState };
        }

        this.draftState.byCodes[context].saveState = {
            state: RequestState.Pending
        }
    }

    public saveByCodesSuccess(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null, form?: Dtos.IForm) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormState };
        }

        this.draftState.byCodes[context].form = form ? form : null;

        this.draftState.byCodes[context].saveState = {
            state: RequestState.Success
        };
    }

    public saveByCodesFailure(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormState };
        }

        this.draftState.byCodes[context].saveState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByCodes(institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null) {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (this.draftState.byCodes[context]) {
            delete this.draftState.byCodes[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialFormState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualFormState: IIndividualFormState = {
    form: null,
    loadState: {
        state: RequestState.None
    },
    saveState: {
        state: RequestState.None
    }
};

export const initialFormState: IFormState = {
    byCodes: {},
    byIds: {},
    byId: {}
}

const createNamedFormReducer = (formMetadata: Dtos.FormMetadata) => {
    let NamedReducer = class extends FormReducer { };

    Object.defineProperty(NamedReducer, 'name', { value: `${formMetadata.name}FormReducer` });

    return NamedReducer;
};

const createEventApi = (formMetadata: Dtos.FormMetadata, dtos: Record<string, any>, client: JsonServiceClient) => {
    const FormGetSingleById: IDtoRequestClass<Dtos.IFormGetSingleById, IFormSingleResponse> = dtos[`${formMetadata.name}GetSingleById`];
    const FormGetSingleByIds: IDtoRequestClass<Dtos.IFormGetSingleByIds, IFormSingleResponse> = dtos[`${formMetadata.name}GetSingleByIds`];
    const FormGetSingleByCodes: IDtoRequestClass<Dtos.IFormGetSingleByCodes, IFormSingleResponse> = dtos[`${formMetadata.name}GetSingleByCodes`];

    const FormPostSave: IDtoRequestClass<IFormPostSave, IFormSingleResponse> = dtos[`${formMetadata.name}PostSave`];
    const FormPostSaveWithIds: IDtoRequestClass<IFormPostSaveWithIds, IFormSingleResponse> = dtos[`${formMetadata.name}PostSaveWithIds`];
    const FormPostSaveWithCodes: IDtoRequestClass<IFormPostSaveWithCodes, IFormSingleResponse> = dtos[`${formMetadata.name}PostSaveWithCodes`];

    return {
        loadById: (id: number) => {
            return client.get(new FormGetSingleById({ id }));
        },
        save: (form?: Dtos.IForm) => {
            return client.post(new FormPostSave({ form }));
        },
        loadByIds: (institutionId?: number, patientId?: number, eventDefinitionId?: number, eventRepeat?: number, formRepeat?: number, createPatient?: boolean, createEvent?: boolean, createForm?: boolean) => {
            return client.get(new FormGetSingleByIds({ institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat, createPatient, createEvent, createForm }));
        },
        saveWithIds: (institutionId?: number, patientId?: number, eventDefinitionId?: number, eventRepeat?: number, formRepeat?: number, createPatient?: boolean, createEvent?: boolean, form?: Dtos.IForm) => {
            return client.post(new FormPostSaveWithIds({ institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat, createPatient, createEvent, form }));
        },
        loadByCodes: (institutionCode?: string, patientStudyNumber?: string, eventDefinitionCode?: string, eventRepeat?: number, formRepeat?: number, createPatient?: boolean, createEvent?: boolean, createForm?: boolean) => {
            return client.get(new FormGetSingleByCodes({ institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, createForm }));
        },
        saveWithCodes: (institutionCode?: string, patientStudyNumber?: string, eventDefinitionCode?: string, eventRepeat?: number, formRepeat?: number, createPatient?: boolean, createEvent?: boolean, form?: Dtos.IForm) => {
            return client.post(new FormPostSaveWithCodes({ institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, form }));
        }
    }
};

export const createEventLogic = (actions: ActionCreators<typeof FormReducer>, api: ReturnType<typeof createEventApi>) => {
    const logics = {
        loadById: createLogic<IFormStore, {}, undefined, string, ReturnType<typeof actions.loadById>>({
            type: actions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(actions.loadByIdSuccess(
                        action.payload,
                        response.form
                    ));
                }
                catch (error) {
                    dispatch(actions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveById: createLogic<IFormStore, {}, undefined, string, ReturnType<typeof actions.saveById>>({
            type: actions.saveById.type,
            process: async ({ action }, dispatch, done) => {
                const [id, form] = action.payload;
                try {
                    const response = await api.save(form);

                    dispatch(actions.saveByIdSuccess(
                        id,
                        response.form
                    ));
                }
                catch (error) {
                    dispatch(actions.saveByIdFailure(id, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByIds: createLogic<any, {}, undefined, string, ReturnType<typeof actions.loadByIds>>({
            type: actions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [ institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat, createPatient, createEvent, createForm ] = action.payload

                try {
                    const response = await api.loadByIds(institutionId ?? undefined, patientId ?? undefined, eventDefinitionId ?? undefined, eventRepeat ?? undefined, formRepeat ?? undefined, createPatient ?? undefined, createEvent ?? undefined, createForm ?? undefined);
                    dispatch(actions.loadByIdsSuccess(
                        institutionId,
                        patientId,
                        eventDefinitionId,
                        eventRepeat,
                        formRepeat,
                        response.form
                    ));
                }
                catch (error) {
                    dispatch(actions.loadByIdsFailure(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveByIds: createLogic<any, {}, undefined, string, ReturnType<typeof actions.saveByIds>>({
            type: actions.saveByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [ institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat, createPatient, createEvent, form ] = action.payload

                try {
                    const response = await api.saveWithIds(institutionId ?? undefined, patientId ?? undefined, eventDefinitionId ?? undefined, eventRepeat ?? undefined, formRepeat ?? undefined, createPatient ?? undefined, createEvent ?? undefined, form);
                    dispatch(actions.saveByIdsSuccess(
                        institutionId,
                        patientId,
                        eventDefinitionId,
                        eventRepeat,
                        formRepeat,
                        response.form
                    ));
                }
                catch (error) {
                    dispatch(actions.saveByIdsFailure(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCodes: createLogic<any, {}, undefined, string, ReturnType<typeof actions.loadByCodes>>({
            type: actions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, createForm] = action.payload

                try {
                    const response = await api.loadByCodes(institutionCode ?? undefined, patientStudyNumber ?? undefined, eventDefinitionCode ?? undefined, eventRepeat ?? undefined, formRepeat ?? undefined, createPatient ?? undefined, createEvent ?? undefined, createForm ?? undefined);
                    dispatch(actions.loadByCodesSuccess(
                        institutionCode,
                        patientStudyNumber,
                        eventDefinitionCode,
                        eventRepeat,
                        formRepeat,
                        response.form
                    ));
                }
                catch (error) {
                    dispatch(actions.loadByCodesFailure(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        saveByCodes: createLogic<any, {}, undefined, string, ReturnType<typeof actions.saveByCodes>>({
            type: actions.saveByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, form] = action.payload

                try {
                    const response = await api.saveWithCodes(institutionCode ?? undefined, patientStudyNumber ?? undefined, eventDefinitionCode ?? undefined, eventRepeat ?? undefined, formRepeat ?? undefined, createPatient ?? undefined, createEvent ?? undefined, form);
                    dispatch(actions.saveByCodesSuccess(
                        institutionCode,
                        patientStudyNumber,
                        eventDefinitionCode,
                        eventRepeat,
                        formRepeat,
                        response.form
                    ));
                }
                catch (error) {
                    dispatch(actions.saveByCodesFailure(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByIds,
        logics.loadByCodes,
        logics.loadById,
        logics.saveById,
        logics.saveByIds,
        logics.saveByCodes
    ]
}

export const useFormSelector: TypedUseSelectorHook<IFormStore> = useSelector;

export const formSelectors = {
    formById: (state: IFormStore, formPropertyName: string, id: number) => state.form[formPropertyName]?.byId[id]?.form ?? initialIndividualFormState.form,
    loadStateById: (state: IFormStore, formPropertyName: string, id: number) => state.form[formPropertyName]?.byId[id]?.loadState ?? initialIndividualFormState.loadState,
    saveStateById: (state: IFormStore, formPropertyName: string, id: number) => state.form[formPropertyName]?.byId[id]?.saveState ?? initialIndividualFormState.saveState,
    formByIds: (state: IFormStore, formPropertyName: string, institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null) => {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!state.form[formPropertyName]?.byIds[context]) {
            return initialIndividualFormState.form;
        }

        return state.form[formPropertyName].byIds[context].form;
    },
    loadStateByIds: (state: IFormStore, formPropertyName: string, institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null) => {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!state.form[formPropertyName]?.byIds[context]) {
            return initialIndividualFormState.loadState;
        }

        return state.form[formPropertyName].byIds[context].loadState;
    },
    saveStateByIds: (state: IFormStore, formPropertyName: string, institutionId?: number | null, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, formRepeat?: number | null) => {
        const context = createIdsContext(institutionId, patientId, eventDefinitionId, eventRepeat, formRepeat);

        if (!state.form[formPropertyName]?.byIds[context]) {
            return initialIndividualFormState.saveState;
        }

        return state.form[formPropertyName].byIds[context].saveState;
    },
    formByCodes: (state: IFormStore, formPropertyName: string, institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null) => {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!state.form[formPropertyName]?.byCodes[context]) {
            return initialIndividualFormState.form;
        }

        return state.form[formPropertyName].byCodes[context].form;
    },
    loadStateByCodes: (state: IFormStore, formPropertyName: string, institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null) => {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!state.form[formPropertyName]?.byCodes[context]) {
            return initialIndividualFormState.loadState;
        }

        return state.form[formPropertyName].byCodes[context].loadState;
    },
    saveStateByCodes: (state: IFormStore, formPropertyName: string, institutionCode?: string | null, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, formRepeat?: number | null) => {
        const context = createCodesContext(institutionCode, patientStudyNumber, eventDefinitionCode, eventRepeat, formRepeat);

        if (!state.form[formPropertyName]?.byCodes[context]) {
            return initialIndividualFormState.saveState;
        }

        return state.form[formPropertyName].byCodes[context].saveState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerFormReducer = (formMetadata: Dtos.FormMetadata, dtos: Record<string, any>, client: JsonServiceClient, reducerRegistry: ActionReducerRegistry) => {
    if (!formMetadata?.propertyName) {
        return;
    }

    const NamedReducer = createNamedFormReducer(formMetadata);

    const actions = createActionCreators(NamedReducer);
    const reducer = createReducerFunction(NamedReducer, initialFormState);

    const api = createEventApi(formMetadata, dtos, client);

    const logic = createEventLogic(actions, api);

    reducerRegistry.register(formMetadata.propertyName, reducer as Reducer, logic as any);
    reducerRegistry.registerActions(formMetadata.propertyName, actions);
};

export default registerFormReducer;