/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, darken, lighten, Typography, TextField, InputAdornment, CircularProgress, Button, Tooltip } from '@material-ui/core';

import { CtcaeSelector as NgtCtcaeSelector, ICtcaeSelectorProps as INgtCtcaeSelectorProps, ICtcaeSelectorRenderProps } from '@ngt/react-ctcae';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';

import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';

import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';

import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps } from '../../../form/components/Input';

import useRelatedInput from '../../../form/hooks/useRelatedInput';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export const TYPE_SELECTOR = 'Selector';
export const TYPE_SELECTOR_WITH_NAME = 'SelectorWithName';

type CtcaeSelectorType = typeof TYPE_SELECTOR | typeof TYPE_SELECTOR_WITH_NAME;

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type CtcaeSelectorsProps = Partial<Omit<INgtCtcaeSelectorProps, 'render' | 'gradeId' | 'termId' | 'specification' | 'onChange' | 'onBlur' | 'onFocus'>> & IInputRenderProps<number, Dtos.IValidationError>

export interface ICtcaeSelectorProps extends CtcaeSelectorsProps {
    display?: CtcaeSelectorType;
    gradeFieldName: string;
    specificationFieldName: string;
    onChange?: (ctcaeState: ICtcaeSelectorRenderProps['state']) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    text: {
    }
}));

const useCtcaeDisplayWithNameStyles = makeStyles<Theme>(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    name: {
        flex: '1 1 auto',
        marginRight: theme.spacing(2)
    },
    selector: {
        minWidth: 240,
        maxWidth: 240
    }
}));

const useCtcaeDisplayStyles = makeStyles<Theme>(theme => {
    const getColor = theme.palette.type === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.type === 'light' ? lighten : darken;

    return {
        container: {
            display: 'flex'
        },
        value: {
            flex: '1 1 auto'
        },
        info: {
            curosr: 'help',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(0, 1),
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
            '& > *': {
                color: theme.palette.info.main,
            }
        },
        remove: {
            minWidth: 'auto',
            borderRadius: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: theme.spacing(0, 1),
            color: getColor(theme.palette.error.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.error.main, 0.9),
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: getBorderColor(theme.palette.error.main, 0.8),
            '& > *': {
                color: theme.palette.error.main,
            }
        },
        error: {
            color: theme.palette.error.main
        },
        infoTitle: {
            fontWeight: 'bold'
        },
        tooltip: {
            padding: theme.spacing(0),
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
        },
        infoSection: {
            padding: theme.spacing(1),
            color: theme.palette.info.main,
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            borderBottomColor: getBorderColor(theme.palette.info.main, 0.8),

            '&:last-child': {
                borderBottom: 'none'
            }
        }
    }
});

const useCtcaeDisplayTooltipStyles = makeStyles<Theme>(theme => {
    const getColor = theme.palette.type === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.type === 'light' ? lighten : darken;

    return {
        tooltip: {
            padding: theme.spacing(0),
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
        }
    }
});


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const CtcaeDisplayWithName: React.FunctionComponent<ICtcaeSelectorRenderProps> = ({
    error,
    loading,
    isOpen,
    open,
    close,
    targetTermId,
    state,
    setCtcaeState,
    disabled,
    onBlur,
    onFocus
}) => {
    const classes = useCtcaeDisplayWithNameStyles();

    const termName = React.useMemo(() => {
        if (state?.selection?.term) {
            if (state.selection.term.specifyOther && state.selection.specification) {
                return `${state.selection.term.name} (${state.selection.specification})`
            }

            return state.selection.term.name
        }

        return 'Adverse Event'
    }, [state])

    return (
        // Displayed as a flex with width 100%.
        <div
            className={classes.container}
        >
            { /* flex: 1 1 auto; so that it takes all remaining space */}
            <div
                className={classes.name}
            >
                <Typography>
                    {termName}
                </Typography>
            </div>
            { /* set a perdetermined min / max width on this div, as it only needs to be able to display the static state text the CTCAE display shows, the rest of the space should go to showing the term name */}
            <div
                className={classes.selector}
            >
                <CtcaeDisplay
                    error={error}
                    loading={loading}
                    targetTermId={targetTermId}
                    state={state}
                    setCtcaeState={setCtcaeState}
                    disabled={disabled}
                    isOpen={isOpen}
                    open={open}
                    close={close}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </div>
        </div>
    )
};

const CtcaeDisplay: React.FunctionComponent<ICtcaeSelectorRenderProps> = ({
    error,
    loading,
    isOpen,
    open,
    close,
    targetTermId,
    state,
    setCtcaeState,
    onBlur,
    onFocus,
    disabled
}) => {
    const classes = useCtcaeDisplayStyles();
    const tooltipClasses = useCtcaeDisplayTooltipStyles();

    React.useEffect(() => {
        () => {
            close();
        };
    }, [close])

    const inputRef = React.useRef<HTMLInputElement>();

    const extendedOnBlur = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (isOpen()) {
            return;
        }

        if (onBlur) {
            onBlur(event)
        }
    }, [onBlur, isOpen]);

    const valueText = React.useMemo(() => {
        if (loading) {
            return 'Loading...'
        }

        if (error) {
            return 'An Error Has Occurred'
        }

        if (state?.selection?.grade) {
            return `Grade ${state.selection.grade.name}`;
        }

        if (state?.selection?.term) {
            if (disabled) {
                return 'No Grade Selected'
            }

            return 'Select a Grade';
        }

        if (disabled) {
            return 'No CTCAE Selected';
        }

        return 'Select CTCAE'
    }, [disabled, state, loading, error]);

    const onKeyPress = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (event.charCode === 32) {
            open();
        }
    }, [open]);

    const onClick = React.useCallback(() => {
        if (!loading) {
            open();
        }
    }, [open, loading]);

    const remove = React.useCallback(() => {
        setCtcaeState(null);

        inputRef.current?.focus();
    }, [setCtcaeState, inputRef]);

    

    return (
        // Displayed as a flex with width 100%.
        <div
            className={classes.container}
        >
            { /* flex: 1 1 auto; so that it takes all remaining space */}
            <div
                className={classes.value}
            >
                {
                    /* 
                     * shows text based on current state, typing disabled by making text field readonly ALWAYS.
                     * clicking text field while not loading or disabled calls open (which opens the CTCAE browser).
                     * hitting space while not disabled does the same as clicking (when text field is focused, still possible while read only, not possible while disabled)
                    */
                }
                <TextField
                    value={valueText}
                    onKeyPress={onKeyPress}
                    fullWidth={true}
                    inputRef={inputRef}
                    inputProps={{
                        readOnly: true,
                        onFocus,
                        onBlur: extendedOnBlur
                    }}
                    InputProps={{
                        startAdornment: loading || error ?
                            <InputAdornment position="start">
                                {
                                    loading && !error && (
                                        <CircularProgress size={16} />
                                    )
                                }
                                {
                                    error && (
                                        <FontAwesomeIcon className={classes.error} fixedWidth icon={faExclamationCircle} />
                                    )
                                }
                            </InputAdornment> :
                            undefined,
                        endAdornment: !disabled && !loading ?
                            <InputAdornment position="end">
                                <FontAwesomeIcon fixedWidth icon={faChevronDown} />
                            </InputAdornment> :
                            undefined
                    }}
                    onClick={onClick}
                    disabled={disabled}
                />
            </div>
            { /* remove icon, on click removes state. */}
            {
                !disabled && state?.selection?.term && (!targetTermId || state?.selection?.grade) && (
                    <Button
                        className={classes.remove}
                        onClick={remove}
                    >
                        <FontAwesomeIcon fixedWidth icon={faTimes} />
                    </Button>
                )
            }
            { /* info icon, on mouse over shows info about selected term */}
            {
                state?.selection?.term && (
                    <Tooltip
                        classes={tooltipClasses}
                        title={
                            <>
                                {
                                    state?.selection?.category && (
                                        <div
                                            className={classes.infoSection}
                                        >
                                            <div>
                                                <Typography
                                                    variant="subtitle1"
                                                >
                                                    Category
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    {state.selection.category.name}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    state?.selection?.term && (
                                        <div
                                            className={classes.infoSection}
                                        >
                                            <div>
                                                <Typography
                                                    variant="subtitle1"
                                                >
                                                    Term
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    {state.selection.term.name}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {state.selection.term.description}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    state?.selection?.subterm && (
                                        <div
                                            className={classes.infoSection}
                                        >
                                            <div>
                                                <Typography
                                                    variant="subtitle1"
                                                >
                                                    Subterm
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    {state.selection.subterm.name}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {state.selection.subterm.description}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    state?.selection?.grade && (
                                        <div
                                            className={classes.infoSection}
                                        >
                                            <div>
                                                <Typography
                                                    variant="subtitle1"
                                                >
                                                    Grade
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="subtitle2"
                                                >
                                                    Grade {state.selection.grade.name}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {state.selection.grade.description}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        }
                    >
                        <div
                            className={classes.info}
                        >
                            <FontAwesomeIcon fixedWidth icon={faInfoCircle} />
                        </div>
                    </Tooltip>
                )
            }
        </div>
    )
};

const CtcaeSelector: React.FunctionComponent<ICtcaeSelectorProps> = ({
    display,
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    onChange,
    gradeFieldName,
    specificationFieldName,
    ctcaeUrl,
    ctcaeVersion,
    ...rest
}) => {
    const parentName = React.useMemo(() => {
        return name.split('.').filter((namePart, index, nameParts) => index !== nameParts.length - 1).join('.');
    }, [name]);

    const completeGradeFieldName = React.useMemo(() => {
        if (parentName) {
            return `${parentName}.${gradeFieldName}`;
        }

        return gradeFieldName;
    }, [gradeFieldName, parentName]);

    const completeSpecificationFieldName = React.useMemo(() => {
        if (parentName) {
            return `${parentName}.${specificationFieldName}`;
        }

        return specificationFieldName;
    }, [specificationFieldName, parentName]);

    const { state: { value: gradeValue }, actions: { update: onGradeChange } } = useRelatedInput<number, Dtos.IValidationError>(completeGradeFieldName, { value: true });
    const { state: { value: specificationValue }, actions: { update: onSpecificationChange } } = useRelatedInput<string, Dtos.IValidationError>(completeSpecificationFieldName, { value: true } );

    const component = display === TYPE_SELECTOR_WITH_NAME ? CtcaeDisplayWithName : CtcaeDisplay;


    const onChangeCombined = React.useCallback((state: ICtcaeSelectorRenderProps['state']) => {
        if (onChange) {
            onChange(state);
        }

        if (state?.selection?.grade?.id) {
            onGradeChange(state.selection.grade?.id, true);
        }
        else {
            onGradeChange(null, true);
        }

        if (state?.selection?.term?.id) {
            onInputChange(state.selection.term?.id, true);
        }
        else {
            onInputChange(null, true);
        }

        if (state?.selection?.specification) {
            onSpecificationChange(state.selection.specification, true);
        }
        else {
            onSpecificationChange(null, true);
        }


    }, [onInputChange, onChange, onGradeChange, onSpecificationChange])

    return (
        <NgtCtcaeSelector
            ctcaeUrl={ctcaeUrl ?? "https://ctcae.breastcancertrials.org.au"}
            ctcaeVersion={ctcaeVersion ?? "5.0"}
            render={component}
            termId={value}
            gradeId={gradeValue}
            specification={specificationValue}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChangeCombined}
            {...rest as any}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CtcaeSelector;
