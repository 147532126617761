/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an saes by institution codes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg,
    ResponseStatus,
    IPatient 
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { ScreeningLogsReducer, screeningLogsActions, useScreeningLogsSelector, screeningLogsSelectors, IScreeningLogsStore } from '../store/screeningLogs';
import { ScreeningLog } from '../api/screeningLog';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseScreeningLogsActions {
    load: BoundActionCreator<OmitFirstArg<typeof screeningLogsActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof screeningLogsActions.clear>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useScreeningLogs = <TScreeningLog extends ScreeningLog = ScreeningLog>(institutionId?: number, autoLoad?: boolean): [
    Array<TScreeningLog> | null,
    IRequestState<ResponseStatus>,
    IUseScreeningLogsActions
] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => screeningLogsActions.load(institutionId);
        load.type = screeningLogsActions.load.type;

        const clear = () => screeningLogsActions.clear(institutionId);
        clear.type = screeningLogsActions.clear.type;

        return bindActionCreators({
            load,
            clear
        }, dispatch);
    }, [screeningLogsActions, institutionId, dispatch]);

    const screeningLogsSelector = React.useCallback((state: IScreeningLogsStore) => {
        return screeningLogsSelectors.screeningLogs(state, institutionId)
    }, [screeningLogsSelectors.screeningLogs, institutionId]);

    const loadStateSelector = React.useCallback((state: IScreeningLogsStore) => {
        return screeningLogsSelectors.loadState(state, institutionId)
    }, [screeningLogsSelectors.loadState, institutionId]);

    const screeningLogs = useScreeningLogsSelector(screeningLogsSelector);

    const loadState = useScreeningLogsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, institutionId]);

    return [
        screeningLogs as Array<TScreeningLog> | null,
        loadState,
        actions
    ];
};

