/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { Form, IFormLabel, IFormContext, IValidationError, IFormState, SubmitButton, RouteLoading } from '@ngt/opms';
import { Button, DialogActions } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ScreeningLog } from '../api/screeningLog';
import useScreeningLogForm from '../hooks/useScreeningLogForm';
import ScreeningLogContext from '../context/ScreeningLogContext';
import { ScreeningLogDialogMode } from './ScreeningLogDialog';
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IScreeningLogFormProps<TScreeningLog extends ScreeningLog = ScreeningLog> {
    formFieldGroup?: any;
    formDisable?: boolean;
    formLabels?: IFormLabel[];
    formMode?: ScreeningLogDialogMode;
    handleSubmit?: ({ values, errors }: IFormState<TScreeningLog, IValidationError>) => Promise<void>;
    onFormSubmit?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningLog, IValidationError>) => void; 
    onFormDelete?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TScreeningLog, IValidationError>) => void; 
    onFormCancel?: () => void;
    validate?: any;
    onSubmitFailed?: any;
    onSubmitValidationFailure?: any;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningLogForm = ({
    formFieldGroup,
    formDisable,
    formLabels,
    formMode,
    onFormSubmit,
    handleSubmit,
    onFormDelete,
    onFormCancel,
    onSubmitFailed,
    onSubmitValidationFailure,
    validate
}: IScreeningLogFormProps) => {

    const screeningLogContext = React.useContext(ScreeningLogContext);

    const notLoading = screeningLogContext.loadState.state !== RequestState.Pending;

    return (
        <>
            {
                notLoading ? <Form
                    initialValues={screeningLogContext?.screeningLog}
                    onValidate={validate}
                    validateOn="onChange"
                    allowSubmit={undefined}
                    fieldErrors={'default'}
                    onSubmit={handleSubmit}
                    labels={formLabels}
                    lookups={undefined}
                    readOnly={formDisable ?? false}
                    onSubmitFailed={onSubmitFailed}
                    onSubmitValidationFailed={onSubmitValidationFailure}
                >
                    {formFieldGroup}

                    {
                        <DialogActions>
                            <Button onClick={onFormCancel} color="secondary" disabled={false}>
                                Cancel
                            </Button>
                            {
                                formMode !== ScreeningLogDialogMode.delete ? <SubmitButton onClick={onFormSubmit} color="primary" disabled={false}>
                                        Save
                                    </SubmitButton> :
                                    <SubmitButton onClick={onFormDelete} color="primary" disabled={false}>
                                            Delete
                                    </SubmitButton>
                            }
                        </DialogActions>
                    }
                </Form>
                : <RouteLoading />
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningLogForm;

