/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import FieldGroupContext from '../../../contexts/form/FieldGroupContext';
import FieldProvider from '../FieldProvider';
import FormLabel from '../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../FormErrorHandler';
import FormErrorDisplay, { ShowErrorFunction } from '../FormErrorDisplay';
import FieldErrorFlag, { ShowErrorFlagFunction } from '../FieldErrorFlag';
import Input, { IInputProps } from '../Input';
import { InputComponentType, OmitInputRender } from '../../../form/components/Input';
import useFieldState from '../../../form/hooks/useFieldState';
import useFieldActions from '../../../form/hooks/useFieldActions';
import { IFieldSubscription, IFieldState } from '../../../form/Form';
import { IUseFieldReturn } from '../../../form/hooks/useField';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IStaticFieldComponentProps<TValue = any> {
    fieldState: IFieldState<TValue, Dtos.IValidationError>;
    fieldActions: IUseFieldReturn<TValue, Dtos.IValidationError>;
}

export interface IStaticFieldProps {
    name: string;
    component?: React.ComponentType<IStaticFieldComponentProps>;
    subscription?: Partial<IFieldSubscription>;
    prependParentName?: boolean;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const StaticField: React.FunctionComponent<IStaticFieldProps> = ({
    name,
    component,
    subscription,
    prependParentName
}) => {
    return (
        <FieldProvider name={name} autoRegister={false} prependParentName={prependParentName}>
            <StaticFieldInternal component={component} name={name} subscription={subscription} />
        </FieldProvider>
    )
}

const StaticFieldInternal: React.FunctionComponent<IStaticFieldProps> = ({
    name,
    component,
    subscription
}) => {
    const fieldState = useFieldState(subscription);
    const fieldActions = useFieldActions();

    if (!component) {
        return (
            <>
                {fieldState.value}
            </>
        );
    }

    const Component = component;

    return (
        <Component fieldState={fieldState} fieldActions={fieldActions} />
    )
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default StaticField;
