/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to get the form context.actions.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.actions.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import FieldContext, { IFieldContext } from '../contexts/FieldContext';

import { IFieldState, IFieldSubscription } from '../Form';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useFieldState = <TValue = any, TError = any>(subscription?: Partial<IFieldSubscription>) => {
    const context: IFieldContext<TValue, TError> | null = React.useContext(FieldContext);

    if (context === null) {
        throw new Error("Field Context does not exist");
    }

    const [state, setState] = React.useState<IFieldState<TValue, TError> & { name: string }>(() => {
        return {
            name: context.name,
            value: context.actions.getValue(),
            dirty: context.actions.getDirty(),
            errors: context.actions.getErrors(),
            focused: context.actions.getFocused(),
            initialValue: context.actions.getInitialValue(),
            touched: context.actions.getTouched()
        };
    });

    React.useEffect(() => {
        setState({
            name: context.name,
            value: context.actions.getValue(),
            dirty: context.actions.getDirty(),
            errors: context.actions.getErrors(),
            focused: context.actions.getFocused(),
            initialValue: context.actions.getInitialValue(),
            touched: context.actions.getTouched()
        });
    }, [context]);

    React.useEffect(() => {
        const unsubscribe = context.actions.subscribe(
            (fieldState) => {
                setState({
                    ...fieldState,
                    name: context.name
                })
            },
            subscription as IFieldSubscription | undefined
        )

        return () => {
            unsubscribe();
        }
    }, [context.actions.subscribe, subscription])

    return state;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useFieldState;