/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';

import MaskedInput, { MaskedInputProps } from 'react-text-mask';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps } from '../../../form/components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type TextFieldsProps = Partial<Omit<MuiTextFieldProps, 'type'>> & IInputRenderProps<string, Dtos.IValidationError> & { maskedFormat: MaskedInputProps; }

export interface IMaskedProps extends TextFieldsProps {
}

interface IInternalMaskedFormatProps {
    inputRef: (instance: HTMLElement | null) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    text: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */
const InternalMaskedFormat: React.FunctionComponent<IInternalMaskedFormatProps> = ({
    inputRef,
    ...rest
}) => {

    return (
        <MaskedInput
            {...rest}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            
        />
    );
}


const Masked: React.FunctionComponent<IMaskedProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    fullWidth,
    onChange,
    InputProps,
    inputProps,
    maskedFormat,
    ...rest
}) => {


    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }

        onInputChange(event.target.value ? event.target.value : undefined as any);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
    }, [inputProps?.onBlur, onBlur]);

    return (
        <MuiTextField
            {...rest as any}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            InputProps={{
                ...InputProps,
                inputComponent: InputProps?.inputComponent === undefined ? InternalMaskedFormat : InputProps?.inputComponent
            }}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined,
                ...maskedFormat
            }}
            name={name}
            value={value}
            onChange={onChangeCombined}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Masked;
