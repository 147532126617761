/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an country by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { CountryReducer, countryActions, useCountrySelector, countrySelectors, ICountryStore } from '../../store/modules/data/country';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseCountryByCodeActions {
    load: BoundActionCreator<OmitFirstArg<typeof countryActions.loadByCode>>;
    clear: BoundActionCreator<OmitFirstArg<typeof countryActions.clearByCode>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useCountryByCode = <CountryType extends Dtos.ICountry>(code: string, autoLoad?: boolean): [
    CountryType | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseCountryByCodeActions
] => {
    const dispatch = useDispatch();

    const actions: IUseCountryByCodeActions = React.useMemo(() => {
        const load = () => countryActions.loadByCode(code);
        load.type = countryActions.loadByCode.type;

        const clear = () => countryActions.clearByCode(code);
        clear.type = countryActions.clearByCode.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [countryActions, code, dispatch]);

    const countrySelector = React.useCallback((state: ICountryStore) => {
        return countrySelectors.countryByCode(state, code);
    }, [countrySelectors.countryByCode, code]);

    const loadStateSelector = React.useCallback((state: ICountryStore) => {
        return countrySelectors.loadStateByCode(state, code);
    }, [countrySelectors.loadStateByCode, code]);

    const country = useCountrySelector(countrySelector);

    const loadState = useCountrySelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, code]);

    return [
        country as CountryType | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useCountryByCode;