/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used for typings
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to type form.
 */
import Form, { IFormProps } from '../Form';

import IDtoClass from '../../../utilities/IDtoClass';
import { Button, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import SubmitButton from '../SubmitButton';
import usePatientForm from '../../../hooks/form/usePatientForm';
import useAuthenticatedUser from '../../../hooks/useAuthenticatedUser';
import ProSteps from './ProSteps';
import useIsMobile from '../../../hooks/useIsMobile';
import Markdown from '../../utility/Markdown';
import { IFormAllowSubmit, IFormState, IFormActions, IFormSubmitValidationFailed } from '../../../form/Form';
import { FieldErrorOption } from '../../../contexts/form/FormOptionsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitCalculatedTypes<T> = Omit<T, 'onValidate' | 'allowSubmit' | 'initialValues' | 'lookups' | 'labels'>

/**
 * This interface defines the properties for the ProForm component.
 */
export interface IProFormProps<Type extends Dtos.IForm> extends OmitCalculatedTypes<IFormProps<Type>> {
    formType: IDtoClass<Type>;
    afterFormSave?: (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null, event?: Dtos.IEvent | null, form?: Type | null) => void;
    afterFormSaveAndReturn?: (institution?: Dtos.IInstitution | null, patient?: Dtos.IPatient | null, event?: Dtos.IEvent | null, form?: Type | null) => void;
    onCancel?: () => void;
    canAdministerProForm?: boolean;
    useSteps?: boolean;
    footer?: React.ReactNode;
    canEdit?: boolean;
    fieldErrors?: FieldErrorOption;
    allowSubmit?: (formState: IFormState<Type, Dtos.IValidationError>, formActions: IFormActions<Type, Dtos.IValidationError>, allowSubmit: IFormAllowSubmit<Type, Dtos.IValidationError> | null) => Promise<boolean>;
    onFormSubmitValidationFailure?: (formState: IFormState<Type, Dtos.IValidationError>, validationError: boolean, onFormSubmitValidationFailure: IFormSubmitValidationFailed<Type, Dtos.IValidationError> | null) => Promise<void>;
}



/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    padding: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */


/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ProForm = <Type extends Dtos.IForm>({
    formType,
    afterFormSave,
    afterFormSaveAndReturn,
    onCancel,
    children,
    canAdministerProForm,
    useSteps,
    footer,
    canEdit,
    allowSubmit,
    fieldErrors,
    onFormSubmitValidationFailure,
    ...formProps
}: IProFormProps<Type>) => {
    const classes = useStyles();
    
    const {
        form,
        patient,
        formDefinition,
        labels,
        lookups,
        handleSubmit,
        allowSubmit: allowSubmitBase,
        onFormCancel,
        onFormSubmit,
        onFormSubmitAndReturn,
        validate,
        formName,
        readOnly,
        onFormSubmitFailure,
        onFormSubmitValidationFailure: onFormSubmitValidationFailureBase
    } = usePatientForm({ formType, afterFormSave, afterFormSaveAndReturn, onCancel, readOnly: !canEdit, isPro: true });

    const showErrors = useSteps ?
        'hide' :
        !!form?.id ?
            'always' :
            'default';

    const combinedAllowSubmit = React.useCallback(async (formState: IFormState<Type, Dtos.IValidationError>, formActions: IFormActions<Type, Dtos.IValidationError>) => {
        if (allowSubmit) {
            return await allowSubmit(formState, formActions, allowSubmitBase);
        }

        return await allowSubmitBase(formState, formActions);
    }, [allowSubmit, allowSubmitBase])

    const combinedOnFormSubmitFailure = React.useCallback(async (formState: IFormState<Type, Dtos.IValidationError>, validationError: boolean) => {
        if (onFormSubmitValidationFailure) {
            return await onFormSubmitValidationFailure(formState, validationError, onFormSubmitValidationFailureBase);
        }

        return await onFormSubmitValidationFailureBase(formState, validationError);
    }, [onFormSubmitValidationFailure, onFormSubmitValidationFailureBase])

    return (
        <Form
            {...formProps}
            initialValues={form ?? {} as any}
            onValidate={validate}
            allowSubmit={combinedAllowSubmit}
            fieldErrors={fieldErrors ?? (showErrors)}
            onSubmit={handleSubmit}
            labels={labels}
            lookups={lookups}
            readOnly={readOnly}
            onSubmitFailed={onFormSubmitFailure}
            onSubmitValidationFailed={combinedOnFormSubmitFailure}
        >
            {
                useSteps ?
                    <ProSteps
                        formName={formName}
                        footer={footer}
                        onCancel={onFormCancel}
                        formDefinition={formDefinition}
                    >
                        {children}
                    </ProSteps> :
                    <>
                        <div>
                            <Typography
                                className={classes.padding}
                                variant="h1"
                            >
                                {formName}
                            </Typography>
                            {
                                !!formDefinition?.instructions && (
                                    <div
                                        className={classes.padding}
                                    >
                                        <Markdown>
                                            {formDefinition?.instructions}
                                        </Markdown>
                                    </div>
                                )
                            }
                        </div>
                        {children}
                        {
                            !readOnly && (
                                <div
                                    className={classes.buttonGroup}
                                >
                                    {
                                        !!patient && canAdministerProForm && (
                                            <SubmitButton
                                                variant="contained"
                                                color="primary"
                                                onClick={onFormSubmit}
                                            >
                                                Submit
                                            </SubmitButton>
                                        )
                                    }
                                    <SubmitButton
                                        variant="contained"
                                        color="primary"
                                        onClick={onFormSubmitAndReturn}
                                    >
                                        {
                                            !!patient && canAdministerProForm ?
                                                "Submit And Return" :
                                                "Submit"
                                        }
                                    </SubmitButton>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onFormCancel}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            )
                        }
                        {
                            readOnly && (
                                <div
                                    className={classes.buttonGroup}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onFormCancel}
                                    >
                                        Back
                                    </Button>
                                </div>
                            )
                        }
                        {footer}
                    </>
            }
        </Form>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ProForm;