/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { Slider as MuiSlider, SliderProps as MuiSliderProps  } from '@material-ui/core';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps } from '../../../form/components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export const TYPE_PASSWORD = 'password';
export const TYPE_TEXT = 'text';
export const TYPE_EMAIL = 'email';
export const TYPE_NUMBER = 'number';
export const TYPE_URL = 'url';
export const TYPE_TELEPHONE = 'tel';
export const TYPE_DATE = 'date';
export const TYPE_DATETIME_LOCAL = 'datetime-local';
export const TYPE_MONTH = 'month';
export const TYPE_TIME = 'time';
export const TYPE_WEEK = 'week';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type SliderFieldsProps = Partial<Omit<MuiSliderProps, 'onChange'>> & IInputRenderProps<number | number[], Dtos.IValidationError>

export interface ISliderProps extends SliderFieldsProps {
    onChange: (event: React.ChangeEvent<{}>, value?: number | number[] | null) => void;
    length?: number | string | null;
    before?: React.ReactNode;
    after?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: (props: any) => {
            if (props.orientation !== 'horizontal' || !props.length) {
                return undefined;
            }

            return props.length;
        },
        height: (props: any) => {
            if (props.orientation !== 'vertical' || !props.length) {
                return undefined;
            }

            return props.length;
        },
        paddingLeft: (props: any) => props.orientation !== 'vertical' ? undefined : 32,
        textAlign: 'center',

        '& .MuiSlider-thumb': {
            display: (props: any) => props.hasValue ? '' : 'none'
        }
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Slider: React.FunctionComponent<ISliderProps> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    length,
    onChange,
    before,
    after,
    ...rest
}) => {

    const hasValue = value !== undefined && value !== null;
    const classes = useStyles({ orientation: rest.orientation, length, hasValue });

    const onChangeCombined = React.useCallback((event: React.ChangeEvent<{}>, eventValue?: number | number[] | null) => {
        if (onChange) {
            onChange(event, eventValue);
        }

        onInputChange(eventValue !== null ? eventValue : undefined);
    }, [onChange, onInputChange]);

    const ThumbComponent = React.useCallback((props: any) => {
        const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLElement | HTMLInputElement>) => {
            if (props?.onFocus) {
                props.onFocus(event);
            }

            onFocus();
        }, [props?.onFocus, onFocus]);

        const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLElement | HTMLInputElement>) => {
            if (props?.onBlur) {
                props.onBlur(event);
            }

            onBlur();
        }, [props?.onBlur, onBlur]);


        return (
            <span {...props} onFocus={onFocusCombined} onBlur={onBlurCombined} />
        )
    }, [onBlur, onFocus])

    return (
        <div>
            {before}
            <div
                className={classes.wrapper}
            >
                <MuiSlider
                    {...rest as any}
                    name={name}
                    value={value ?? null}
                    onChange={onChangeCombined}
                    ThumbComponent={ThumbComponent}
                />
            </div>
            { after }
        </div>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Slider;
