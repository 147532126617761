/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import Breadcrumbs, { IBreadcrumb } from './Breadcrumbs';
import * as Dtos from '../../api/dtos';
import InstitutionContext from '../../contexts/data/InstitutionContext';
import { ALL_INSTITUTIONS } from '../../constants/institution';
import { useParams } from 'react-router-dom';
import OnlinePatientManagementContext from '../../contexts/OnlinePatientManagementContext';
import InstitutionsContext from '../../contexts/data/InstitutionsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the InstitutionBreadcrumbs component.
 */
export interface IInstitutionBreadcrumbsProps {
    institution?: Dtos.IInstitution | null;
    createInstitutionLink?: (institution?: Dtos.IInstitution | null) => string;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

export const DEFAULT_INSTITUTION_BREADCRUMB_CREATOR = (institution?: Dtos.IInstitution | null) => {
    if (!institution) {
        return '/registration';
    }

    return `/registration/${institution.code}`;
}

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const InstitutionBreadcrumbs: React.FunctionComponent<IInstitutionBreadcrumbsProps> = ({
    children,
    institution,
    createInstitutionLink
}) => {
    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);
    const contextInstitution = React.useContext(InstitutionContext);
    const contextInstitutions = React.useContext(InstitutionsContext);
    const params = useParams<Record<string, string>>();

    const breadcrumbs = React.useMemo(() => {
        let i = institution;

        if (i === undefined) {
            const institutionCode = params[onlinePatientManagement.routeParameters.institutionCode];
            const institutionId = params[onlinePatientManagement.routeParameters.institutionId];

            if (contextInstitution.institution && (contextInstitution.institution.code === institutionCode || contextInstitution.institution.id?.toString() === institutionId)) {
                i = contextInstitution.institution;
            }
            else if (contextInstitutions.institutions) {
                i = contextInstitutions.institutions.find(inst => (inst.code && inst.code === institutionCode) || inst.id?.toString() === institutionId);
            }
        }

        const b: IBreadcrumb[] = [];

        if (i) {
            b.push({
                icon: faBuilding,
                label: i.name,
                to: !!createInstitutionLink ? createInstitutionLink(i) : DEFAULT_INSTITUTION_BREADCRUMB_CREATOR(i)
            });
        }
        else {
            b.push({
                icon: faBuilding,
                label: ALL_INSTITUTIONS,
                to: !!createInstitutionLink ? createInstitutionLink(i) : DEFAULT_INSTITUTION_BREADCRUMB_CREATOR(i)
            });
        }

        return b;
    }, [contextInstitution, contextInstitutions, institution, createInstitutionLink, params, onlinePatientManagement])

    return (
        <Breadcrumbs
            breadcrumbs={breadcrumbs}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default InstitutionBreadcrumbs;