/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import FieldGroupContext from '../../../../contexts/form/FieldGroupContext';
import FieldProvider from '../../FieldProvider';
import FormLabel from '../../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../../FormErrorHandler';
import FormErrorDisplay from '../../FormErrorDisplay';
import FieldErrorFlag from '../../FieldErrorFlag';
import Input, { IInputProps } from '../../Input';
import { InputComponentType, OmitInputRender } from '../../../../form/components/Input';
import RatingTableContext from '../../../../contexts/field/RatingTableContext';
import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import FormLookupContext from '../../../../contexts/form/FormLookupContext';
import Radio from '../../input/Radio';
import Condition, { IConditionProps } from '../../Condition';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IStaticGridCellProps {
    columnName: string;
    fieldName?: string | null;
    content?: React.ReactNode;
}


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */
const StaticGridCell: React.FunctionComponent<IStaticGridCellProps> = ({
    columnName,
    content,
    fieldName
}) => {
    const tableCell = (
        <TableCell>
            {content}
        </TableCell>
    );

    if (fieldName) {
        return (
            <FieldProvider name={fieldName}>
                {tableCell}
            </FieldProvider>
        );
    }

    return (
        tableCell
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default StaticGridCell;
