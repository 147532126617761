/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the patientValidation reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualPatientState {
    validation: Dtos.IPatientValidationResult | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IPatientState {
    byId: Record<number, IIndividualPatientState>;
    byStudyNumber: Record<string, IIndividualPatientState>;
}

export interface IPatientStore {
    patientValidation: IPatientState;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all patientValidation reducer state changes and actions. 
 */
export class PatientValidationReducer extends ImmerReducer<IPatientState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientValidationState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, validation?: Dtos.IPatientValidationResult) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientValidationState };
        }

        this.draftState.byId[id].validation = validation ? validation : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualPatientValidationState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public loadByStudyNumber(studyNumber: string) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientValidationState };
        }

        this.draftState.byStudyNumber[studyNumber].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByStudyNumberSuccess(studyNumber: string, validation?: Dtos.IPatientValidationResult) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientValidationState };
        }

        this.draftState.byStudyNumber[studyNumber].validation = validation ? validation : null;

        this.draftState.byStudyNumber[studyNumber].loadState = {
            state: RequestState.Success
        };
    }

    public loadByStudyNumberFailure(studyNumber: string, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byStudyNumber[studyNumber]) {
            this.draftState.byStudyNumber[studyNumber] = { ...initialIndividualPatientValidationState };
        }

        this.draftState.byStudyNumber[studyNumber].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public clearByStudyNumber(studyNumber: string) {
        if (this.draftState.byStudyNumber[studyNumber]) {
            delete this.draftState.byStudyNumber[studyNumber];
        }
    }

    public clearAll() {
        this.draftState = { ...initialPatientValidationState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualPatientValidationState: IIndividualPatientState = {
    validation: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialPatientValidationState: IPatientState = {
    byStudyNumber: {},
    byId: {}
}

export const patientValidationActions = createActionCreators(PatientValidationReducer);
export const patientValidationReducer = createReducerFunction(PatientValidationReducer, initialPatientValidationState);

const createPatientApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.PatientGetValidationById({ id }));
    },
    loadByStudyNumber: (studyNumber: string) => {
        return client.get(new Dtos.PatientGetValidationByStudyNumber({ studyNumber }));
    }
});

export const createPatientLogic = (api: ReturnType<typeof createPatientApi>) => {
    const logics = {
        loadById: createLogic<IPatientStore, {}, undefined, string, ReturnType<typeof patientValidationActions.loadById>>({
            type: patientValidationActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(patientValidationActions.loadByIdSuccess(
                        action.payload,
                        response.validationResult
                    ));
                }
                catch (error) {
                    dispatch(patientValidationActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByStudyNumber: createLogic<any, {}, undefined, string, ReturnType<typeof patientValidationActions.loadByStudyNumber>>({
            type: patientValidationActions.loadByStudyNumber.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadByStudyNumber(action.payload);
                    dispatch(patientValidationActions.loadByStudyNumberSuccess(
                        action.payload,
                        response.validationResult
                    ));
                }
                catch (error) {
                    dispatch(patientValidationActions.loadByStudyNumberFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadByStudyNumber,
        logics.loadById
    ]
}

export const usePatientValidationSelector: TypedUseSelectorHook<IPatientStore> = useSelector;

export const patientValidationSelectors = {
    patientValidationById: (state: IPatientStore, id: number) => state.patientValidation.byId[id]?.validation ?? null,
    loadStateById: (state: IPatientStore, id: number) => state.patientValidation.byId[id]?.loadState ?? initialIndividualPatientValidationState.loadState,
    patientValidationByStudyNumber: (state: IPatientStore, studyNumber: string) => state.patientValidation.byStudyNumber[studyNumber]?.validation ?? null,
    loadStateByStudyNumber: (state: IPatientStore, studyNumber: string) => state.patientValidation.byStudyNumber[studyNumber]?.loadState ?? initialIndividualPatientValidationState.loadState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerPatientValidationReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPatientApi(client);

    const logic = createPatientLogic(api);

    reducerRegistry.register('patientValidation', patientValidationReducer as Reducer, logic as any);
};

export default registerPatientValidationReducer;