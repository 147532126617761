/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, fade } from '@material-ui/core/styles';

import { Radio as MuiRadio, RadioProps as MuiRadioProps, RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps, FormControlLabel } from '@material-ui/core';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import FormLookupContext from '../../../contexts/form/FormLookupContext';

import { IInputRenderProps } from '../../../form/components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type RadioGroupProps = Partial<MuiRadioGroupProps> & IInputRenderProps<number, Dtos.IValidationError>

export interface IRadioGroup extends Omit<RadioGroupProps, 'onChange'> {
    radioProps?: MuiRadioProps;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, eventValue: number | null | undefined) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    group: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const RadioGroup: React.FunctionComponent<IRadioGroup> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onInputBlur, focus: onInputFocus, ...restInputActions } },
    onChange,
    children,
    disabled,
    className,
    onBlur,
    onFocus,
    radioProps,
    ...rest
}) => {
    const classes = useStyles();

    const lookup = React.useContext(FormLookupContext);
    
    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, eventValue: string | null | undefined) => {
        let convertedValue = undefined;

        if (eventValue) {
            try {
                convertedValue = parseInt(eventValue);
            }
            catch {
                convertedValue = undefined;
            }
        }

        if (onChange) {
            onChange(event, convertedValue);
        }

        onInputChange(convertedValue);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
            onFocus(event);
        }

        onInputFocus();
    }, [onFocus, onInputFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
            onBlur(event);
        }

        onInputBlur();
    }, [onBlur, onInputBlur]);

    return (
        <MuiRadioGroup
            {...rest as any}
            name={name}
            value={value?.toString() ?? null}
            onChange={onChangeCombined}
            onFocus={onFocusCombined}
            onBlur={onBlurCombined}
            className={className ?? classes.group}
        >
            {
                children ?? lookup?.items?.map(item => {
                    return (
                        <FormControlLabel
                            disabled={disabled}
                            key={item.id}
                            value={item.id?.toString()}
                            control={<MuiRadio {...radioProps} color={radioProps?.color ? radioProps?.color : 'primary'} />}
                            label={item.value}
                        />
                    );
                })
            }
        </MuiRadioGroup>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default RadioGroup;
