/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the patient summary component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * React
 */
import * as React from 'react';

import { makeStyles, Theme, darken } from '@material-ui/core/styles';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Paper from '@material-ui/core/Paper';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import Typography from '@material-ui/core/Typography';


/*
 * ---------------------------------------------------------------------------------
 * interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPatientInformationCardProps {
    icon: IconDefinition;
    title?: string;
    text?: string;
    caption?: string;
    color: string;
}

interface IPatientInformationCardStyleProps {
    color: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme, IPatientInformationCardStyleProps>(theme => ({
    container: {
        background: props => props.color,
        color: theme.palette.common.white,
        display: 'flex',
        overflow: 'hidden'
    },
    icon: {
        fontSize: '4rem',
        padding: theme.spacing(1, 2),
        background: props => darken(props.color, 0.15),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    text: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    overline: {
        lineHeight: 'inherit'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */


const PatientInformationCard: React.FunctionComponent<IPatientInformationCardProps> = ({
    icon,
    title,
    text,
    caption,
    color
}) => {
    const classes = useStyles({ color })

    return (
        <Paper
            className={classes.container}
        >
            <div
                className={classes.icon}
            >
                <FontAwesomeIcon fixedWidth icon={icon} />
            </div>
            <div
                className={classes.text}
            >
                {
                    !!title && (
                        <Typography
                            className={classes.overline}
                            variant="overline"
                        >
                            {title}
                        </Typography>
                    )
                }
                {
                    !!text && (
                        <Typography>
                            {text}
                        </Typography>
                    )
                }
                {
                    !!caption && (
                        <Typography
                            variant="caption"
                        >
                            {caption}
                        </Typography>
                    )
                }
            </div>
        </Paper>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientInformationCard;
