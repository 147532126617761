/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Column } from 'material-table';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import LabelsContext, { IFormLabel } from '../../../contexts/form/LabelsContext';


import CollapsibleTable from '../../table/CollapsibleTable';
import Markdown from '../../utility/Markdown';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormValues component.
 */
export interface IFormLabelsProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const columns: Column<IFormLabel>[] = [
    {
        field: 'name',
        title: 'Property Path',
    },
    {
        field: 'label',
        title: 'Label'
    }
]

const renderDetailPanel = (rowData: IFormLabel) => {
    return (
        <div style={{ paddingLeft: 48 }}>
            <Markdown>
                {rowData?.detailedLabel}
            </Markdown>
        </div>
    );
};

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormLabels: React.FunctionComponent<IFormLabelsProps> = ({
    children
}) => {
    const labels = React.useContext(LabelsContext);

    return (
        <CollapsibleTable
            title="Labels"
            loading={false}
            data={labels ?? []}
            columns={columns}
            options={{
                paging: false
            }}
            detailPanel={renderDetailPanel}
            startCollapsed
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormLabels;