/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae investigator review form component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { makeStyles, Button, List, ListItem } from '@material-ui/core';

import { InstitutionContext, Form, SubmitButton, Field, TextArea, FieldGroup, FormGrid, IFormGridCell, Input, OnlinePatientManagementContext } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import SaeContext from '../context/SaeContext';

import useSaInvestigatorReviewForm from '../hooks/useSaeInvestigatorReviewForm';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeInvestigatorReviewFormProps {
}

export interface ISaeRejectionForm {
    reason?: string;
    followUpNumber?: number;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    rejectionLabel: {
        fontWeight: "bold",
        marginTop: theme.spacing(2)
    },
    rejectionReason: {
        marginLeft: theme.spacing(-3)
    },
    ml0: {
        marginLeft: theme.spacing(-1)
    },
    mt1: {
        marginTop: theme.spacing(3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const SaeInvestigatorReviewForm: React.FunctionComponent<ISaeInvestigatorReviewFormProps> = () => {
    const classes = useStyles();

    const { sae, loadState: saeLoadState, actions: saeActions } = React.useContext(SaeContext);

    const showForm = React.useMemo(() => {
        return sae?.status !== Dtos.SaeStatus.Approved && sae?.status !== Dtos.SaeStatus.Rejected
    }, [sae?.status]);

    const initialInvestigatorReviewForm: ISaeRejectionForm = React.useMemo(() => {
        return {
            reason: '',
            followUpNumber: sae?.form?.followUpNumber
        }
    }, [sae?.form?.followUpNumber]);

    const { trialName, organisationName, organisationLogo } = React.useContext(OnlinePatientManagementContext)

    const {
        handleSubmit,
        onFormCancel,
        onFormApprove,
        onFormReject,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate
    } = useSaInvestigatorReviewForm();

    return (
        <>
            {
                showForm &&
                <>
                    <Form
                        initialValues={initialInvestigatorReviewForm}
                        onValidate={validate}
                        validateOn="onChange"
                        allowSubmit={undefined}
                        fieldErrors={'default'}
                        onSubmit={handleSubmit}
                        labels={undefined}
                        lookups={undefined}
                        readOnly={false}
                        onSubmitFailed={onFormSubmitFailure}
                        onSubmitValidationFailed={onFormSubmitValidationFailure}
                    >
                        <FieldGroup>
                        <br />
                        <Typography
                                component={'span'}
                                className={classes.mt1}
                        >
                                An SAE has been updated for the {trialName} trial.

                            <List component="ul">
                                <ListItem>Approve: If submitted data is correct click 'approve'.</ListItem>
                                <ListItem>Reject: If data amendments are required or the event does not constitute an SAE enter the reason and click 'reject'. The site ISC will be requested to log in to OpenClinica to make the required changes or submit a follow up SAE.</ListItem>
                            </List>
                        </Typography>

                        <SubmitButton
                            variant="contained"
                            color="primary"
                            onClick={onFormApprove}
                        >
                                Approve SAE
                        </SubmitButton>

                        <Typography>
                            <br />
                            OR
                            <br />
                        </Typography>

                        <Typography
                            className={classes.rejectionLabel}
                        >
                            Reason for Rejection:
                        </Typography>

                        <Field
                            name="reason"
                            component={TextArea}
                            rows={10}
                            rowsMax={20}
                            sm={12}
                            md={12}
                            xl={12}
                            lg={12}
                            variant="outlined"
                            hideLabel
                            fullWidth   
                            className={classes.rejectionReason}
                        />
                        
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            onClick={onFormReject}
                            className={classes.ml0}
                        >
                                Reject SAE
                        </SubmitButton>
                        <br /><br />
                        </FieldGroup>
                    </Form>
                </>
            }
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeInvestigatorReviewForm;