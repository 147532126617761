/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae collapsible table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Link } from 'react-router-dom';

import { useParams, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { History as RouterHistory } from 'history';

import { Button, FormControl, InputLabel, Select, MenuItem, Grid, Tooltip, withStyles } from '@material-ui/core';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import {
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    ICollapsibleTableProps
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { useSaes } from '../hooks/useSaes';

import { IPatient } from '@ngt/opms';

import SaeReviewContext from '../context/SaeReviewContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;

interface ISaeCollapsibleTableProps<TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm, TPatient extends IPatient = IPatient, TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>> extends OmitData<ICollapsibleTableProps<TSae>> {
    data?: TSae[];
    columns: Array<Column<TSae>>;
    title?: string;
    loading?: boolean;
    status: Dtos.SaeStatus[];
    showInvestigatorColumn: boolean;
}

interface ISaeButtonProps {
    patientInstitutionId: number;
    patientStudyNumber: string;
    saeNumber: number;
    label: string;
    urlSuffix: string;
    tooltipTitle?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    buttonSet: {
        textAlign: 'right'
    },
    button: {
        marginLeft: theme.spacing(2),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3),
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform
];


const useSaeColumns = <
    TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm,
    TPatient extends IPatient = IPatient,
    TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>
>(
    columns: Array<Column<TSae>>,
    canViewSae: boolean,
    canReviewSae: boolean,
    canAdministerSae: boolean,
    canMedicallyReviewSae: boolean,
    investigatorColumn: boolean,
    classes: Record<string, string>
) => {


    const contextSaeReview = React.useContext(SaeReviewContext);

    const hasMedicalReview = contextSaeReview?.hasMedicalReview;

    const saeColumns = React.useMemo(() => {
        const actionColumns: Array<Column<TSae>> = [];

        actionColumns.push({
            field: 'id',
            title: 'View',
            render: sae => {
                return (
                    <>
                        {
                            <SaeButton
                                patientInstitutionId={sae.patient.institutionId as number}
                                patientStudyNumber={sae.patient.studyNumber}
                                saeNumber={sae.form.saeNumber as number}
                                label={sae.status === Dtos.SaeStatus.AwaitingForReview && canReviewSae ? 'Review' : 'View'}
                                urlSuffix={sae.status === Dtos.SaeStatus.AwaitingForReview && canReviewSae ? 'review' : ''}
                            />
                        }
                    </>
                );
            },
            width: 100,
            sorting: false
        });

        if (investigatorColumn && canAdministerSae) {
            actionColumns.push({
                field: 'id',
                title: 'Investigator',
                render: sae => {
                    return (
                        !!canAdministerSae && <SaeButton
                            patientInstitutionId={sae.patient.institutionId as number}
                            patientStudyNumber={sae.patient.studyNumber}
                            saeNumber={sae.form.saeNumber as number}
                            label="Notify"
                            urlSuffix="notify/investigators"
                        />
                    );
                },
                width: 50,
                sorting: false
            });
        }

        if (hasMedicalReview && canMedicallyReviewSae) {
            actionColumns.push({
                field: 'id',
                title: 'Medical Review',
                render: sae => {
                    const saeMedicalReviewStatus = sae.actionHistory
                        .find(a => a.actionType === Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers || a.actionType === Dtos.SaeWebActionTypeEnum.SubmittedMedicalReview);

                    const saeMedicalReviewStatusActionDate = sae.actionHistory
                        .find(a => a.actionType === Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers || a.actionType === Dtos.SaeWebActionTypeEnum.SubmittedMedicalReview)?.actionDate;

                    const saeMedicalReviewStatusDate = !!saeMedicalReviewStatusActionDate ? DateTime.fromISO(saeMedicalReviewStatusActionDate).toFormat('dd/MM/yyyy') : undefined;

                    const saeMedicalReviewTooltip = (saeMedicalReviewStatus?.actionType === Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers ? 'Pending' :
                        (saeMedicalReviewStatus?.actionType === Dtos.SaeWebActionTypeEnum.SubmittedMedicalReview ? 'Reviewed' : undefined)) as string +
                        (saeMedicalReviewStatusDate ? ` ${saeMedicalReviewStatusDate}` : undefined)

                    return (
                        <>
                            {
                                saeMedicalReviewStatus?.actionType === Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers && <SaeButton
                                    patientInstitutionId={sae.patient.institutionId as number}
                                    patientStudyNumber={sae.patient.studyNumber}
                                    saeNumber={sae.form.saeNumber as number}
                                    label="Review"
                                    urlSuffix="mreview"
                                />
                            }

                            {
                                !!canAdministerSae && <SaeButton
                                    patientInstitutionId={sae.patient.institutionId as number}
                                    patientStudyNumber={sae.patient.studyNumber}
                                    saeNumber={sae.form.saeNumber as number}
                                    label="Notify"
                                    urlSuffix="notify/mreviewer"
                                    tooltipTitle={saeMedicalReviewTooltip}
                                />
                            }

                            {
                                //saeMedicalReviewStatus?.actionType === Dtos.SaeWebActionTypeEnum.NotifiedMedicalReviewers &&
                                //<span className={classes.ml1}>Pending</span>
                            }

                            {
                                //saeMedicalReviewStatus?.actionType === Dtos.SaeWebActionTypeEnum.SubmittedMedicalReview &&
                                //<span className={classes.ml1}>Reviewed</span>
                            }
                        </>
                    );
                },
                sorting: false,
            });
        }

        return columns.concat(actionColumns);
    }, [columns, canViewSae, canReviewSae, canAdministerSae, canMedicallyReviewSae, investigatorColumn, classes, hasMedicalReview])

    
    return saeColumns;
};


const SaeButton: React.FunctionComponent<ISaeButtonProps> = ({
    patientInstitutionId,
    patientStudyNumber,
    saeNumber,
    label,
    urlSuffix,
    tooltipTitle
}) => {
    const classes = useStyles();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutionCode = contextInstitutions.institutions?.find(i => i.id === patientInstitutionId)?.code ?? ALL_INSTITUTIONS_CODE;

    let saeLink = `/sae/${institutionCode}/${patientStudyNumber}/${saeNumber}`

    if (urlSuffix !== '') {
        saeLink += `/${urlSuffix}`
    }

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    return <>
        {
            !!tooltipTitle && <LightTooltip title={tooltipTitle ?? ''} className={classes.tooltip}>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    className={classes.button}
                    to={saeLink}
                    size="small"
                >
                    {label}
                </Button>
            </LightTooltip>
        }

        {
            !tooltipTitle && <Button
                variant="contained"
                color="primary"
                component={Link}
                className={classes.button}
                to={saeLink}
                size="small"
            >
                {label}
            </Button>
        }
    </>
};

const SaeCollapsibleTable = <TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm, TPatient extends IPatient = IPatient, TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>> ({
    data,
    loading,
    title,
    status,
    columns,
    showInvestigatorColumn,
    ...tableProps
}: ISaeCollapsibleTableProps<TSaeForm, TPatient, TSae>) => {

    const classes = useStyles();

    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return contextInstitutions?.institutions?.find(i => i.code === institutionCode);
    }, [contextInstitutions?.institutions, institutionCode]);

    const [saes, saesLoadState, saesActions] = useSaes<TSaeForm, TPatient, TSae>(institution?.code, false);

    const saesToUse = React.useMemo(() => {
        if (!saes && !data) {
            return [];
        }

        return (data ?? saes ?? []).filter(sae => status.includes(sae.status));
    }, [saes, data, status]);

    const [[canViewSae, canReviewSae, canAdministerSae, canMedicallyReviewSae], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, false);

    const saesLoadingToUse = React.useMemo(() => {
        return data === undefined && loading === undefined ?
            saesLoadState.state === RequestState.None || saesLoadState.state === RequestState.Pending || permissionLoadState.state === RequestState.Pending :
            loading ?? false;
    }, [data, loading, saesLoadState, permissionLoadState]);

    const titleToUse = title ?? 'SAE';

    const columnsToUse = useSaeColumns(columns, canViewSae, canReviewSae, canAdministerSae, canMedicallyReviewSae, showInvestigatorColumn, classes);

    return (
        <>
            {
                <CollapsibleTable
                    title={titleToUse}
                    entityName="SAEs"
                    loading={saesLoadingToUse}
                    data={saesToUse}
                    columns={columnsToUse}
                    {...tableProps}
                />
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaeCollapsibleTable;
