/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { Column } from 'material-table';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import useFormState from '../../../form/hooks/useFormState';

import CollapsibleTable from '../../table/CollapsibleTable';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormExtra component.
 */
export interface IFormStateProps {

}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const columns: Column<any>[] = [
    {
        field: 'state',
        title: 'State',
    },
    {
        field: 'value',
        title: 'Value'
    }
]

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormState: React.FunctionComponent<IFormStateProps> = ({
    children
}) => {
    const { validating, submitting } = useFormState({ submitting: true, validating: true });

    const data = React.useMemo(() => [
        {
            state: 'Validating',
            value: validating
        },
        {
            state: 'Submitting',
            value: submitting
        }
    ], [submitting, validating]);


    return (
        <CollapsibleTable
            title="Form States"
            entityName="states"
            loading={false}
            data={data}
            columns={columns}
            options={{
                paging: false
            }}
            startCollapsed
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormState;