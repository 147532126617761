/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams, match } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load events into the EventByCodeContext
 */
import PatientSummaryByStudyNumberResolver from '../../resolver/utility/PatientSummaryByStudyNumberResolver';

/**
 * Used to get OPMS settings
 */
import OnlinePatientManagementContext from '../../../contexts/OnlinePatientManagementContext';
import { ALL_MASTER_GROUPS_CODE } from '../../../constants/masterGroup';
import { ALL_COLLABORATING_GROUPS_CODE } from '../../../constants/collaboratingGroup';
import { ALL_INSTITUTIONS_CODE } from '../../../constants/institution';
import { ALL_COUNTRIES_CODE } from '../../../constants/country';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsEventByCodeRoute component.
 */
export interface IPatientSummaryByStudyNumberRouteProps extends RouteProps {
    masterGroupCode?: string;
    collaboratingGroupCode?: string;
    countryCode?: string;
    institutionCode?: string;
    patientStudyNumber?: string;

    computedMatch?: match<Record<string, string>>;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the event management system.
 * @param param0 component properties.
 */
const PatientSummaryByStudyNumberRoute: React.FunctionComponent<IPatientSummaryByStudyNumberRouteProps> = ({
    masterGroupCode,
    collaboratingGroupCode,
    countryCode,
    institutionCode,
    patientStudyNumber,
    resolveBeforeLoad,
    children,
    ...routeProps
}) => {
    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext)
    const params = routeProps.computedMatch?.params ?? useParams<Record<string, string>>();
    const paramMasterGroupCode = params[onlinePatientManagement.routeParameters.masterGroupCode];
    const paramCollaboratingGroupCode = params[onlinePatientManagement.routeParameters.collaboratingGroupCode];
    const paramCountryCode = params[onlinePatientManagement.routeParameters.countryCode];
    const paramInstitutionCode = params[onlinePatientManagement.routeParameters.institutionCode];
    const paramPatientStudyNumber = params[onlinePatientManagement.routeParameters.patientStudyNumber];


    const masterGroupCodeToUse = paramMasterGroupCode ?? masterGroupCode ?? null;
    const collaboratingGroupCodeToUse = paramCollaboratingGroupCode ?? collaboratingGroupCode ?? null;
    const countryCodeToUse = paramCountryCode ?? countryCode ?? null;
    const institutionCodeToUse = paramInstitutionCode ?? institutionCode ?? null;

    return <Route {...routeProps}>
        <PatientSummaryByStudyNumberResolver
            masterGroupCode={masterGroupCodeToUse === ALL_MASTER_GROUPS_CODE ? null : masterGroupCodeToUse}
            collaboratingGroupCode={collaboratingGroupCodeToUse === ALL_COLLABORATING_GROUPS_CODE ? null : collaboratingGroupCodeToUse}
            countryCode={countryCodeToUse === ALL_COUNTRIES_CODE ? null : countryCodeToUse}
            institutionCode={institutionCodeToUse === ALL_INSTITUTIONS_CODE ? null : institutionCodeToUse}
            patientStudyNumber={paramPatientStudyNumber ?? patientStudyNumber}
            resolveBeforeLoad={resolveBeforeLoad}
        >
            {children}
        </PatientSummaryByStudyNumberResolver>
    </Route>
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientSummaryByStudyNumberRoute;