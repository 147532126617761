/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the collaboratingGroups reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualCollaboratingGroupsState {
    collaboratingGroups: Dtos.ICollaboratingGroup[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface ICollaboratingGroupsState {
    byIds: Record<string, IIndividualCollaboratingGroupsState>;
    byCodes: Record<string, IIndividualCollaboratingGroupsState>;
}

export interface ICollaboratingGroupsStore {
    collaboratingGroups: ICollaboratingGroupsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (masterGroupId?: number | null) => {
    return `${(masterGroupId ?? 'null')}`
}

const createCodesContext = (masterGroupCode?: string | null) => {
    return `${(masterGroupCode ?? 'null')}`
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all collaboratingGroups reducer state changes and actions. 
 */
export class CollaboratingGroupsReducer extends ImmerReducer<ICollaboratingGroupsState>
{
    public loadByIds(masterGroupId?: number | null) {
        const context = createIdsContext(masterGroupId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualCollaboratingGroupsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByIdsSuccess(masterGroupId?: number | null, collaboratingGroups?: Dtos.ICollaboratingGroup[]) {
        const context = createIdsContext(masterGroupId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualCollaboratingGroupsState };
        }

        this.draftState.byIds[context].collaboratingGroups = collaboratingGroups ? collaboratingGroups : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(masterGroupId?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(masterGroupId);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualCollaboratingGroupsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(masterGroupId?: number | null) {
        const context = createIdsContext(masterGroupId);

        this.draftState.byIds[context] = { ...initialIndividualCollaboratingGroupsState }
    }

    public loadByCodes(masterGroupCode?: string | null) {
        const context = createCodesContext(masterGroupCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualCollaboratingGroupsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByCodesSuccess(masterGroupCode?: string | null, collaboratingGroups?: Dtos.ICollaboratingGroup[]) {
        const context = createCodesContext(masterGroupCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualCollaboratingGroupsState };
        }

        this.draftState.byCodes[context].collaboratingGroups = collaboratingGroups ? collaboratingGroups : null;

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodesFailure(masterGroupCode?: string | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createCodesContext(masterGroupCode);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualCollaboratingGroupsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByCodes(masterGroupCode?: string | null) {
        const context = createCodesContext(masterGroupCode);

        this.draftState.byCodes[context] = { ...initialIndividualCollaboratingGroupsState }
    }

    public clearAll() {
        this.draftState = { ...initialCollaboratingGroupsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialCollaboratingGroupsState: ICollaboratingGroupsState = {
    byIds: {},
    byCodes: {}
}

export const initialIndividualCollaboratingGroupsState: IIndividualCollaboratingGroupsState = {
    collaboratingGroups: null,
    loadState: {
        state: RequestState.None
    }
}

export const collaboratingGroupsActions = createActionCreators(CollaboratingGroupsReducer);
export const collaboratingGroupsReducer = createReducerFunction(CollaboratingGroupsReducer, initialCollaboratingGroupsState);

const createCollaboratingGroupsApi = (client: JsonServiceClient) => ({
    loadByIds: (masterGroupId?: number) => {
        if (masterGroupId) {
            return client.get(new Dtos.CollaboratingGroupGetCollectionByMasterGroupId({ masterGroupId }));
        }
        else {
            return client.get(new Dtos.CollaboratingGroupGetCollection());
        }
    },
    loadByCodes: (masterGroupCode?: string) => {
        if (masterGroupCode) {
            return client.get(new Dtos.CollaboratingGroupGetCollectionByMasterGroupCode({ masterGroupCode }));
        }
        else {
            return client.get(new Dtos.CollaboratingGroupGetCollection());
        }
    }
});

const createCollaboratingGroupsLogic = (api: ReturnType<typeof createCollaboratingGroupsApi>) => {
    const logic = {
        loadByIds: createLogic<ICollaboratingGroupsStore, {}, undefined, string, ReturnType<typeof collaboratingGroupsActions.loadByIds>>({
            type: collaboratingGroupsActions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const masterGroupId = action.payload as unknown as number | null | undefined;

                try {
                    const response = await api.loadByIds(masterGroupId ?? undefined);

                    dispatch(collaboratingGroupsActions.loadByIdsSuccess(
                        masterGroupId,
                        response.collaboratingGroups
                    ));
                }
                catch (error) {
                    dispatch(collaboratingGroupsActions.loadByIdsFailure(masterGroupId, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCodes: createLogic<ICollaboratingGroupsStore, {}, undefined, string, ReturnType<typeof collaboratingGroupsActions.loadByCodes>>({
            type: collaboratingGroupsActions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const masterGroupCode = action.payload as unknown as string | null | undefined;

                try {
                    const response = await api.loadByCodes(masterGroupCode ?? undefined);

                    dispatch(collaboratingGroupsActions.loadByCodesSuccess(
                        masterGroupCode,
                        response.collaboratingGroups
                    ));
                }
                catch (error) {
                    dispatch(collaboratingGroupsActions.loadByCodesFailure(masterGroupCode, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByIds,
        logic.loadByCodes
    ]
};

export const useCollaboratingGroupsSelector: TypedUseSelectorHook<ICollaboratingGroupsStore> = useSelector;

export const collaboratingGroupsSelectors = {
    collaboratingGroupsByIds: (state: ICollaboratingGroupsStore, masterGroupId?: number | null) => {
        const context = createIdsContext(masterGroupId);

        if (!state.collaboratingGroups.byIds[context]) {
            return initialIndividualCollaboratingGroupsState.collaboratingGroups;
        }

        return state.collaboratingGroups.byIds[context].collaboratingGroups;
    },
    loadStateByIds: (state: ICollaboratingGroupsStore, masterGroupId?: number | null) => {
        const context = createIdsContext(masterGroupId);

        if (!state.collaboratingGroups.byIds[context]) {
            return initialIndividualCollaboratingGroupsState.loadState;
        }

        return state.collaboratingGroups.byIds[context].loadState;
    },
    collaboratingGroupsByCodes: (state: ICollaboratingGroupsStore, masterGroupCode?: string | null) => {
        const context = createCodesContext(masterGroupCode);

        if (!state.collaboratingGroups.byCodes[context]) {
            return initialIndividualCollaboratingGroupsState.collaboratingGroups;
        }

        return state.collaboratingGroups.byCodes[context].collaboratingGroups;
    },
    loadStateByCodes: (state: ICollaboratingGroupsStore, masterGroupCode?: string | null) => {
        const context = createCodesContext(masterGroupCode);

        if (!state.collaboratingGroups.byCodes[context]) {
            return initialIndividualCollaboratingGroupsState.loadState;
        }

        return state.collaboratingGroups.byCodes[context].loadState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerCollaboratingGroupsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createCollaboratingGroupsApi(client);

    const logic = createCollaboratingGroupsLogic(api);

    reducerRegistry.register('collaboratingGroups', collaboratingGroupsReducer as Reducer, logic as any);
};

export default registerCollaboratingGroupsReducer;