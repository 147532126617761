/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an patients by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { PatientsReducer, patientsActions, usePatientsSelector, patientsSelectors, IPatientsStore } from '../../store/modules/data/patients';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg, OmitFirstThreeArgs } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUsePatientsByCodeActions {
    load: BoundActionCreator<OmitFirstThreeArgs<typeof patientsActions.loadByCodes>>;
    clear: BoundActionCreator<OmitFirstThreeArgs<typeof patientsActions.clearByCodes>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const usePatientsByCodes = <PatientsType extends Dtos.IPatient>(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutionCode?: string | null, patientStateIds?: number[] | null, autoLoad?: boolean): [
    PatientsType[] | null,
    IRequestState<Dtos.ResponseStatus>,
    IUsePatientsByCodeActions
] => {
    const dispatch = useDispatch();

    const actions: IUsePatientsByCodeActions = React.useMemo(() => {
        const load = () => patientsActions.loadByCodes(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);
        load.type = patientsActions.loadByCodes.type;

        const clear = () => patientsActions.clearByCodes(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);
        clear.type = patientsActions.clearByCodes.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [patientsActions, masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds, dispatch]);

    const patientsSelector = React.useCallback((state: IPatientsStore) => {
        return patientsSelectors.patientsByCodes(state, masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);
    }, [patientsSelectors.patientsByCodes, masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds]);

    const loadStateSelector = React.useCallback((state: IPatientsStore) => {
        return patientsSelectors.loadStateByCodes(state, masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);
    }, [patientsSelectors.loadStateByCodes, masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds]);

    const patients = usePatientsSelector(patientsSelector);

    const loadState = usePatientsSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds]);

    return [
        patients as PatientsType[] | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default usePatientsByCodes;