/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the constant values related to master groups.
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------------------------
 */


export const ALL_COUNTRIES_CODE = 'all-countries';
export const ALL_COUNTRIES = 'All Countries';

