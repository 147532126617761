/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently loaded country.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used for typing request states.
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * Used to set default values for the context
 */
import { initialIndividualCountryState } from '../../store/modules/data/country';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ICountryContext {
    country: Dtos.ICountry | null;
    loadState: IRequestState<Dtos.ResponseStatus>,
    actions: ICountryActions;
}

interface ICountryActions {
    load: () => void;
    clear: () => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const CountryContext = React.createContext<ICountryContext>({
    country: initialIndividualCountryState.country,
    loadState: initialIndividualCountryState.loadState,
    actions: {
        load: () => undefined,
        clear: () => undefined
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CountryContext;