/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { MobileStepper, Button, useTheme, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery, Typography, makeStyles } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import useFormState from '../../../form/hooks/useFormState';
import * as Dtos from '../../../api/dtos';
import useFormActions from '../../../form/hooks/useFormActions';
import ValidationErrorSummary from '../../utility/ValidationErrorSummary';
import SubmitButton from '../SubmitButton';
import ProgressButton from '../../utility/ProgressButton';
import OnlinePatientManagementContext from '../../../contexts/OnlinePatientManagementContext';
import FormOptionsContext from '../../../contexts/form/FormOptionsContext';
import Markdown from '../../utility/Markdown';
import FieldGroup from '../FieldGroup';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the ProSteps component.
 */
export interface IProStepsProps {
    formName?: string;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    onCancel?: () => void;
    formDefinition?: Dtos.IFormDefinition | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    padding: {
        padding: theme.spacing(1, 3)
    },
    instructions: {
        padding: theme.spacing(3),

        [theme.breakpoints.up('md')]: {
            minHeight: 300
        }
    },
    container: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 56,
        minHeight: 'calc(100% - 56px)',
        zIndex: 1,
        background: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',

        '& > :nth-child(2)': {
            flex: '1 1 auto'
        },

        [theme.breakpoints.up('md')]: {
            position: 'static'
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ProSteps = ({
    formName,
    formDefinition,
    children,
    footer,
    onCancel
}: IProStepsProps) => {
    const classes = useStyles();

    const theme = useTheme();

    const formOptionsContext = React.useContext(FormOptionsContext);

    const steps = React.useMemo(() => {
        const childArray: React.ReactNode[] = [];

        if (formDefinition?.patientInstructions) {
            childArray.push(
                <FieldGroup>
                    <div
                        className={classes.instructions}
                    >
                        <Markdown>
                            {formDefinition?.patientInstructions}
                        </Markdown>
                     </div>
                </FieldGroup>
            );
        }

        if (!children) {
            return childArray;
        }

        if (children instanceof Array) {
            children.forEach(c => {
                if (!!c) {
                    childArray.push(c);
                }
            });
        }
        else {
            childArray.push(children);
        }

        return childArray;

    }, [children, formDefinition]);

    const { fields, errors, validating, submitting } = useFormState({ fields: true, errors: true, validating: true, submitting: true });
    const { setFieldTouched, submit } = useFormActions();

    const questionErrors = React.useMemo(() => {
        const qe: Dtos.IValidationError[] = [];

        fields.forEach(field => {
            if (errors[field] && errors[field].length > 0) {
                errors[field].forEach(e => qe.push(e));
            }
        });

        return qe;
    }, [fields, errors]);

    const hasErrors = React.useMemo(() => {
        return questionErrors.some(qe => qe.type !== Dtos.ValidationErrorType.Warning);
    }, [questionErrors])

    const [question, setQuestion] = React.useState(0);

    const [showModal, setShowModal] = React.useState(false);

    const [queueNext, setQueueNext] = React.useState(false);

    const [skipped, setSkipped] = React.useState<string[]>([]);

    const closeModal = React.useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const skipQuestion = React.useCallback(() => {
        setShowModal(false);

        fields.forEach(field => {
            if (!skipped.includes(field)) {
                skipped.push(field);
            }
        });

        if (question === steps.length - 1) {
            submit();
        }
        else {
            setQuestion(question + 1);
        }
    }, [setShowModal, fields, skipped, setSkipped, steps.length, submit]);

    const nextQuestion = React.useCallback(() => {
        if (!formOptionsContext.readOnly) {
            if (validating) {
                setQueueNext(true);
                return;
            }

            if (questionErrors.length > 0 && fields.some(field => !skipped.includes(field))) {
                fields.forEach(field => setFieldTouched(field, true, true, true));
                setShowModal(true);
                return;
            }

        }


        if (question === steps.length - 1) {
            if (formOptionsContext.readOnly) {
                !!onCancel && onCancel()
            }
            else {
                submit();
            }
        }
        else {
            setQuestion(question + 1);
        }
    }, [question, setQuestion, questionErrors, setShowModal, skipped, validating, setQueueNext, steps.length, submit, formOptionsContext.readOnly, onCancel]);

    const prevQuestion = React.useCallback(() => {
        setQuestion(question - 1);
    }, [question, setQuestion, fields]);

    React.useEffect(() => {
        if (!validating && queueNext) {
            setQueueNext(false);
            nextQuestion();
        }
    }, [validating, queueNext, setQueueNext, nextQuestion]);

    //React.useEffect(() => {
    //    if (showModal && (!questionErrors || questionErrors.length === 0)) {
    //        closeModal();
    //        nextQuestion();
    //    }
    //}, [showModal, questionErrors, closeModal, nextQuestion]);

    if (steps.length === 0) {
        return null;
    }

    return (
        <>
            <Dialog
                open={showModal}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ValidationErrorSummary
                    validationErrors={questionErrors}
                    detailedMessage
                />
                <DialogActions>
                    {
                        !hasErrors && (
                            <Button onClick={skipQuestion} color="primary">
                                Skip
                            </Button>
                        )
                    }
                    <Button onClick={closeModal} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <div
                className={classes.container}
            >
                <Typography
                    className={classes.padding}
                    variant="h1"
                >
                    {formName}
                </Typography>
                {
                    steps && steps.length > 1 ?
                        steps[question] :
                        steps
                }
                {footer}
                <MobileStepper
                    variant="progress"
                    LinearProgressProps={{
                        color: 'secondary'
                    }}
                    steps={steps.length}
                    position="static"
                    activeStep={question}
                    nextButton={
                        question === steps.length - 1 ?

                            <ProgressButton
                                onClick={nextQuestion}
                                disabled={submitting || queueNext}
                                variant="contained"
                                color="primary"
                                loading={queueNext || submitting}
                            >
                                {
                                    formOptionsContext.readOnly ?
                                        "Ok" :
                                        <>
                                            Submit
                                            <FontAwesomeIcon fixedWidth icon={faCheck} />
                                        </>
                                }
                            </ProgressButton> :
                            <ProgressButton
                                onClick={nextQuestion}
                                disabled={question === steps.length - 1 || queueNext}
                                variant="contained"
                                color="primary"
                                loading={queueNext}
                            >
                                Next
                            {theme.direction === 'rtl' ? <FontAwesomeIcon fixedWidth icon={faChevronLeft} /> : <FontAwesomeIcon fixedWidth icon={faChevronRight} />}
                            </ProgressButton>
                    }
                    backButton={
                        <ProgressButton
                            onClick={prevQuestion}
                            disabled={question === 0 || queueNext || submitting}
                            variant="contained"
                            color="primary"
                        >
                            {theme.direction === 'rtl' ? <FontAwesomeIcon fixedWidth icon={faChevronRight} /> : <FontAwesomeIcon fixedWidth icon={faChevronLeft} />}
                        Back
                    </ProgressButton>
                    }
                />
            </div>
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ProSteps;