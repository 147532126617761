/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import FieldGroupContext from '../../../../contexts/form/FieldGroupContext';
import FieldProvider from '../../FieldProvider';
import FormLabel from '../../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../../FormErrorHandler';
import FormErrorDisplay from '../../FormErrorDisplay';
import FieldErrorFlag from '../../FieldErrorFlag';
import Input, { IInputProps } from '../../Input';
import { InputComponentType, OmitInputRender } from '../../../../form/components/Input';
import RatingTableContext from '../../../../contexts/field/RatingTableContext';
import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import FormLookupContext from '../../../../contexts/form/FormLookupContext';
import Radio from '../../input/Radio';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IRatingRowProps<TValue = any, TError = any> {
    noErrors?: boolean;
    noErrorFlag?: boolean;
    label?: React.ReactNode;
    simpleLabel?: boolean;
    name: string;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    valueRow: {
        borderBottom: (props: any) => !props.noErrors ? 'none' : '',

        '& td': {
            borderBottom: (props: any) => !props.noErrors ? 'none' : ''
        }
    },
    errorRow: {
        padding: theme.spacing(0),

        '& td': {
            padding: theme.spacing(0)
        }
    },
    errorFlagCell: {
        width: 64
    }
}));

const useValueStyles = makeStyles(theme => ({
    cell: {
        width: (props: any) => props.columnWidth
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */
const RatingValues: React.FunctionComponent = () => {
    const lookup = React.useContext(FormLookupContext);

    const context = React.useContext(RatingTableContext);

    const classes = useValueStyles({ columnWidth: context?.columnWidth });

    React.useEffect(() => {
        if ((lookup?.items?.length ?? 0) <= (context?.columns?.length ?? 0)) {
            return;
        }

        if (context?.setColumns && lookup?.items) {
            context.setColumns(lookup.items.map(i => i.value));
        }

    }, [context?.columns, context?.setColumns, lookup?.items])

    const columns = React.useMemo(() => {
        const columnCount = context?.columns?.length ?? 0;

        if (columnCount === 0) {
            return [];
        }

        const lookupCount = lookup?.items?.length ?? 0;

        const count = columnCount > lookupCount ? columnCount : lookupCount;

        const c: (Dtos.ILookupItem | undefined)[] = [];

        lookup?.items?.forEach(item => {
            c.push(item);
        })

        while (c.length < count) {
            c.push(undefined);
        }

        return c

    }, [context?.columns?.length, lookup?.items?.length])

    return (
        <>
            {
                columns.map((column, i) => {
                    return (
                        <TableCell
                            key={i}
                            className={classes.cell}
                        >
                            {
                                !!column && (
                                    <Input
                                        component={Radio}
                                        value={column.id}
                                        noErrorFlag
                                        removeErrorStyling
                                    />
                                )
                            }
                        </TableCell>
                    );
                })
            }
        </>
    );
};


const RatingRow = <TValue extends any = any, TError = any>({
    name,
    label,
    simpleLabel,
    noErrors,
    noErrorFlag,
}: IRatingRowProps<TValue, TError>) => {

    //const isGrouped = React.useContext(FieldGroupContext);

    const classes = useStyles({ noErrors });

    const context = React.useContext(RatingTableContext);

    return (
        <>
            <FormErrorHandler
                forceNew
            >
                <FieldProvider name={name}>
                    <TableRow
                        className={classes.valueRow}
                    >
                        <TableCell>
                            {
                                label === undefined || isString(label) ?
                                    <FormLabel simpleLabel={simpleLabel} label={label} /> :
                                    label
                            }
                        </TableCell>
                        <RatingValues />
                        <TableCell
                            className={classes.errorFlagCell}
                        >
                            {
                                !noErrorFlag && (
                                    <FieldErrorFlag />
                                )
                            }
                        </TableCell>
                    </TableRow>
                </FieldProvider>
                {
                    !noErrors && (
                        <TableRow
                            className={classes.errorRow}
                        >
                            <TableCell colSpan={(context?.columns?.length ?? 0) + 2}>
                                <FormErrorDisplay />
                            </TableCell>
                        </TableRow>
                    )
                }
            </FormErrorHandler>
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default RatingRow;
