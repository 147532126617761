/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an institution by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { InstitutionReducer, institutionActions, useInstitutionSelector, institutionSelectors, IInstitutionStore } from '../../store/modules/data/institution';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseInstitutionByCodeActions {
    load: BoundActionCreator<OmitFirstArg<typeof institutionActions.loadByCode>>;
    clear: BoundActionCreator<OmitFirstArg<typeof institutionActions.clearByCode>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useInstitutionByCode = <InstitutionType extends Dtos.IInstitution>(code: string, autoLoad?: boolean): [
    InstitutionType | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseInstitutionByCodeActions
] => {
    const dispatch = useDispatch();

    const actions: IUseInstitutionByCodeActions = React.useMemo(() => {
        const load = () => institutionActions.loadByCode(code);
        load.type = institutionActions.loadByCode.type;

        const clear = () => institutionActions.clearByCode(code);
        clear.type = institutionActions.clearByCode.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [institutionActions, code, dispatch]);

    const institutionSelector = React.useCallback((state: IInstitutionStore) => {
        return institutionSelectors.institutionByCode(state, code);
    }, [institutionSelectors.institutionByCode, code]);

    const loadStateSelector = React.useCallback((state: IInstitutionStore) => {
        return institutionSelectors.loadStateByCode(state, code);
    }, [institutionSelectors.loadStateByCode, code]);

    const institution = useInstitutionSelector(institutionSelector);

    const loadState = useInstitutionSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, code]);

    return [
        institution as InstitutionType | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useInstitutionByCode;