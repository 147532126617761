/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type form metadata
 */
import * as Dtos from './api/dtos';

/*
 * Used to register the institution reducer;
 */
import registerPermissionsReducer from './store/modules/utility/permissions';
import { Store, Middleware } from 'redux';
import { IOnlinePatientManagementExtension, OnlinePatientManagement } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IBctApiExtensionReducerOptions {
    permissions?: boolean | null;
};

interface IBctApiExtensionStoreOptions {
    initialiseReducers?: boolean | null | IBctApiExtensionReducerOptions;
};

export interface IBctApiExtensionOptions {
    /**
     * The configuration options to use when creating the redux store.
     *
     * (Defaults used if not provided).
     */
    storeOptions?: IBctApiExtensionStoreOptions | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */


/**
 * This class handles the global context used by the OPMS.
 */
export class BctApiExtension implements IOnlinePatientManagementExtension {
    private reducerOptions: boolean | IBctApiExtensionReducerOptions;

    /**
     * Creates a new OnlinePatientManagement using the provided configuration options.
     * @param options Configuration Options
     */
    constructor(options: IBctApiExtensionOptions) {
        this.initialiseReducerOptions(options?.storeOptions?.initialiseReducers);
    }

    private initialiseReducerOptions(reducerOptions?: boolean | IBctApiExtensionReducerOptions | null) {
        this.reducerOptions = reducerOptions ?? true;
    }


    /**
     * This method initialises the Reducers in the Reducer Registry.
     * @param onlinePatientManagement Online Patient Management.
     */
    public initialiseReducers(onlinePatientManagement: OnlinePatientManagement) {
        // Check if reducer initialisation was requested to be skipped.
        if (this.reducerOptions === false) {
            return;
        }

        if (this.reducerOptions === true || this.reducerOptions?.permissions !== false) {
            // Register Authenticated User Reducer if requested.
            registerPermissionsReducer(onlinePatientManagement.serviceStackClient, onlinePatientManagement.reducerRegistry);
        }
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default OnlinePatientManagement;