/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, darken, lighten } from '@material-ui/core/styles';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import IDtoClass from '../../utilities/IDtoClass';

import * as Dtos from '../../api/dtos';

import InputBase, { IInputProps as IInputBaseProps, InputComponentType, OmitInputRender } from '../../form/components/Input';

import FieldErrorFlag, { ShowErrorFlagFunction } from './FieldErrorFlag';
import FormOptionsContext from '../../contexts/form/FormOptionsContext';
import { ShowErrorFunction } from './FormErrorDisplay';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


export interface IInputProps<
    TComponent extends InputComponentType = any,
    TValue = any,
    TError = any
> extends IInputBaseProps<TComponent, TValue, TError> {
    noErrorFlag?: boolean;
    removeErrorStyling?: boolean;
    showErrorFlag?: ShowErrorFlagFunction;
    showErrorFlagExt?: ShowErrorFunction;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => {
    return {
        container: {
            display: 'flex'
        },
        field: {
            minWidth: 0,
            maxWidth: (props: any) => props.removeErrorStyling ? '100%' : 'calc(100% - 32px)',
            flex: '1 1 auto'
        }
    }
});

/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Input = <
    TComponent extends InputComponentType = any,
    TValue = any,
    TError = any
>({
    disabled,
    noErrorFlag,
    removeErrorStyling,
    showErrorFlag,
    showErrorFlagExt,
    ...props
}: IInputProps<TComponent, TValue, TError> & Partial<OmitInputRender<React.ComponentProps<TComponent>>>) => {
    const formOptions = React.useContext(FormOptionsContext);
    const classes = useStyles({ removeErrorStyling: removeErrorStyling || formOptions.fieldErrors === 'hide' });

    const inputDisabled = disabled || formOptions.readOnly;

    return (
        <div
            className={classes.container}
        >
            <div
                className={classes.field}
            >
                <InputBase
                    disabled={inputDisabled}
                    {...props as any}
                />
            </div>
            {
                !noErrorFlag && (
                    <FieldErrorFlag showErrorFlag={showErrorFlag} showErrorFlagExt={showErrorFlagExt} />
                )
            }
        </div>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Input;
