/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component for the select icon.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

/**
 * Used to style the icon.
 */
import { makeStyles, Theme } from '@material-ui/core/styles';

/**
 * Used as basic foundation of the application.
 */
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type master groups
 */
import * as Dtos from '../../api/dtos';

/*
 * Used to consistently identify and name the all master groups option.
 */
import { ALL_MASTER_GROUPS_CODE, ALL_MASTER_GROUPS } from '../../constants/masterGroup';

/*
 * Used to consistently style the select drop down icon.
 */
import SelectIcon from './SelectIcon';
/*
 * Used to consistently style the select loading icon.
 */
import LoadingIcon from './LoadingIcon';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface ISelectorProps {
    inputId: string;
    label?: React.ReactNode;
    loading?: boolean | null;
    selection?: string | null;
    onSelectionChanged?: (value: string | null | undefined) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Selector: React.FunctionComponent<ISelectorProps> = ({
    loading,
    children,
    label,
    onSelectionChanged,
    selection,
    inputId
}) => {
    const classes = useStyles();

    const onChange = React.useCallback((event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        const code = event.target.value as string | null | undefined;

        if (onSelectionChanged) {
            onSelectionChanged(code);
        }
    }, [onSelectionChanged])

    const value = selection;

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id={`${inputId}-label`}>{label}</InputLabel>
            <Select
                labelId={`${inputId}-label`}
                id={inputId}
                value={selection}
                onChange={onChange}
                IconComponent={loading ? LoadingIcon : SelectIcon}
            >
                {children}
            </Select>
        </FormControl>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Selector;
