/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, fade } from '@material-ui/core/styles';

import { Radio as MuiRadio, RadioProps as MuiRadioProps, FormControlLabel } from '@material-ui/core';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps } from '../../../form/components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type RadioGroupProps = Partial<MuiRadioProps> & IInputRenderProps<number, Dtos.IValidationError>

export interface IRadio extends Omit<RadioGroupProps, 'onChange' | 'value'> {
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: number | null | undefined) => void;
    value: number;
    label: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    group: {
        
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Radio: React.FunctionComponent<IRadio> = ({
    inputRender: { state: { name, value: inputValue, ...restInputState }, actions: { update: onInputChange, blur: onInputBlur, focus: onInputFocus, ...restInputActions } },
    onChange,
    children,
    disabled,
    onBlur,
    onFocus,
    value,
    color,
    label,
    ...rest
}) => {
    const classes = useStyles();
    
    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

        if (onChange) {
            onChange(event, checked ? value : undefined);
        }

        onInputChange(checked ? value : undefined);
    }, [onChange, onInputChange, value]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLButtonElement>) => {
        if (onFocus) {
            onFocus(event);
        }

        onInputFocus();
    }, [onFocus, onInputFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLButtonElement>) => {
        if (onBlur) {
            onBlur(event);
        }

        onInputBlur();
    }, [onBlur, onInputBlur]);

    if (!label) {
        return (
            <MuiRadio
                color={color ? color : 'primary'}
                disabled={disabled}
                name={name}
                value={value?.toString() ?? null}
                checked={value === inputValue}
                onChange={onChangeCombined}
                onFocus={onFocusCombined}
                onBlur={onBlurCombined}
                {...rest as any}
            />
        );
    }


    return (
        <FormControlLabel
            color={color ? color : 'primary'}
            disabled={disabled}
            name={name}
            value={value?.toString() ?? null}
            checked={value === inputValue}
            control={<MuiRadio
                onChange={onChangeCombined}
                onFocus={onFocusCombined}
                onBlur={onBlurCombined}
                {...rest as any}
            />}
            label={label}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Radio;
