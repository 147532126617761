/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected event.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass event context to the rest of the app.
 */
import EventByCodeContext from '../../../contexts/data/EventContext';


/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';
import useEventDefinitionByCode from '../../../hooks/configuration/useEventDefinitionByCode';
import EventDefinitionContext from '../../../contexts/configuration/EventDefinitionContext';
import useFormDefinitionByCode from '../../../hooks/configuration/useFormDefinitionByCode';
import FormDefinitionContext from '../../../contexts/configuration/FormDefinitionContext';
import usePatientByStudyNumber from '../../../hooks/data/usePatientByStudyNumber';
import PatientContext from '../../../contexts/data/PatientContext';
import useInstitutionByCode from '../../../hooks/data/useInstitutionByCode';
import useInstitutionById from '../../../hooks/data/useInstitutionById';
import InstitutionContext from '../../../contexts/data/InstitutionContext';
import useCountryByCode from '../../../hooks/data/useCountryByCode';
import useCountryById from '../../../hooks/data/useCountryById';
import CountryContext from '../../../contexts/data/CountryContext';
import useCollaboratingGroupByCode from '../../../hooks/data/useCollaboratingGroupByCode';
import useCollaboratingGroupById from '../../../hooks/data/useCollaboratingGroupById';
import CollaboratingGroupContext from '../../../contexts/data/CollaboratingGroupContext';
import useMasterGroupByCode from '../../../hooks/data/useMasterGroupByCode';
import useMasterGroupById from '../../../hooks/data/useMasterGroupById';
import MasterGroupContext from '../../../contexts/data/MasterGroupContext';
import useEventByCodes from '../../../hooks/data/useEventByCodes';
import EventContext from '../../../contexts/data/EventContext';
import useFormByCodes from '../../../hooks/data/useFormByCodes';
import FormContext from '../../../contexts/data/FormContext';
import useLookupsByFormDefinitionId from '../../../hooks/utility/useLookupsByFormDefinitionId';
import LookupsContext from '../../../contexts/utility/LookupsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface ICrfFormMapping {
    components: React.ReactNode;
}

export interface ICrfFormByCodesResolverProps {
    masterGroupCode?: string | null;
    collaboratingGroupCode?: string | null;
    countryCode?: string | null;
    institutionCode?: string | null;
    patientStudyNumber?: string | null;
    eventDefinitionCode?: string | null;
    eventRepeat?: number | null;
    formDefinitionCode?: string | null;
    formRepeat?: number | null;
    createPatient?: boolean | null;
    formMapping?: Record<string, ICrfFormMapping>;
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const CrfFormByCodesResolver: React.FunctionComponent<ICrfFormByCodesResolverProps> = ({
    masterGroupCode,
    collaboratingGroupCode,
    countryCode,
    institutionCode,
    patientStudyNumber,
    eventDefinitionCode,
    eventRepeat,
    formDefinitionCode,
    formRepeat,
    createPatient,
    resolveBeforeLoad,
    formMapping,
    children
}) => {

    if (!createPatient && !patientStudyNumber) {
        throw new Error('Route Invalid: Patient creation not enabled and no study number was provided.');
    }

    if (!patientStudyNumber && !institutionCode) {
        throw new Error('Route Invalid: No institution code was provided (required for new patient creation).');
    }

    if (!eventDefinitionCode) {
        throw new Error('Route Invalid: No event definition code has been provided.');
    }

    if (!eventRepeat) {
        throw new Error('Route Invalid: No event repeat has been provided.')
    }

    if (!formDefinitionCode) {
        throw new Error('Route Invalid: No form definition code has been provided.');
    }

    if (!formRepeat) {
        throw new Error('Route Invalid: No form repeat has been provided.')
    }

    // Event Definition Loading.
    const [eventDefinition, eventDefinitionLoadState, eventDefinitionActions] = useEventDefinitionByCode(eventDefinitionCode, true);

    const eventDefinitionContext = React.useMemo(() => {
        return {
            eventDefinition,
            loadState: eventDefinitionLoadState,
            actions: eventDefinitionActions
        };
    }, [eventDefinition, eventDefinitionLoadState, eventDefinitionActions]);

    // Form Definition Loading.
    const [formDefinition, formDefinitionLoadState, formDefinitionActions] = useFormDefinitionByCode(formDefinitionCode, true);

    const formDefinitionContext = React.useMemo(() => {
        return {
            formDefinition,
            loadState: formDefinitionLoadState,
            actions: formDefinitionActions
        };
    }, [formDefinition, formDefinitionLoadState, formDefinitionActions]);

    let conditionalContexts: React.ReactNode = !!formMapping && !!formMapping[formDefinitionCode]?.components  ? formMapping[formDefinitionCode]?.components : children;


    // Patient Loading
    const loadPatient = !!patientStudyNumber;

    const [patient, patientLoadState, patientSaveState, patientActions] = usePatientByStudyNumber(patientStudyNumber as any, loadPatient);

    const patientContext = React.useMemo(() => {
        return {
            patient,
            loadState: patientLoadState,
            saveState: patientSaveState,
            actions: patientActions
        };
    }, [patient, patientLoadState, patientActions]);

    if (loadPatient) {
        conditionalContexts = (
            <PatientContext.Provider value={patientContext}>
                {conditionalContexts}
            </PatientContext.Provider>
        );
    }

    // Load institution
    const loadInstitutionByCode = !!institutionCode;
    const loadInstitutionById = !!patientContext.patient?.institutionId && !institutionCode;

    const [institutionByCode, institutionByCodeLoadState, institutionByCodeActions] = useInstitutionByCode(institutionCode as any, loadInstitutionByCode);
    const [institutionById, institutionByIdLoadState, institutionByIdActions] = useInstitutionById(patientContext.patient?.institutionId as any, loadInstitutionById);

    const institutionContext = React.useMemo(() => {
        return {
            institution: loadInstitutionByCode ?
                institutionByCode :
                institutionById,
            loadState: loadInstitutionByCode ?
                institutionByCodeLoadState :
                institutionByIdLoadState,
            actions: loadInstitutionByCode ?
                institutionByCodeActions :
                institutionByIdActions
        };
    }, [institutionByCode, institutionByCodeLoadState, institutionByCodeActions, institutionById, institutionByIdLoadState, institutionByIdActions, loadInstitutionByCode]);

    // load Country
    const loadCountryByCode = !!countryCode;
    const loadCountryById = !!institutionContext.institution?.countryId && !countryCode;

    const [countryByCode, countryByCodeLoadState, countryByCodeActions] = useCountryByCode(countryCode as any, loadCountryByCode);
    const [countryById, countryByIdLoadState, countryByIdActions] = useCountryById(institutionContext.institution?.countryId as any, loadCountryById);

    const countryContext = React.useMemo(() => {
        return {
            country: loadCountryByCode ?
                countryByCode :
                countryById,
            loadState: loadCountryByCode ?
                countryByCodeLoadState :
                countryByIdLoadState,
            actions: loadCountryByCode ?
                countryByCodeActions :
                countryByIdActions
        };
    }, [countryByCode, countryByCodeLoadState, countryByCodeActions, countryById, countryByIdLoadState, countryByIdActions, loadCountryByCode]);

    if (loadCountryById || loadCountryByCode) {
        conditionalContexts = (
            <CountryContext.Provider value={countryContext}>
                {conditionalContexts}
            </CountryContext.Provider>
        );
    }

    // load CollaboratingGroup
    const loadCollaboratingGroupByCode = !!collaboratingGroupCode;
    const loadCollaboratingGroupById = !!institutionContext.institution?.collaboratingGroupId && !collaboratingGroupCode;

    const [collaboratingGroupByCode, collaboratingGroupByCodeLoadState, collaboratingGroupByCodeActions] = useCollaboratingGroupByCode(collaboratingGroupCode as any, loadCollaboratingGroupByCode);
    const [collaboratingGroupById, collaboratingGroupByIdLoadState, collaboratingGroupByIdActions] = useCollaboratingGroupById(institutionContext.institution?.collaboratingGroupId as any, loadCollaboratingGroupById);

    const collaboratingGroupContext = React.useMemo(() => {
        return {
            collaboratingGroup: loadCollaboratingGroupByCode ?
                collaboratingGroupByCode :
                collaboratingGroupById,
            loadState: loadCollaboratingGroupByCode ?
                collaboratingGroupByCodeLoadState :
                collaboratingGroupByIdLoadState,
            actions: loadCollaboratingGroupByCode ?
                collaboratingGroupByCodeActions :
                collaboratingGroupByIdActions
        };
    }, [collaboratingGroupByCode, collaboratingGroupByCodeLoadState, collaboratingGroupByCodeActions, collaboratingGroupById, collaboratingGroupByIdLoadState, collaboratingGroupByIdActions, loadCollaboratingGroupByCode]);

    if (loadCollaboratingGroupById || loadCollaboratingGroupByCode) {
        conditionalContexts = (
            <CollaboratingGroupContext.Provider value={collaboratingGroupContext}>
                {conditionalContexts}
            </CollaboratingGroupContext.Provider>
        );
    }

    // load MasterGroup
    const loadMasterGroupByCode = !!masterGroupCode;
    const loadMasterGroupById = !!institutionContext.institution?.masterGroupId && !masterGroupCode;

    const [masterGroupByCode, masterGroupByCodeLoadState, masterGroupByCodeActions] = useMasterGroupByCode(masterGroupCode as any, loadMasterGroupByCode);
    const [masterGroupById, masterGroupByIdLoadState, masterGroupByIdActions] = useMasterGroupById(institutionContext.institution?.masterGroupId as any, loadMasterGroupById);

    const masterGroupContext = React.useMemo(() => {
        return {
            masterGroup: loadMasterGroupByCode ?
                masterGroupByCode :
                masterGroupById,
            loadState: loadMasterGroupByCode ?
                masterGroupByCodeLoadState :
                masterGroupByIdLoadState,
            actions: loadMasterGroupByCode ?
                masterGroupByCodeActions :
                masterGroupByIdActions
        };
    }, [masterGroupByCode, masterGroupByCodeLoadState, masterGroupByCodeActions, masterGroupById, masterGroupByIdLoadState, masterGroupByIdActions, loadMasterGroupByCode]);

    if (loadMasterGroupById || loadMasterGroupByCode) {
        conditionalContexts = (
            <MasterGroupContext.Provider value={masterGroupContext}>
                {conditionalContexts}
            </MasterGroupContext.Provider>
        );
    }

    // load event
    const createEvent = true;

    const loadEvent = !!institutionContext.institution?.code && (!loadPatient || !!patientContext.patient?.studyNumber);

    const [event, eventLoadState, eventSaveState, eventActions] = useEventByCodes(institutionContext.institution?.code, patientContext.patient?.studyNumber, eventDefinitionCode, eventRepeat, createPatient, createEvent, loadEvent);

    const eventContext = React.useMemo(() => {
        return {
            event,
            loadState: eventLoadState,
            saveState: eventSaveState,
            actions: eventActions
        };
    }, [event, eventLoadState, eventActions]);

    // load form
    const createForm = true;

    const loadForm = !!institutionContext.institution?.code && (!loadPatient || !!patientContext.patient?.studyNumber);

    const [form, formLoadState, formSaveState, formActions] = useFormByCodes(formDefinitionCode, institutionContext.institution?.code, patientContext.patient?.studyNumber, eventDefinitionCode, eventRepeat, formRepeat, createPatient, createEvent, createForm, loadForm);

    const formContext = React.useMemo(() => {
        return {
            form,
            loadState: formLoadState,
            saveState: formSaveState,
            actions: formActions
        };
    }, [form, formLoadState, formActions]);


    // get lookups for form.
    const loadLookups = !!formDefinition?.id;

    const [lookups, lookupsLoadState, lookupsActions] = useLookupsByFormDefinitionId(formDefinition?.id ?? 0, loadLookups);

    const lookupsContext = React.useMemo(() => {
        return {
            lookups,
            loadState: lookupsLoadState,
            actions: lookupsActions
        };
    }, [lookups, lookupsLoadState, lookupsActions]);


    const notLoading =
        eventDefinitionContext.loadState.state !== RequestState.None &&
        eventDefinitionContext.loadState.state !== RequestState.Pending &&
        formDefinitionContext.loadState.state !== RequestState.None &&
        formDefinitionContext.loadState.state !== RequestState.Pending &&
        (!loadPatient ||
            (patientContext.loadState.state !== RequestState.None &&
                patientContext.loadState.state !== RequestState.Pending)) &&
        institutionContext.loadState.state !== RequestState.None &&
        institutionContext.loadState.state !== RequestState.Pending &&
        ((!loadCountryById && !loadCountryByCode) ||
            (countryContext.loadState.state !== RequestState.None &&
            countryContext.loadState.state !== RequestState.Pending)) &&
        ((!loadCollaboratingGroupById && !loadCollaboratingGroupByCode) ||
            (collaboratingGroupContext.loadState.state !== RequestState.None &&
            collaboratingGroupContext.loadState.state !== RequestState.Pending)) &&
        ((!loadMasterGroupById && !loadMasterGroupByCode) ||
            (masterGroupContext.loadState.state !== RequestState.None &&
            masterGroupContext.loadState.state !== RequestState.Pending)) &&
        eventContext.loadState.state !== RequestState.None &&
        eventContext.loadState.state !== RequestState.Pending &&
        formContext.loadState.state !== RequestState.None &&
        formContext.loadState.state !== RequestState.Pending &&
        lookupsContext.loadState.state !== RequestState.None &&
        lookupsContext.loadState.state !== RequestState.Pending;

    return (
        <EventDefinitionContext.Provider value={eventDefinitionContext}>
            <FormDefinitionContext.Provider value={formDefinitionContext}>
                <InstitutionContext.Provider value={institutionContext}>
                    <EventContext.Provider value={eventContext}>
                        <FormContext.Provider value={formContext}>
                            <LookupsContext.Provider value={lookupsContext}>
                                {
                                    !resolveBeforeLoad || notLoading ?
                                        conditionalContexts :
                                        <RouteLoading />
                                }
                            </LookupsContext.Provider>
                        </FormContext.Provider>
                    </EventContext.Provider>
                </InstitutionContext.Provider>
            </FormDefinitionContext.Provider>
        </EventDefinitionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CrfFormByCodesResolver;