/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently loaded sae.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used for typing request states.
 */
import { IRequestState } from '@ngt/request-utilities';

import { ResponseStatus, IPatient } from '@ngt/opms'

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * Used to set default values for the context
 */
import { initialIndividualSaeState } from '../store/sae';

import { ISaeRejectionForm } from '../components/SaeInvestigatorReviewForm';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeContext {
    sae: Dtos.Sae<Dtos.ISaeForm, IPatient> | null;
    loadState: IRequestState<ResponseStatus>,
    actions: ISaeActions;
}

interface ISaeActions {
    load: () => void;
    clear: () => void;
    notify: (notificationForm: Dtos.SaeNotificationForm) => void;
    reject: (rejectForm: ISaeRejectionForm) => void;
    performAction: (webAction: Dtos.WebAction) => void
    asyncNotify: (notificationForm: Dtos.SaeNotificationForm) => Promise<Dtos.SaeNotificationForm | undefined>;
    asyncReject: (rejectForm: ISaeRejectionForm) => Promise<ISaeRejectionForm | undefined>;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const SaeContext = React.createContext<ISaeContext>({
    sae: initialIndividualSaeState.sae,
    loadState: initialIndividualSaeState.loadState,
    actions: {
        load: () => undefined,
        clear: () => undefined,
        notify: (form) => undefined,
        reject: (form) => undefined,
        performAction: (form) => undefined,
        asyncNotify: (form) => new Promise<Dtos.SaeNotificationForm | undefined>((resolve, reject) => {
            resolve(undefined)
        }),
        asyncReject: (form) => new Promise<ISaeRejectionForm | undefined>((resolve, reject) => {
            resolve(undefined)
        })
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeContext;