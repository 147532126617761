/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the screeninglogs reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';
import { ScreeningLog } from '../api/screeningLog';

/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualScreeningLogsState {
    screeningLogs: Array<ScreeningLog> | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IScreeningLogsState {
    byContext: Record<string, IIndividualScreeningLogsState>;
};

export interface IScreeningLogsStore {
    screeningLogs: IScreeningLogsState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualScreeningLogsState: IIndividualScreeningLogsState = {
    screeningLogs: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialScreeningLogsState: IScreeningLogsState = {
    byContext: {}
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class ScreeningLogsReducer extends ImmerReducer<IScreeningLogsState> {
    public load(institutionId?: number) {
        const context = `${institutionId}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogsState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(institutionId?: number, screeningLogs?: Array<ScreeningLog>) {
        const context = `${institutionId}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogsState };
        }

        this.draftState.byContext[context].screeningLogs = screeningLogs ? screeningLogs : null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(institutionId?: number, responseStatus?: ResponseStatus) {
        const context = `${institutionId}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualScreeningLogsState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(institutionId?: number) {
        const context = `${institutionId}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialScreeningLogsState };
    }
}

export const screeningLogsActions = createActionCreators(ScreeningLogsReducer);
export const screeningLogsReducer = createReducerFunction(ScreeningLogsReducer, initialScreeningLogsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createScreeningLogsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.ScreeningLogGetCollection());
    },
    loadByInstitutionId: (institutionId?: number) => {
        const request: Dtos.ScreeningLogGetCollectionByInstitutionId = new Dtos.ScreeningLogGetCollectionByInstitutionId({
            institutionId
        });

        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createScreeningLogsLogic = (api: ReturnType<typeof createScreeningLogsApi>) => {
    const logic = {
        load: createLogic<IScreeningLogsStore, {}, undefined, string, ReturnType<typeof screeningLogsActions.load>>({
            type: screeningLogsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const institutionId = action.payload as unknown as number;

                try {
                    let response: Dtos.ScreeningLogCollectionResponse | null = null;

                    if (institutionId) {
                        response = await api.loadByInstitutionId(institutionId);
                    } else {
                        response = await api.load();
                    }

                    dispatch(screeningLogsActions.loadSuccess(
                        institutionId,
                        response.screeningLogs
                    ));
                }
                catch (error) {
                    dispatch(screeningLogsActions.loadFailure(
                        institutionId,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ];
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useScreeningLogsSelector: TypedUseSelectorHook<IScreeningLogsStore> = useSelector;

export const screeningLogsSelectors = {
    screeningLogs: (state: IScreeningLogsStore, institutionId?: number) => {
        const context = `${institutionId}`;

        return state.screeningLogs?.byContext[context]?.screeningLogs ?? initialIndividualScreeningLogsState.screeningLogs;
    },
    loadState: (state: IScreeningLogsStore, institutionId?: number) => {
        const context = `${institutionId}`;

        return state.screeningLogs?.byContext[context]?.loadState ?? initialIndividualScreeningLogsState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerScreeningLogsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createScreeningLogsApi(client);

    const logic = createScreeningLogsLogic(api);

    reducerRegistry.register('screeningLogs', screeningLogsReducer as Reducer, logic as any);
};

export default registerScreeningLogsReducer;