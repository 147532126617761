/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { darken, lighten, makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import FieldGroupContext from '../../../../contexts/form/FieldGroupContext';
import FieldProvider from '../../FieldProvider';
import FormLabel from '../../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../../FormErrorHandler';
import FormErrorDisplay from '../../FormErrorDisplay';
import FieldErrorFlag from '../../FieldErrorFlag';
import Input, { IInputProps } from '../../Input';
import { InputComponentType, OmitInputRender } from '../../../../form/components/Input';
import RatingTableContext from '../../../../contexts/field/RatingTableContext';
import { Table, TableHead, TableCell, TableBody, TableRow, Button } from '@material-ui/core';
import FormLookupContext from '../../../../contexts/form/FormLookupContext';
import Radio from '../../input/Radio';
import FormGridCell, { IFormGridCellProps } from './FormGridCell';
import classNames from 'classnames';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IFormGridRowProps {
    cells: Array<IFormGridCellProps>;
    noErrors?: boolean;
    index: number;
    removeRow: (index: number) => void;
    disabled?: boolean;
    allowDelete?: (index: number) => boolean;
    onClick?: (index: number) => void;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    valueRow: {
        borderBottom: (props: any) => !props.noErrors ? 'none' : '',

        '& td': {
            borderBottom: (props: any) => !props.noErrors ? 'none' : ''
        }
    },
    errorRow: {
        padding: theme.spacing(0),

        '& td': {
            padding: theme.spacing(0)
        }
    },
    remove: {
        color: theme.palette.error.main
    },
    hover: {
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.95),
            cursor: 'pointer'
        }
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */


const FormGridRow: React.FunctionComponent<IFormGridRowProps> = ({
    cells,
    noErrors,
    removeRow,
    index,
    disabled,
    allowDelete,
    onClick
}) => {

    //const isGrouped = React.useContext(FieldGroupContext);

    const classes = useStyles({ noErrors });

    const remove = React.useCallback((event: React.MouseEvent<Element, MouseEvent>) => {
        event.stopPropagation();
        removeRow(index);
    }, [removeRow, index])

    return (
        <>
            <FormErrorHandler
                forceNew
            >
                <FieldProvider name={`[${index}]`}>
                    <TableRow
                        className={onClick ? classNames(classes.valueRow, classes.hover) : classes.valueRow}
                        onClick={onClick ? () => { onClick(index)} : undefined}
                    >
                            {
                                cells.map((cell, i) => {
                                    return (
                                        <FormGridCell
                                            key={i}
                                            {...cell}
                                        />
                                    );
                                })
                            }
                            {
                                !disabled && (
                                    <TableCell>
                                        {
                                            (!allowDelete || allowDelete(index)) && (
                                                <Button
                                                    variant="text"
                                                    color="default"
                                                    className={classes.remove}
                                                    onClick={remove}
                                                >
                                                    <FontAwesomeIcon fixedWidth icon={faTimes} />
                                                </Button>
                                            )
                                        }
                                    </TableCell>
                                )
                            }
                    </TableRow>
                </FieldProvider>
                {
                    !noErrors && (
                        <TableRow
                            className={classes.errorRow}
                        >
                            <TableCell colSpan={(cells?.length ?? 0) + 1}>
                                <FormErrorDisplay />
                            </TableCell>
                        </TableRow>
                    )
                }
            </FormErrorHandler>
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormGridRow;
