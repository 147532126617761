/* Options:
Date: 2021-01-27 14:21:19
Version: 5.92
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44359

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: NewtonGreen.ServiceStack.Opms.ScreeningLog/*,__ScreeningLog.ScreeningLog/*
//ExcludeTypes: 
DefaultImports: IGet:@ngt/opms,IPost:@ngt/opms,IDelete:@ngt/opms,IDataModel:@ngt/opms,IModel:@ngt/opms,IHasAudit:@ngt/opms,ScreeningLog:./screeningLog,IPatient:@ngt/opms,ResponseError:@ngt/opms,ResponseStatus:@ngt/opms,IReturn:@ngt/opms,IValidationError:@ngt/opms,IValidationResult:@ngt/opms,ValidationResultType:@ngt/opms,ValidationErrorType:@ngt/opms
*/

import { IGet } from "@ngt/opms";
import { IPost } from "@ngt/opms";
import { IDelete } from "@ngt/opms";
import { IDataModel } from "@ngt/opms";
import { IModel } from "@ngt/opms";
import { IHasAudit } from "@ngt/opms";
import { ScreeningLog } from "./screeningLog";
import { IPatient } from "@ngt/opms";
import { ResponseError } from "@ngt/opms";
import { ResponseStatus } from "@ngt/opms";
import { IReturn } from "@ngt/opms";
import { IValidationError } from "@ngt/opms";
import { IValidationResult } from "@ngt/opms";
import { ValidationResultType } from "@ngt/opms";
import { ValidationErrorType } from "@ngt/opms";

export interface IScreeningLogGetSingleById extends IGet
{
    id?: number;
}

export interface IScreeningLogGetCollection extends IGet
{
}

export interface IScreeningLogGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export interface IScreeningLogDelete extends IDelete
{
    screeningLogId?: number;
}

export class ScreeningLogFormValidationError implements IScreeningLogFormValidationError, IValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningLogFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResult implements IScreeningLogFormValidationResult, IValidationResult
{
    public result: ValidationResultType;
    public errors: ScreeningLogFormValidationError[];

    public constructor(init?: Partial<ScreeningLogFormValidationResult>) { (Object as any).assign(this, init); }
}

export interface IScreeningLog extends IDataModel, IModel, IHasAudit
{
    institutionId?: number;
    patientId?: number;
    initials: string;
    dateOfBirth?: string;
    screeningDate?: string;
}

export interface IScreeningLogFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningLogFormValidationResult extends IValidationResult
{
    errors: IScreeningLogFormValidationError[];
}

// @DataContract
export class ScreeningLogSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeninglog.
    */
    // @DataMember(Order=2)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeninglogs.
    */
    // @DataMember(Order=2)
    public screeningLogs: ScreeningLog[];

    public constructor(init?: Partial<ScreeningLogCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ScreeningLogDeleteResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningLogFormValidationResult;

    public constructor(init?: Partial<ScreeningLogFormValidationResponse>) { (Object as any).assign(this, init); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/single/id/{Id}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetSingleById implements IReturn<ScreeningLogSingleResponse>, IScreeningLogGetSingleById, IGet
{
    /**
    * The ID of the screeninglog to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningLogGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningLogSingleResponse(); }
    public getTypeName() { return 'ScreeningLogGetSingleById'; }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/collection", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollection implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollection, IGet
{

    public constructor(init?: Partial<ScreeningLogGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
    public getTypeName() { return 'ScreeningLogGetCollection'; }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollectionByInstitutionId implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollectionByInstitutionId, IGet
{
    /**
    * The ID of the Institution associated with the screeninglog to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningLogGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
    public getTypeName() { return 'ScreeningLogGetCollectionByInstitutionId'; }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/save", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostSave implements IReturn<ScreeningLogSingleResponse>, IPost
{
    /**
    * The screeninglog to save.
    */
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningLogSingleResponse(); }
    public getTypeName() { return 'ScreeningLogPostSave'; }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/delete/{ScreeningLogId}", "DELETE")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogDelete implements IReturn<ScreeningLogDeleteResponse>, IScreeningLogDelete, IDelete
{
    /**
    * The screeninglog ID to delete.
    */
    // @DataMember(Order=1)
    public screeningLogId?: number;

    public constructor(init?: Partial<ScreeningLogDelete>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningLogDeleteResponse(); }
    public getTypeName() { return 'ScreeningLogDelete'; }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/validate", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostValidate implements IReturn<ScreeningLogFormValidationResponse>, IPost
{
    /**
    * The screeninglog to validate.
    */
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ScreeningLogFormValidationResponse(); }
    public getTypeName() { return 'ScreeningLogPostValidate'; }
}

