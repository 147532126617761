/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ErrorContext from '../../contexts/form/ErrorContext';
import * as Dtos from '../../api/dtos';
import useFormState from '../../form/hooks/useFormState';
import ValidationErrorSummary from '../utility/ValidationErrorSummary';
import { Collapse } from '@material-ui/core';
import FormOptionsContext from '../../contexts/form/FormOptionsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type ShowErrorFunction = (name: string, error: Dtos.IValidationError, touched: Record<string, boolean>, errors: Record<string, Dtos.IValidationError[]>, validating: boolean, isAlwaysShown: boolean) => boolean;

/**
 * This interface defines the properties for the FormErrorDisplay component.
 */
export interface IFormErrorDisplayProps {
    showError?: ShowErrorFunction;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

export const DEFAULT_SHOW_ERROR_FUNCTION: ShowErrorFunction = (name, error, touched, errors, validating, isAlwaysShown) => {
    return touched[name] || isAlwaysShown;
}

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormErrorDisplay: React.FunctionComponent<IFormErrorDisplayProps> = ({
    children,
    showError
}) => {
    const errorContext = React.useContext(ErrorContext);
    const { errors, touched, validating } = useFormState({ errors: true, touched: true, validating: true });

    const formsOptionsContext = React.useContext(FormOptionsContext);

    const forceShow = formsOptionsContext.fieldErrors === 'always';

    const scopedErrors = React.useMemo(() => {
        if (!errorContext?.names || !errors) {
            return null;
        }

        const isAlwaysShown = formsOptionsContext.fieldErrors === 'always';

        return errorContext.names.reduce((a: Dtos.IValidationError[], b: string) => {
            if (errors[b] && errors[b].length > 0) {
                if (showError) {
                    return [...a, ...errors[b].filter(error => showError(b, error, touched, errors, validating, isAlwaysShown))]
                }
                else {
                    return [...a, ...errors[b].filter(error => DEFAULT_SHOW_ERROR_FUNCTION(b, error, touched, errors, validating, isAlwaysShown))]
                }
            }

            return a;
        }, [])
    }, [errorContext?.names, errors, forceShow, touched, formsOptionsContext.fieldErrors, showError]);

    const [hasExistingErrors, setHasExistingErrors] = React.useState(false);

    React.useEffect(() => {
        if (forceShow) {
            setHasExistingErrors(true);
            return;
        }

        if (!errorContext?.names || !touched) {
            setHasExistingErrors(false);
            return;
        }

        if (validating) {
            return;
        }

        setHasExistingErrors((scopedErrors?.length ?? 0) > 0);
    }, [errorContext?.names, scopedErrors, touched, setHasExistingErrors, validating, forceShow]);

    if (!hasExistingErrors || formsOptionsContext.fieldErrors === 'hide') {
        return null;
    }

    return (
        <Collapse in={errorContext?.expanded} enter>
            <ValidationErrorSummary
                validationErrors={scopedErrors}
                detailedMessage
            />
        </Collapse>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormErrorDisplay;