import * as React from 'react';
import * as ReactDom from 'react-dom';
import { JsonServiceClient } from '@servicestack/client';
import { WindowManager, ICtcaeState } from '@ngt/adverse-event-coding';
import { WindowView } from '@ngt/adverse-event-coding/dist/WindowManager';
import { v4 as uuidV4 } from 'uuid';
import * as Dtos from './api/Dtos';

export interface ICtcaeSelectorRenderProps {
    state: ICtcaeState | null;
    targetTermId: number | null;
    loading: boolean;
    error: boolean;
    disabled: boolean;
    isOpen: () => boolean;
    open: () => void;
    close: () => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    setCtcaeState: (state: ICtcaeState | null) => void;
}

export interface ICtcaeSelectorProps {
    ctcaeUrl: string;
    ctcaeVersion: string;
    targetTermId?: number | null;
    termId?: number | null;
    gradeId?: number | null;
    specification?: string | null;
    maxGrade?: number | null;
    minGrade?: number | null;
    allowUnknownGrade?: boolean | null;
    disabled?: boolean | null;
    render: (props: ICtcaeSelectorRenderProps) => React.ReactElement;
    onChange?: (state: ICtcaeState | null) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
}

const CtcaeSelector: React.FunctionComponent<ICtcaeSelectorProps> = ({
    ctcaeUrl,
    ctcaeVersion,
    targetTermId,
    termId,
    gradeId,
    specification,
    maxGrade,
    minGrade,
    allowUnknownGrade,
    disabled,
    render,
    onChange,
    onBlur,
    onFocus
}) => {
    const [loading, setLoading] = React.useState(false);

    const [error, setError] = React.useState(false);

    const [ctcaeState, setCtcaeState] = React.useState<ICtcaeState | null>(null);

    const [ctcaeManager, setCtcaeManager] = React.useState<WindowManager | null>(null);

    const setCtcaeStateAndChange = React.useCallback((state: ICtcaeState | null) => {
        ReactDom.unstable_batchedUpdates(() => {
            if (onChange) {
                if (targetTermId && !state?.selection?.grade) {
                    onChange(null);
                }
                else {
                    onChange(state);
                }
            }

            setCtcaeState(state);
        });
    }, [onChange, setCtcaeState, targetTermId]);

    const serviceClient = React.useMemo(() => {
        const client = new JsonServiceClient(ctcaeUrl);

        client.credentials = "omit";

        return client;
    }, [ctcaeUrl]);

    React.useEffect(() => {
        const manager = new WindowManager(
            ctcaeUrl,
            ctcaeVersion,
            {
                instanceId: uuidV4(),
                onSelect: (state) => {
                    setCtcaeStateAndChange(state);
                }
            }
        );

        setCtcaeManager(manager);

        return () => {
            manager.dispose();
        }
    }, [ctcaeUrl, ctcaeVersion, setCtcaeStateAndChange]);

    const loadGrade = React.useCallback((id: number) => {
        setLoading(true);
        setError(false);

        const request = new Dtos.GetGradeById();

        request.id = id;

        serviceClient
            .get(request)
            .then(response => {
                setCtcaeStateAndChange({
                    view: WindowView.Browse,
                    selection: {
                        version: response.version,
                        category: response.category,
                        term: response.parentTerm ? response.parentTerm : response.term,
                        subterm: response.parentTerm ? response.term : null,
                        grade: response.grade,
                        specification: specification ?? null
                    }
                });

                setLoading(false);
            })
            .catch(error => {
                setCtcaeStateAndChange(null);

                setLoading(false);
                setError(true);
            })
    }, [setLoading, specification, setError, setCtcaeStateAndChange, serviceClient])

    const loadTerm = React.useCallback((id: number) => {
        setLoading(true);
        setError(false);

        const request = new Dtos.GetTermById();

        request.id = id;

        serviceClient
            .get(request)
            .then(response => {
                setCtcaeStateAndChange({
                    view: WindowView.Browse,
                    selection: {
                        version: response.version,
                        category: response.category,
                        term: response.parentTerm ? response.parentTerm : response.term,
                        subterm: response.parentTerm ? response.term : null,
                        grade: null,
                        specification: specification ?? null
                    }
                });

                setLoading(false);
            })
            .catch(error => {
                setCtcaeStateAndChange(null);

                setLoading(false);
                setError(true);
            })
    }, [setLoading, specification, setError, setCtcaeStateAndChange, serviceClient])

    React.useEffect(() => {
        if (!loading) {
            if (gradeId && termId) {
                if (!ctcaeState || !ctcaeState.selection || !ctcaeState.selection.grade || !ctcaeState.selection.grade.id || ctcaeState.selection.grade.id !== gradeId) {
                    loadGrade(gradeId);
                }
            }
            else if (termId || targetTermId) {
                const id = termId ?? targetTermId as number;

                if (!ctcaeState || !ctcaeState.selection || !ctcaeState.selection.term || !ctcaeState.selection.term.id || ctcaeState.selection.term.id !== id) {
                    loadTerm(id);
                }
                else if (ctcaeState.selection.grade) {
                    setCtcaeStateAndChange({
                        view: ctcaeState.view,
                        selection: {
                            version: ctcaeState.selection.version,
                            category: ctcaeState.selection.category,
                            term: ctcaeState.selection.term,
                            subterm: ctcaeState.selection.subterm,
                            grade: null,
                            specification: ctcaeState.selection.specification
                        }
                    })
                }
            }
            else {
                setCtcaeStateAndChange(null);
            }
        }
    }, [termId, gradeId, targetTermId, loading, ctcaeState, loadGrade, loadTerm, setCtcaeStateAndChange, ctcaeState]);

    const open = React.useCallback(() => {
        if (!disabled && !loading && !error) {
            const stateSpecification = ctcaeState && ctcaeState.selection.specification ? ctcaeState.selection.specification : undefined;
            const stateTermId = ctcaeState && ctcaeState.selection.subterm && ctcaeState.selection.subterm.specifyOther ?
                ctcaeState.selection.subterm.id :
                ctcaeState && ctcaeState.selection.term && ctcaeState.selection.term.specifyOther ?
                    ctcaeState.selection.term.id :
                    undefined;
            const limitToTermId = targetTermId ? targetTermId : undefined;


            if (ctcaeState && ctcaeState.selection && ctcaeState.selection.subterm && ctcaeState.selection.subterm.id) {
                ctcaeManager?.openToTerm(ctcaeState.selection.subterm.id, limitToTermId, stateSpecification, stateTermId, allowUnknownGrade ?? false, minGrade ?? undefined, maxGrade ?? undefined);
            }
            else if (ctcaeState && ctcaeState.selection && ctcaeState.selection.term && ctcaeState.selection.term.id) {
                ctcaeManager?.openToTerm(ctcaeState.selection.term.id, limitToTermId, stateSpecification, stateTermId, allowUnknownGrade ?? false, minGrade ?? undefined, maxGrade ?? undefined);
            }
            else if (targetTermId) {
                ctcaeManager?.openToTerm(targetTermId, limitToTermId, stateSpecification, stateTermId, allowUnknownGrade ?? false, minGrade ?? undefined, maxGrade ?? undefined);
            }
            else {
                ctcaeManager?.open(stateSpecification, stateTermId, allowUnknownGrade ?? false, minGrade ?? undefined, maxGrade ?? undefined);
            }
        }
    }, [disabled, loading, error, ctcaeState, ctcaeManager, minGrade, maxGrade, targetTermId, allowUnknownGrade]);


    const close = React.useCallback(() => {
        ctcaeManager?.close();
    }, [ctcaeManager]);

    const isOpen = React.useCallback(() => {
        return ctcaeManager?.isOpen() ?? false;
    }, [ctcaeManager])

    const Component = render;

    return (
        <Component
            disabled={disabled ?? false}
            state={ctcaeState}
            error={error}
            loading={loading}
            isOpen={isOpen}
            open={open}
            close={close}
            setCtcaeState={setCtcaeStateAndChange}
            targetTermId={targetTermId ?? null}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
};

export default CtcaeSelector;