/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme, fade } from '@material-ui/core/styles';

import MuiToggleButtonGroup, { ToggleButtonGroupProps as MuiToggleButtonGroupProps } from '@material-ui/lab/ToggleButtonGroup';

import MuiToggleButton, { ToggleButtonProps as MuiToggleButtonProps } from '@material-ui/lab/ToggleButton';

import MenuItem from '@material-ui/core/MenuItem';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import FormLookupContext from '../../../contexts/form/FormLookupContext';

import { IInputRenderProps } from '../../../form/components/Input';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */


type ToggleButtonGroupProps = Partial<MuiToggleButtonGroupProps> & IInputRenderProps<number, Dtos.IValidationError>

export interface IToggleButtonGroup extends ToggleButtonGroupProps {
    toggleButtonProps?: MuiToggleButtonProps;
    disabled?: boolean;
    spacing?: number;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    group: {
        width: '100%'
    },
    button: {
        width: '100%',
        height: (props: any) => props.orientation === 'vertical' ? '' : 32
    }
}));

const useButtonStyles = makeStyles(theme => ({
    root: {
        color: `${theme.palette.common.black} !important`,

        marginRight: (props: any) => props.orientation !== 'vertical' && props.spacing ? theme.spacing(props.spacing) : '',
        marginBottom: (props: any) => props.orientation === 'vertical' && props.spacing ? theme.spacing(props.spacing) : '',

        borderLeftColor: (props: any) => props.orientation === 'vertical' || props.spacing ? `${fade(theme.palette.common.black, 0.12)} !important` : '',
        borderTopColor: (props: any) => props.orientation !== 'vertical' || props.spacing ? `${fade(theme.palette.common.black, 0.12)} !important` : '',

        borderRadius: (props: any) => props.spacing ? `${theme.shape.borderRadius}px !important` : '',

        '&:last-child': {
            marginRight: 0,
            marginBottom: 0
        }
    },
    selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} !important`
    },
    disabled: {
        '&$selected': {
            backgroundColor: `${fade(theme.palette.primary.main, 0.3)} !important`,
            color: `${fade(theme.palette.common.black, 0.74)} !important`
        },
        '&$disabled': {
            color: `${fade(theme.palette.common.black, 0.5)} !important`
        }
    },
    label: {
        textTransform: 'none'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const ToggleButtonGroup: React.FunctionComponent<IToggleButtonGroup> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onInputBlur, focus: onInputFocus, ...restInputActions } },
    onChange,
    children,
    exclusive,
    size,
    toggleButtonProps,
    disabled,
    className,
    onBlur,
    onFocus,
    spacing,
    ...rest
}) => {
    const classes = useStyles({ orientation: rest.orientation });

    const buttonClasses = useButtonStyles({ spacing, orientation: rest.orientation });

    const lookup = React.useContext(FormLookupContext);
    
    const onChangeCombined = React.useCallback((event: React.MouseEvent<HTMLElement>, eventValue: any) => {
        if (onChange) {
            onChange(event, eventValue);
        }

        onInputChange(eventValue);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLDivElement>) => {
        if (onFocus) {
            onFocus(event);
        }

        onInputFocus();
    }, [onFocus, onInputFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLDivElement>) => {
        if (onBlur) {
            onBlur(event);
        }

        onInputBlur();
    }, [onBlur, onInputBlur]);

    return (
        <MuiToggleButtonGroup
            {...rest as any}
            name={name}
            exclusive={exclusive === undefined ? true : exclusive}
            size={size === undefined ? 'small' : size}
            value={value}
            onChange={onChangeCombined}
            onFocus={onFocusCombined}
            onBlur={onBlurCombined}
            className={className ?? classes.group}
        >
            {
                children ?? lookup?.items?.map(item => {
                    return (
                        <MuiToggleButton
                            {...toggleButtonProps}
                            disabled={disabled === true ? true : toggleButtonProps?.disabled}
                            key={item.id}
                            value={item.id}
                            className={toggleButtonProps?.className ?? classes.button}
                            classes={buttonClasses}
                        >
                            {item.value}
                        </MuiToggleButton>
                    );
                })
            }
        </MuiToggleButtonGroup>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ToggleButtonGroup;
