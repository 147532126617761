/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ErrorContext from '../../contexts/form/ErrorContext';
import Observable from '../../utilities/Observable';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the FormErrorHandler component.
 */
export interface IFormErrorHandlerProps {
    forceNew?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const FormErrorHandler: React.FunctionComponent<IFormErrorHandlerProps> = ({
    forceNew,
    children
}) => {
    const parentContext = React.useContext(ErrorContext);

    const observableNames = React.useMemo(() => {
        return new Observable<string[]>([]);
    }, []);

    const [expanded, setExpanded] = React.useState<boolean>(true);

    const addName = React.useCallback((name: string) => {
        const currentNames = observableNames.get();

        if (currentNames.includes(name)) {
            return;
        }

        observableNames.set([...currentNames, name])
    }, [observableNames.set, observableNames.get]);

    const removeName = React.useCallback((name: string) => {
        const currentNames = observableNames.get();

        if (!currentNames.includes(name)) {
            return;
        }

        observableNames.set(currentNames.filter(n => n !== name));
    }, [observableNames.set, observableNames.get]);

    const [names, setNames] = React.useState(observableNames.get());

    React.useEffect(() => {
        setNames(observableNames.get());

        const unsubscribe = observableNames.subscribe((newNames) => {
            setNames(newNames);
        });

        return () => {
            unsubscribe();
        }
    }, [observableNames?.subscribe, observableNames?.get, setNames])

    const context = React.useMemo(() => {
        if (parentContext && !forceNew) {
            return undefined;
        }

        return {
            names,
            expanded,
            setExpanded,
            addName,
            removeName
        }
    }, [parentContext, forceNew, expanded, setExpanded, names, addName, removeName]);

    if (!context) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <ErrorContext.Provider value={context}>
            {children}
        </ErrorContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormErrorHandler;