/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an saes by institution codes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import {
    BoundActionCreator,
    OmitFirstArg
} from '@ngt/opms';

import { bindActionCreators } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * used to type actions.
 */
import { SaesReducer, saesActions, useSaesSelector, saesSelectors, ISaesStore } from '../store/saes';
import { Patient } from '@ngt/opms/dist/api/patient';
import { ResponseStatus, IPatient } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseSaeActions {
    load: BoundActionCreator<OmitFirstArg<typeof saesActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof saesActions.clear>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useSaes = <TSaeForm extends Dtos.ISaeForm = Dtos.ISaeForm, TPatient extends IPatient = IPatient, TSae extends Dtos.Sae<TSaeForm, TPatient> = Dtos.Sae<TSaeForm, TPatient>>(institutionCode?: string, autoLoad?: boolean): [
    Array<TSae> | null,
    IRequestState<ResponseStatus>,
    IUseSaeActions
] => {
    const dispatch = useDispatch();

    const actions = React.useMemo(() => {
        const load = () => saesActions.load(institutionCode);
        load.type = saesActions.load.type;

        const clear = () => saesActions.clear(institutionCode);
        clear.type = saesActions.clear.type;

        return bindActionCreators({
            load,
            clear
        }, dispatch);
    }, [saesActions, institutionCode, dispatch]);

    const saeSelector = React.useCallback((state: ISaesStore) => {
        return saesSelectors.saes(state, institutionCode)
    }, [saesSelectors.saes, institutionCode]);

    const loadStateSelector = React.useCallback((state: ISaesStore) => {
        return saesSelectors.loadState(state, institutionCode)
    }, [saesSelectors.loadState, institutionCode]);

    const saes = useSaesSelector(saeSelector);

    const loadState = useSaesSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, institutionCode]);

    return [
        saes as Array<TSae> | null,
        loadState,
        actions
    ];
};

