/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains types to help create and mutate function types.
 * --------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Imports - External
* ---------------------------------------------------------------------------------
*/

import * as React from 'react';

/**
 * typings
 */
import { CreateLogic } from 'redux-logic/definitions/logic';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/

/**
 * Typings
 */
import { IActionTypedFunction } from '../utilities/functionTypes';

import { AsyncFunction } from 'redux-promise-listener';
import { Action } from 'redux';
import useValueChangedLog from './useValueChangedLog';
import OnlinePatientManagementContext from '../contexts/OnlinePatientManagementContext';

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/

const useAsyncFunction = <
    RequestParameters extends any[] = any[],
    RequestReturn = any,
    SuccessfulResponseParameters extends any[] = any[],
    SuccessfulResponseReturn = any,
    FailedResponseParameters extends any[] = any[],
    FailedResponseReturn = any
>(
    requestActionCreator: IActionTypedFunction<RequestParameters, RequestReturn>,
    successfulResponseActionCreator: IActionTypedFunction<SuccessfulResponseParameters, SuccessfulResponseReturn>,
    failedResponseActionCreator: IActionTypedFunction<FailedResponseParameters, FailedResponseReturn>,
    setPayload?: (action: Action, payload: RequestParameters) => Action
) => {
    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const [data, setData] = React.useState<{ asyncFunction: AsyncFunction<RequestParameters, SuccessfulResponseReturn>; unsubscribe: () => void; }>(null as any);

    //useValueChangedLog(data, 'useAsyncFunction - data');
    //useValueChangedLog(setData, 'useAsyncFunction - setData');
    //useValueChangedLog(setPayload, 'useAsyncFunction - setPayload');
    //useValueChangedLog(requestActionCreator.type, 'useAsyncFunction - requestActionCreator.type');
    //useValueChangedLog(successfulResponseActionCreator.type, 'useAsyncFunction - successfulResponseActionCreator.type');
    //useValueChangedLog(failedResponseActionCreator.type, 'useAsyncFunction - failedResponseActionCreator.type');

    React.useEffect(() => {

        const defaultPayload = (action: Action, parameters: RequestParameters) => {
            if (!(parameters instanceof Array)) {
                return { type: action.type, payload: parameters };
            }

            if (parameters.length === 1) {
                return { type: action.type, payload: parameters[0] };
            }

            return {
                type: action.type,
                payload: parameters,
                args: true,
            };
        };

        const d = onlinePatientManagement.reduxPromiseListener.createAsyncFunction<RequestParameters, SuccessfulResponseReturn, FailedResponseReturn>({
            start: requestActionCreator.type,
            resolve: successfulResponseActionCreator.type,
            reject: failedResponseActionCreator.type,
            setPayload: setPayload ?? defaultPayload
        });

        setData(d);

        return () => {
            d.unsubscribe();
        };
    }, [setData, setPayload, requestActionCreator.type, successfulResponseActionCreator.type, failedResponseActionCreator.type, onlinePatientManagement.reduxPromiseListener]);

    return data?.asyncFunction;
};

/*
* ---------------------------------------------------------------------------------
* Default Exports
* ---------------------------------------------------------------------------------
*/
export default useAsyncFunction;

