/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';
import { faFileAlt } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';
import {
    Breadcrumbs,
    IBreadcrumb,
    IInstitution,
    IPatient,
    DEFAULT_INSTITUTION_BREADCRUMB_CREATOR,
    ALL_INSTITUTIONS,
    ALL_INSTITUTIONS_CODE,
    InstitutionContext,
    InstitutionsContext,
    PatientContext,
    OnlinePatientManagementContext
} from '@ngt/opms'

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the PatientBreadcrumbs component.
 */
export interface ISaeBreadcrumbsProps {
    institution?: IInstitution | null;
    patientStudyNumber?: number | null;
    saeNumber?: number | null;
    createInstitutionLink?: (institution?: IInstitution | null) => string;
    createSaeLink?: (institution?: IInstitution | null, patientStudyNumber?: string | null, saeNumber?: string | null) => string;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

export const DEFAULT_SAE_BREADCRUMB_CREATOR = (institution?: IInstitution | null, patientStudyNumber?: string | null, saeNumber?: string | null) => {
    if (patientStudyNumber && saeNumber) {
        return `/sae/${institution?.code ?? ALL_INSTITUTIONS_CODE}/${patientStudyNumber}/${saeNumber}`;
    } else if (institution) {
        return `/sae/${institution?.code}`
    } else {
        return '/sae';
    }
}

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const SaeBreadcrumbs: React.FunctionComponent<ISaeBreadcrumbsProps> = ({
    children,
    institution,
    patientStudyNumber,
    saeNumber,
    createInstitutionLink,
    createSaeLink
}) => {

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);
    const contextInstitution = React.useContext(InstitutionContext);
    const contextInstitutions = React.useContext(InstitutionsContext);
    const params = useParams<Record<string, string>>();
    const saeNumberParam = 'saeNumber';

    const breadcrumbs = React.useMemo(() => {
        let i = institution;

        if (i === undefined) {
            const institutionCode = params[onlinePatientManagement.routeParameters.institutionCode];
            const institutionId = params[onlinePatientManagement.routeParameters.institutionId];

            if (contextInstitution.institution && (contextInstitution.institution.code === institutionCode || contextInstitution.institution.id?.toString() === institutionId)) {
                i = contextInstitution.institution;
            }
            else if (contextInstitutions.institutions) {
                i = contextInstitutions.institutions.find(inst => inst.code === institutionCode || inst.id?.toString() === institutionId);
            }
        }

        const b: IBreadcrumb[] = [];

        if (i) {
            b.push({
                icon: faBuilding,
                label: i.name,
                to: !!createInstitutionLink ? createInstitutionLink(i) : DEFAULT_SAE_BREADCRUMB_CREATOR(i)
            });
        }
        else {
            b.push({
                icon: faBuilding,
                label: ALL_INSTITUTIONS,
                to: !!createInstitutionLink ? createInstitutionLink(i) : DEFAULT_SAE_BREADCRUMB_CREATOR(i)
            });
        }

        const p = patientStudyNumber === undefined ? params[onlinePatientManagement.routeParameters.patientStudyNumber] : patientStudyNumber;

        const s = saeNumber === undefined ? params[saeNumberParam] : saeNumber;

        if (p && s) {
            b.push({
                icon: faFileAlt,
                label: `${p}(SAE No. ${s})`,
                to: !!createSaeLink ? createSaeLink(i, p as string, s as string) : DEFAULT_SAE_BREADCRUMB_CREATOR(i, p as string, s as string)
            });
        }

        return b;
    }, [contextInstitution, contextInstitutions, institution, createInstitutionLink, params, onlinePatientManagement, createSaeLink]);

    return !!breadcrumbs && (
        <>
            <Breadcrumbs
                breadcrumbs={breadcrumbs}
            />
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeBreadcrumbs;

