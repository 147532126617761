/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an sae by patient number and sae number
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

import { bindActionCreators } from 'redux';

import {
    IPatient,
    ResponseStatus,
    useAsyncFunction,
    BoundActionCreator,
    TypedFunction,
    bindActionCreatorsWithType,
    OmitFirstArg
} from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

import {
    ScreeningLogReducer,
    screeningLogActions,
    useScreeningLogSelector,
    screeningLogSelectors,
    IScreeningLogStore
} from '../store/screeningLog';

import { ScreeningLog } from '../api/screeningLog';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseScreeningLogActions {
    load: BoundActionCreator<OmitFirstArg<typeof screeningLogActions.load>>;
    clear: BoundActionCreator<OmitFirstArg<typeof screeningLogActions.clear>>;
    save: BoundActionCreator<OmitFirstArg<typeof screeningLogActions.save>>;
    del: BoundActionCreator<OmitFirstArg<typeof screeningLogActions.delete>>;

    asyncSave: TypedFunction<Parameters<BoundActionCreator<OmitFirstArg<typeof screeningLogActions.save>>>, Promise<ScreeningLog | undefined>>;
    asyncDelete: TypedFunction<Parameters<BoundActionCreator<OmitFirstArg<typeof screeningLogActions.delete>>>, Promise<ScreeningLog | undefined>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const useScreeningLog = <TScreeningLog extends ScreeningLog = ScreeningLog>(id?: number, autoLoad?: boolean): [
    TScreeningLog | null,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IRequestState<ResponseStatus>,
    IUseScreeningLogActions
] => {
    const dispatch = useDispatch();

    const unboundAsyncSave = useAsyncFunction(screeningLogActions.save, screeningLogActions.saveSuccess, screeningLogActions.saveFailure);
    const unboundAsyncDelete= useAsyncFunction(screeningLogActions.delete, screeningLogActions.deleteSuccess, screeningLogActions.deleteFailure);

    const actions: IUseScreeningLogActions = React.useMemo(() => {
        const load = () => screeningLogActions.load(id!!);
        load.type = screeningLogActions.load.type;

        const clear = () => screeningLogActions.clear(id!!);
        clear.type = screeningLogActions.clear.type;

        const save = (screeningLog?: ScreeningLog) => screeningLogActions.save(id, screeningLog);
        save.type = screeningLogActions.save.type;

        const del = (screeningLog?: ScreeningLog) => screeningLogActions.delete(id, screeningLog);
        del.type = screeningLogActions.delete.type;

        const asyncSave = async (screeningLog?: ScreeningLog) => {
            const [, savedScreeningLog] = await unboundAsyncSave([id, screeningLog]);

            return savedScreeningLog;
        };

        const asyncDelete = async (screeningLog?: ScreeningLog) => {
            const [, ,] = await unboundAsyncDelete([id, screeningLog]);

            return undefined;
        };

        return {
            ...bindActionCreatorsWithType({
                load,
                clear,
                save,
                del,
            }, dispatch),
            asyncSave,
            asyncDelete
        };
    }, [screeningLogActions, id, dispatch, unboundAsyncSave]);

    const screeningLogSelector = React.useCallback((state: IScreeningLogStore) => {
        return screeningLogSelectors.screeningLog(state, id)
    }, [screeningLogSelectors.screeningLog, id]);

    const loadStateSelector = React.useCallback((state: IScreeningLogStore) => {
        return screeningLogSelectors.loadState(state, id)
    }, [screeningLogSelectors.loadState, id]);

    const saveStateSelector = React.useCallback((state: IScreeningLogStore) => {
        return screeningLogSelectors.saveState(state, id)
    }, [screeningLogSelectors.saveState, id]);

    const deleteStateSelector = React.useCallback((state: IScreeningLogStore) => {
        return screeningLogSelectors.deleteState(state, id)
    }, [screeningLogSelectors.deleteState, id]);

    const screeningLog = useScreeningLogSelector(screeningLogSelector);

    const loadState = useScreeningLogSelector(loadStateSelector);

    const saveState = useScreeningLogSelector(saveStateSelector);

    const deleteState = useScreeningLogSelector(deleteStateSelector);

    React.useEffect(() => {
        if (autoLoad && id) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, id]);

    return [
        screeningLog as TScreeningLog | null,
        loadState,
        saveState,
        deleteState,
        actions
    ];
}