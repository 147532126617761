
/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

import React from "react";

export interface ISaeReviewContext {
    hasMedicalReview: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const SaeReviewContext = React.createContext<ISaeReviewContext>({
    hasMedicalReview: true
})

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeReviewContext;