/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component for the select icon.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

/**
 * Used to style the icon.
 */
import { makeStyles, Theme } from '@material-ui/core/styles';

/**
 * Used as basic foundation of the application.
 */
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to type master groups
 */
import * as Dtos from '../../api/dtos';

/*
 * Used to consistently identify and name the all master groups option.
 */
import { ALL_MASTER_GROUPS_CODE, ALL_MASTER_GROUPS } from '../../constants/masterGroup';

/*
 * Used to consistently style the select drop down icon.
 */
import SelectIcon from './SelectIcon';
/*
 * Used to consistently style the select loading icon.
 */
import LoadingIcon from './LoadingIcon';

/*
 * Used for basic selector logic.
 */ 
import Selector from './Selector';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface ICodeSelectorProps {
    items?: IItem[] | null;
    loading?: boolean | null;
    allSelectionAllowed?: boolean | null;
    allSelectionLabel?: string | null;
    allSelectionValue?: string | null;
    inputId: string;
    label: string;
    selection?: string | null | undefined;
    onChange?: (code: string | null | undefined) => void;
}

interface IItem {
    id?: number;
    name?: string;
    code?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const CodeSelector: React.FunctionComponent<ICodeSelectorProps> = ({
    allSelectionAllowed,
    inputId,
    label,
    allSelectionLabel,
    allSelectionValue,
    loading,
    items,
    onChange,
    selection
}) => {
    const classes = useStyles();

    const onSelectedItemChange = React.useCallback((code: string | null | undefined) => {
        if (onChange) {
            onChange(code);
        }
    }, [onChange])

    const value = React.useMemo(() => {
        return allSelectionAllowed ? selection ?? allSelectionValue : selection ?? ''
    }, [allSelectionAllowed, selection, allSelectionValue]);

    return (
        <Selector
            inputId={inputId}
            label={label}
            onSelectionChanged={onSelectedItemChange}
            selection={value}
            loading={loading}
        >
            {
                allSelectionAllowed && (
                    <MenuItem
                        key="all"
                        value={allSelectionValue ?? undefined}
                    >
                        {allSelectionLabel}
                    </MenuItem>
                )
            }
            {
                !!items && items?.map(item => {
                    return (
                        <MenuItem
                            key={item.id}
                            value={item.code}
                        >
                            {item.name}
                        </MenuItem>
                    );
                })
            }
        </Selector>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default CodeSelector;
