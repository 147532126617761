/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import FieldGroupContext from '../../../../contexts/form/FieldGroupContext';
import FieldProvider from '../../FieldProvider';
import FormLabel from '../../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../../FormErrorHandler';
import FormErrorDisplay from '../../FormErrorDisplay';
import FieldErrorFlag from '../../FieldErrorFlag';
import Input, { IInputProps } from '../../Input';
import { InputComponentType, OmitInputRender } from '../../../../form/components/Input';
import StaticGridContext from '../../../../contexts/field/StaticGridContext';
import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IStaticGridHeaderProps extends IStaticGridHeaderStyleProps {
    columnName: string;
    content: React.ReactNode;
    className?: string;
}

interface IStaticGridHeaderStyleProps {
    width?: string | number;
    minWidth?: string | number;
    maxWidth?: string | number;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    header: {
        width: (props: IStaticGridHeaderStyleProps) => props.width ? props.width : undefined,
        minWidth: (props: IStaticGridHeaderStyleProps) => props.minWidth ? props.minWidth : undefined,
        maxWidth: (props: IStaticGridHeaderStyleProps) => props.maxWidth ? props.maxWidth : undefined
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const StaticGridHeader: React.FunctionComponent<IStaticGridHeaderProps> = ({
    content,
    columnName,
    width,
    minWidth,
    maxWidth
}) => {

    const classes = useStyles({ width, minWidth, maxWidth });

    return (
        <TableCell key={columnName} className={classes.header}>
            <strong>
                {content}
            </strong>
        </TableCell>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default StaticGridHeader;
