/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently loaded event.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used for typing request states.
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * Used to set default values for the context
 */
import { initialIndividualEventState } from '../../store/modules/data/event';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IEventContext {
    event: Dtos.IEvent | null;
    loadState: IRequestState<Dtos.ResponseStatus>,
    saveState: IRequestState<Dtos.ResponseStatus>,
    actions: IEventActions;
}

interface IEventActions {
    load: () => void;
    save: (event?: Dtos.IEvent) => void;
    clear: () => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const EventContext = React.createContext<IEventContext>({
    event: initialIndividualEventState.event,
    loadState: initialIndividualEventState.loadState,
    saveState: initialIndividualEventState.saveState,
    actions: {
        load: () => undefined,
        save: (event) => undefined,
        clear: () => undefined
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default EventContext;