/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the forms reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators, ActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to type special reducer registry.
 */
import ActionReducerRegistry from '../../../utilities/ActionRegistryReducer';

/*
 * Used to type Dto classes.
 */
import IDtoRequestClass from '../../../utilities/IDtoRequestClass';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualFormsState {
    forms: Dtos.IForm[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IFormsState {
    byIds: Record<string, IIndividualFormsState>;
    byCodes: Record<string, IIndividualFormsState>;
}

export interface IFormsStore {
    forms: Record<string, IFormsState>;
}

interface IFormCollectionResponse {
    forms?: Dtos.IForm[];
    responseStatus?: Dtos.ResponseStatus
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) => {
    return `${(patientId ?? 'null')}-${(eventDefinitionId ?? 'null')}-${(eventRepeat ?? 'null')}`;
}

const createStudyNumbersContext = (patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) => {
    return `${(patientStudyNumber ?? 'null')}-${(eventDefinitionCode ?? 'null')}-${(eventRepeat ?? 'null')}`;
}


/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all forms reducer state changes and actions. 
 */
export class FormsReducer extends ImmerReducer<IFormsState>
{
    public loadByIds(patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) {
        const context = createIdsContext(patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByIdsSuccess(patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, forms?: Dtos.IForm[]) {
        const context = createIdsContext(patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormsState };
        }

        this.draftState.byIds[context].forms = forms ? forms : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(patientId, eventDefinitionId, eventRepeat);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualFormsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) {
        const context = createIdsContext(patientId, eventDefinitionId, eventRepeat);

        this.draftState.byIds[context] = { ...initialIndividualFormsState }
    }

    public loadByCodes(patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) {
        const context = createStudyNumbersContext(patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByCodesSuccess(patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, forms?: Dtos.IForm[]) {
        const context = createStudyNumbersContext(patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormsState };
        }

        this.draftState.byCodes[context].forms = forms ? forms : null;

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodesFailure(patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createStudyNumbersContext(patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualFormsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByCodes(patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) {
        const context = createStudyNumbersContext(patientStudyNumber, eventDefinitionCode, eventRepeat);

        this.draftState.byCodes[context] = { ...initialIndividualFormsState }
    }

    public clearAll() {
        this.draftState = { ...initialFormsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialFormsState: IFormsState = {
    byIds: {},
    byCodes: {}
}

export const initialIndividualFormsState: IIndividualFormsState = {
    forms: null,
    loadState: {
        state: RequestState.None
    }
}

const createNamedFormsReducer = (formMetadata: Dtos.FormMetadata) => {
    let NamedReducer = class extends FormsReducer { };

    Object.defineProperty(NamedReducer, 'name', { value: `${formMetadata.name}FormsReducer` });

    return NamedReducer;
};

const createFormsApi = (formMetadata: Dtos.FormMetadata, dtos: Record<string, any>, client: JsonServiceClient) => {
    const FormGetCollection: IDtoRequestClass<Dtos.IFormGetCollection, IFormCollectionResponse> = dtos[`${formMetadata.name}GetCollection`];
    const FormGetCollectionByIds: IDtoRequestClass<Dtos.IFormGetCollectionByIds, IFormCollectionResponse> = dtos[`${formMetadata.name}GetCollectionByIds`];
    const FormGetCollectionByCodes: IDtoRequestClass<Dtos.IFormGetCollectionByCodes, IFormCollectionResponse> = dtos[`${formMetadata.name}GetCollectionByCodes`];

    return {
        loadByIds: (patientId?: number, eventDefinitionId?: number, eventRepeat?: number) => {
            if (patientId && eventDefinitionId && eventRepeat) {
                return client.get(new FormGetCollectionByIds({ patientId, eventDefinitionId, eventRepeat }));
            }
            else {
                return client.get(new FormGetCollection());
            }
        },
        loadByCodes: (patientStudyNumber?: string, eventDefinitionCode?: string, eventRepeat?: number) => {
            if (patientStudyNumber && eventDefinitionCode && eventRepeat) {
                return client.get(new FormGetCollectionByCodes({ patientStudyNumber, eventDefinitionCode, eventRepeat }));
            }
            else {
                return client.get(new FormGetCollection());
            }
        }
    }
};

const createFormsLogic = (actions: ActionCreators<typeof FormsReducer>, api: ReturnType<typeof createFormsApi>) => {
    const logic = {
        loadByIds: createLogic<IFormsStore, {}, undefined, string, ReturnType<typeof actions.loadByIds>>({
            type: actions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [ patientId, eventDefinitionId, eventRepeat ] = action.payload;

                try {
                    const response = await api.loadByIds(patientId ?? undefined, eventDefinitionId ?? undefined, eventRepeat ?? undefined);

                    dispatch(actions.loadByIdsSuccess(
                        patientId,
                        eventDefinitionId,
                        eventRepeat,
                        response.forms
                    ));
                }
                catch (error) {
                    dispatch(actions.loadByIdsFailure(patientId, eventDefinitionId, eventRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCodes: createLogic<IFormsStore, {}, undefined, string, ReturnType<typeof actions.loadByCodes>>({
            type: actions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [patientStudyNumber, eventDefinitionCode, eventRepeat] = action.payload;

                try {
                    const response = await api.loadByCodes(patientStudyNumber ?? undefined, eventDefinitionCode ?? undefined, eventRepeat ?? undefined);

                    dispatch(actions.loadByCodesSuccess(
                        patientStudyNumber,
                        eventDefinitionCode,
                        eventRepeat,
                        response.forms
                    ));
                }
                catch (error) {
                    dispatch(actions.loadByCodesFailure(patientStudyNumber, eventDefinitionCode, eventRepeat, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByIds,
        logic.loadByCodes
    ]
};

export const useFormsSelector: TypedUseSelectorHook<IFormsStore> = useSelector;

export const formsSelectors = {
    formsByIds: (state: IFormsStore, formPropertyName: string, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) => {
        const context = createIdsContext(patientId, eventDefinitionId, eventRepeat);

        if (!state.forms[formPropertyName]?.byIds[context]) {
            return initialIndividualFormsState.forms;
        }

        return state.forms[formPropertyName].byIds[context].forms;
    },
    loadStateByIds: (state: IFormsStore, formPropertyName: string, patientId?: number | null, eventDefinitionId?: number | null, eventRepeat?: number | null) => {
        const context = createIdsContext(patientId, eventDefinitionId, eventRepeat);

        if (!state.forms[formPropertyName]?.byIds[context]) {
            return initialIndividualFormsState.loadState;
        }

        return state.forms[formPropertyName].byIds[context].loadState;
    },
    formsByCodes: (state: IFormsStore, formPropertyName: string, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) => {
        const context = createStudyNumbersContext(patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!state.forms[formPropertyName]?.byCodes[context]) {
            return initialIndividualFormsState.forms;
        }

        return state.forms[formPropertyName].byCodes[context].forms;
    },
    loadStateByCodes: (state: IFormsStore, formPropertyName: string, patientStudyNumber?: string | null, eventDefinitionCode?: string | null, eventRepeat?: number | null) => {
        const context = createStudyNumbersContext(patientStudyNumber, eventDefinitionCode, eventRepeat);

        if (!state.forms[formPropertyName]?.byCodes[context]) {
            return initialIndividualFormsState.loadState;
        }

        return state.forms[formPropertyName].byCodes[context].loadState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerFormsReducer = (formMetadata: Dtos.FormMetadata, dtos: Record<string, any>, client: JsonServiceClient, reducerRegistry: ActionReducerRegistry) => {
    if (!formMetadata?.propertyName) {
        return;
    }

    const NamedReducer = createNamedFormsReducer(formMetadata);

    const actions = createActionCreators(NamedReducer);
    const reducer = createReducerFunction(NamedReducer, initialFormsState);

    const api = createFormsApi(formMetadata, dtos, client);

    const logic = createFormsLogic(actions, api);

    reducerRegistry.register(formMetadata.propertyName, reducer as Reducer, logic as any);
    reducerRegistry.registerActions(formMetadata.propertyName, actions);
};

export default registerFormsReducer;