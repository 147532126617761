/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an eventDefinitions by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { EventDefinitionsReducer, eventDefinitionsActions, useEventDefinitionsSelector, eventDefinitionsSelectors, IEventDefinitionsStore } from '../../store/modules/configuration/eventDefinitions';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg, OmitFirstThreeArgs } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseEventDefinitionsByIdActions {
    load: BoundActionCreator<OmitFirstThreeArgs<typeof eventDefinitionsActions.load>>;
    clear: BoundActionCreator<OmitFirstThreeArgs<typeof eventDefinitionsActions.clear>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useEventDefinitions = <EventDefinitionsType extends Dtos.IEventDefinition>(autoLoad?: boolean): [
    EventDefinitionsType[] | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseEventDefinitionsByIdActions
] => {
    const dispatch = useDispatch();

    const actions: IUseEventDefinitionsByIdActions = React.useMemo(() => {
        return bindActionCreatorsWithType({
            load: eventDefinitionsActions.load,
            clear: eventDefinitionsActions.clear
        }, dispatch);
    }, [eventDefinitionsActions, dispatch]);

    const eventDefinitions = useEventDefinitionsSelector(eventDefinitionsSelectors.eventDefinitions);

    const loadState = useEventDefinitionsSelector(eventDefinitionsSelectors.loadState);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad]);

    return [
        eventDefinitions as EventDefinitionsType[] | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useEventDefinitions;