/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import FieldGroupContext from '../../../../contexts/form/FieldGroupContext';
import FieldProvider from '../../FieldProvider';
import FormLabel from '../../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../../FormErrorHandler';
import FormErrorDisplay from '../../FormErrorDisplay';
import FieldErrorFlag from '../../FieldErrorFlag';
import Input, { IInputProps } from '../../Input';
import { InputComponentType, OmitInputRender } from '../../../../form/components/Input';
import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import StaticGridHeader, { IStaticGridHeaderProps } from './StaticGridHeader';
import StaticGridContext from '../../../../contexts/field/StaticGridContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IStaticGridProps {
    headers: IStaticGridHeaderProps[]
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    container: {
        overflowX: 'auto'
    },
    table: {
        minWidth: '100%'
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */


const StaticGrid: React.FunctionComponent<IStaticGridProps> = ({
    headers,
    children
}) => {
    const classes = useStyles();

    return (
        <StaticGridContext.Provider value={headers}>
            <div
                className={classes.container}
            >
                <Table
                    className={classes.table}
                >
                    <TableHead>
                        <TableRow>
                            {
                                headers?.map((header) => {
                                    return (
                                        <StaticGridHeader
                                            key={header.columnName}
                                            {...header}
                                        />
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {children}
                    </TableBody>
                </Table>
            </div>
        </StaticGridContext.Provider>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default StaticGrid;
