/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently loaded patient.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used for typing request states.
 */
import { IRequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * Used to set default values for the context
 */
import { initialIndividualPatientState } from '../../store/modules/data/patient';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IPatientContext {
    patient: Dtos.IPatient | null;
    loadState: IRequestState<Dtos.ResponseStatus>,
    saveState: IRequestState<Dtos.ResponseStatus>,
    actions: IPatientActions;
}

interface IPatientActions {
    load: () => void;
    save: (patient?: Dtos.IPatient) => void;
    asyncSave: (patient?: Dtos.IPatient) => Promise<Dtos.IPatient | undefined>
    clear: () => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientContext = React.createContext<IPatientContext>({
    patient: initialIndividualPatientState.patient,
    loadState: initialIndividualPatientState.loadState,
    saveState: initialIndividualPatientState.saveState,
    actions: {
        load: () => undefined,
        save: (patient) => undefined,
        clear: () => undefined,
        asyncSave: (form) => new Promise<Dtos.IPatient | undefined>((resolve, reject) => {
            resolve(undefined)
        })
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientContext;