/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import { IInputRenderProps } from '../../../form/components/Input';

import LabelContext from '../../../contexts/form/LabelContext';
import Markdown from '../../utility/Markdown';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type CheckboxsProps = Partial<Omit<MuiCheckboxProps, 'type'>> & IInputRenderProps<boolean, Dtos.IValidationError>

export interface ICheckbox extends CheckboxsProps {
    label?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    text: {
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const Checkbox: React.FunctionComponent<ICheckbox> = ({
    inputRender: { state: { name, value, ...restInputState }, actions: { update: onInputChange, blur: onBlur, focus: onFocus, ...restInputActions } },
    label,
    onChange,
    inputProps,
    ...rest
}) => {
    const contextLabel = React.useContext(LabelContext);

    const labelToUse = React.useMemo(() => {
        const l = label ?? contextLabel?.detailedLabel ?? contextLabel?.label;

        if (!l) {
            return null;
        }

        return (
            <Markdown>
                {l}
            </Markdown>
        );
    }, [contextLabel?.label, contextLabel?.detailedLabel, label])


    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (onChange) {
            onChange(event, checked);
        }

        onInputChange(checked);
    }, [onChange, onInputChange]);

    const onFocusCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (inputProps?.onFocus) {
            inputProps.onFocus(event);
        }

        onFocus();
    }, [inputProps?.onFocus, onFocus]);

    const onBlurCombined = React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (inputProps?.onBlur) {
            inputProps.onBlur(event);
        }

        onBlur();
    }, [inputProps?.onBlur, onBlur]);

    const checkbox = (
        <MuiCheckbox
            {...rest as any}
            inputProps={{
                ...inputProps,
                onBlur: onBlurCombined,
                onFocus: onFocusCombined
            }}
            name={name}
            checked={value ?? false}
            onChange={onChangeCombined}
        />
    );

    if (!labelToUse) {
        return checkbox;
    }

    return (
        <FormControlLabel
            control={checkbox}
            label={labelToUse}
        />
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Checkbox;
