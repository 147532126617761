/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the reducer registry class used to control adding reducers
 * and logic to the redux store at run time.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { get, set, cloneDeep } from 'lodash-es'

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

/*
 * Used to get object property paths.
 */
import getDeepKeys from '../utilities/getDeepKeys';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export type ValidateOn = 'onSubmit' | 'onBlur' | 'onChange';

export interface IFormValidate<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>): Promise<Record<string, TError[]>>
}

export interface IFormAllowSubmit<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>, formActions: IFormActions<TValues, TError> ): Promise<boolean>;
}

export interface IFormSubmit<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>): Promise<void | Record<string, TError[]>>
}

export interface IFormSubmitFailed<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>): Promise<void | Record<string, TError[]>>
}

export interface IFormSubmitValidationFailed<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>, validationError: boolean): Promise<void | Record<string, TError[]>>
}

export interface IFormOptions<TValues extends object = any, TError = any> {
    initialValues?: TValues | null;
    onValidate?: IFormValidate<TValues, TError> | null;
    allowSubmit?: IFormAllowSubmit<TValues, TError> | null;
    onSubmit?: IFormSubmit<TValues, TError> | null;
    onSubmitValidationFailed?: IFormSubmitValidationFailed<TValues, TError> | null;
    onSubmitFailed?: IFormSubmitFailed<TValues, TError> | null;
    validateOn?: ValidateOn;
}

export interface IFormState<TValues extends object = any, TError = any> {
    values: TValues;
    fields: string[];
    touched: Record<string, boolean>;
    dirty: Record<string, boolean>;
    initialValues: TValues;
    focused: Record<string, boolean>;
    errors: Record<string, TError[]>;
    validating: boolean;
    submitting: boolean;
}

export interface IFormSubscription extends Record<keyof IFormState, boolean> {

}

export interface IFormListener<TValues extends object = any, TError = any> {
    subscription: IFormSubscription;
    subscriber: IFormSubscriber<TValues, TError>;
}

export interface IFieldState<TValue = any, TError = any> {
    value: TValue | null | undefined;
    touched: boolean;
    dirty: boolean;
    initialValue: TValue | null | undefined;
    focused: boolean;
    errors: TError[];
}

export interface IFieldSubscription extends Record<keyof IFieldState, boolean> {

}

export interface IFormSubscriber<TValues extends object = any, TError = any> {
    (formState: IFormState<TValues, TError>): void;
}

export interface IFieldSubscriber<TValue = any, TError = any> {
    (fieldState: IFieldState<TValue, TError>): void;
}

export interface IFieldListener<TValue = any, TError = any> {
    path: string;
    subscription: IFieldSubscription;
    subscriber: IFieldSubscriber<TValue, TError>;
}

export interface IUnsubscribe {
    (): void;
}

export interface IFormActions<TValues extends object = any, TError = any> {
    getValues: Form<TValues, TError>['getFormValues'];
    getTouched: Form<TValues, TError>['getFormTouched'];
    getDirty: Form<TValues, TError>['getFormDirty'];
    getFocused: Form<TValues, TError>['getFormFocused'];
    getErrors: Form<TValues, TError>['getFormErrors'];
    getInitialValues: Form<TValues, TError>['getFormInitialValues'];
    getSubmitting: Form<TValues, TError>['getFormSubmitting'];
    getValidating: Form<TValues, TError>['getFormValidating'];
    setValues: Form<TValues, TError>['setFormValues'];
    setTouched: Form<TValues, TError>['setFormTouched'];
    setDirty: Form<TValues, TError>['setFormDirty'];
    setFocused: Form<TValues, TError>['setFormFocused'];
    setErrors: Form<TValues, TError>['setFormErrors'];
    setValidating: Form<TValues, TError>['setFormValidating'];
    setSubmitting: Form<TValues, TError>['setFormSubmitting'];
    getFieldValue: Form<TValues, TError>['getFieldValue'];
    getFieldTouched: Form<TValues, TError>['getFieldTouched'];
    getFieldDirty: Form<TValues, TError>['getFieldDirty'];
    getFieldFocused: Form<TValues, TError>['getFieldFocused'];
    getFieldErrors: Form<TValues, TError>['getFieldErrors'];
    getFieldInitialValue: Form<TValues, TError>['getFieldInitialValue'];
    setFieldValue: Form<TValues, TError>['setFieldValue'];
    setFieldTouched: Form<TValues, TError>['setFieldTouched'];
    setFieldDirty: Form<TValues, TError>['setFieldDirty'];
    setFieldFocused: Form<TValues, TError>['setFieldFocused'];
    setFieldErrors: Form<TValues, TError>['setFieldErrors'];
    reset: Form<TValues, TError>['reset'];
    submit: Form<TValues, TError>['submit'];
    validate: Form<TValues, TError>['validate'];
    subscribe: Form<TValues, TError>['subscribeToForm'];
    subscribeToField: Form<TValues, TError>['subscribeToField'];
    registerField: Form<TValues, TError>['registerField'];
    unregisterField: Form<TValues, TError>['unregisterField'];
    getFields: Form<TValues, TError>['getFields'];
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

const isDevelopment = process.env.NODE !== "production";

/**
 * This class handles the form state of a form
 */
export class Form<TValues extends object = any, TError = any> {
    private values: TValues;
    private initialValues: TValues;
    private touched: Record<string, boolean>;
    private dirty: Record<string, boolean>;
    private focused: Record<string, boolean>;
    private errors: Record<string, TError[]>;
    private onSubmit?: IFormSubmit<TValues, TError> | null;
    private onValidate?: IFormValidate<TValues, TError> | null;
    private onSubmitValidationFailed?: IFormSubmitValidationFailed<TValues, TError> | null;
    private onSubmitFailed?: IFormSubmitFailed<TValues, TError> | null;
    private formListeners: IFormListener<TValues, TError>[];
    private fieldListeners: IFieldListener<any, TError>[];
    private validateOn: ValidateOn;
    private allowSubmit?: IFormAllowSubmit<TValues, TError> | null;
    private validating: boolean;
    private submitting: boolean;
    private fields: string[];


    constructor(options?: IFormOptions) {
        this.initialValues = options?.initialValues ?? {} as any;
        this.onSubmit = options?.onSubmit;
        this.onSubmitFailed = options?.onSubmitFailed;
        this.onSubmitValidationFailed = options?.onSubmitValidationFailed;
        this.onValidate = options?.onValidate;
        this.allowSubmit = options?.allowSubmit;
        this.validateOn = options?.validateOn ?? 'onSubmit';
        this.fieldListeners = [];
        this.formListeners = [];
        this.fields = [];
        this.errors = {};

        this.reset(true, true);

        this.reset = this.reset.bind(this);
        this.getFormSubmitting = this.getFormSubmitting.bind(this);
        this.getFormValidating = this.getFormValidating.bind(this);
        this.getFieldDirty = this.getFieldDirty.bind(this);
        this.getFieldErrors = this.getFieldErrors.bind(this);
        this.getFieldFocused = this.getFieldFocused.bind(this);
        this.getFieldInitialValue = this.getFieldInitialValue.bind(this);
        this.getFieldTouched = this.getFieldTouched.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
        this.getFormDirty = this.getFormDirty.bind(this);
        this.getFormErrors = this.getFormErrors.bind(this);
        this.getFormFocused = this.getFormFocused.bind(this);
        this.getFormInitialValues = this.getFormInitialValues.bind(this);
        this.getFormTouched = this.getFormTouched.bind(this);
        this.getFormValues = this.getFormValues.bind(this);
        this.setFormSubmitting = this.setFormSubmitting.bind(this);
        this.setFormValidating = this.setFormValidating.bind(this);
        this.setFieldDirty = this.setFieldDirty.bind(this);
        this.setFieldErrors = this.setFieldErrors.bind(this);
        this.setFieldFocused = this.setFieldFocused.bind(this);
        this.setFieldTouched = this.setFieldTouched.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);
        this.setFormDirty = this.setFormDirty.bind(this);
        this.setFormErrors = this.setFormErrors.bind(this);
        this.setFormFocused = this.setFormFocused.bind(this);
        this.setFormTouched = this.setFormTouched.bind(this);
        this.setFormValues = this.setFormValues.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.subscribeToField = this.subscribeToField.bind(this);
        this.subscribeToForm = this.subscribeToForm.bind(this);
        this.setOnSubmit = this.setOnSubmit.bind(this);
        this.setAllowSubmit = this.setAllowSubmit.bind(this);
        this.setOnValidate = this.setOnValidate.bind(this);
        this.setValidateOn = this.setValidateOn.bind(this);
        this.setInitialValues = this.setInitialValues.bind(this);
        this.unregisterField = this.unregisterField.bind(this);
        this.registerField = this.registerField.bind(this);
        this.getFields = this.getFields.bind(this);
        this.getActions = this.getActions.bind(this);
    }

    public setOnSubmit(onSubmit?: IFormSubmit<TValues, TError> | null) {
        this.onSubmit = onSubmit;
    }

    public setAllowSubmit(allowSubmit?: IFormAllowSubmit<TValues, TError> | null) {
        this.allowSubmit = allowSubmit;
    }

    public setOnValidate(onValidate?: IFormValidate<TValues, TError> | null) {
        this.onValidate = onValidate;
    }

    public setValidateOn(validateOn?: ValidateOn | null) {
        this.validateOn = validateOn ?? 'onSubmit';;
    }

    public setInitialValues(initialValues?: TValues | null, notifyForm?: boolean, notifyFields?: boolean) {
        if (initialValues != this.initialValues) {
            this.initialValues = initialValues ?? {} as any;

            this.reset(false, false);

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: true,
                    errors: true,
                    fields: false,
                    focused: true,
                    initialValues: true,
                    touched: true,
                    values: true,
                    submitting: true,
                    validating: true
                });
            }

            if (notifyFields !== false) {
                const listenerPaths = new Set(this.fieldListeners.map(l => l.path));

                listenerPaths.forEach(path => {
                    this.notifyFieldChange(
                        path,
                        {
                            dirty: true,
                            errors: true,
                            focused: true,
                            initialValue: true,
                            touched: true,
                            value: true
                        }
                    );
                });
            }

            return true;
        }

        return false;
    }

    public reset(notifyForm?: boolean, notifyFields?: boolean) {
        this.setFormValues(cloneDeep(this.initialValues ?? {} as any), false, false);
        this.setFormTouched({}, false, false);
        this.setFormDirty({}, false, false);
        this.setFormFocused({}, false, false);
        //this.setFormErrors({}, false, false);
        this.setFormSubmitting(false, false);
        this.setFormValidating(false, false);

        if (notifyForm) {
            this.notifyFormChange({
                dirty: true,
                fields: false,
                errors: false,
                focused: true,
                initialValues: false,
                touched: true,
                values: true,
                submitting: true,
                validating: true
            });
        }

        if (notifyFields) {
            const listenerPaths = new Set(this.fieldListeners.map(l => l.path));

            listenerPaths.forEach(path => {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: true,
                        errors: false,
                        focused: true,
                        initialValue: false,
                        touched: true,
                        value: true
                    }
                );
            });
        }

        this.validate();
    }

    public setFormValues(values: TValues, notifyForm?: boolean, notifyFields?: boolean) {
        if (values !== this.values) {
            const updatedPaths = notifyFields ? this.updateValuePaths(this.values, values ?? {}) : [];

            this.values = values ?? {};

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    fields: false,
                    errors: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: true,
                    submitting: false,
                    validating: false
                });
            }

            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: false,
                            errors: false,
                            focused: false,
                            initialValue: false,
                            touched: false,
                            value: true
                        }
                    );
                });
            }

            if (this.validateOn === 'onChange') {
                this.validate();
            }

            return true;
        }

        return false;
    }

    public getFormValues() {
        return this.values;
    }

    public getFormInitialValues() {
        return this.initialValues;
    }

    public registerField(path: string, notifyForm?: boolean) {
        if (!this.fields.includes(path)) {
            this.fields = [...this.fields, path];

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: true,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                })
            }
            
            return true;
        }

        return false;
    }

    public unregisterField(path: string, notifyForm?: boolean) {
        if (this.fields.includes(path)) {
            this.fields = this.fields.filter(f => f !== path);

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: true,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                })
            }

            return true;
        }

        return false;
    }

    public getFields() {
        return this.fields;
    }

    public getFormSubmitting() {
        return this.submitting;
    }

    public setFormSubmitting(submitting: boolean, notifyForm?: boolean) {
        if (submitting !== this.submitting) {

            this.submitting = submitting;

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: true,
                    validating: false
                });
            }

            return true;
        }

        return false;
    }

    public getFormValidating() {
        return this.validating;
    }

    public setFormValidating(validating: boolean, notifyForm?: boolean) {
        if (validating !== this.validating) {

            this.validating = validating;

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: true
                });
            }

            return true;
        }

        return false;
    }

    public setFormTouched(touched: Record<string, boolean> | null | undefined, notifyForm?: boolean, notifyFields?: boolean) {
        if (touched !== this.touched) {
            const updatedPaths = notifyFields !== false ? this.updatedStatePaths(this.touched, touched ?? {}) : [];

            this.touched = touched ?? {};

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: true,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: false,
                            errors: false,
                            focused: false,
                            initialValue: false,
                            touched: true,
                            value: false
                        }
                    );
                });
            }

            return true;
        }

        return false;
    }

    public getFormTouched() {
        return this.touched;
    }

    public setFormDirty(dirty: Record<string, boolean> | null | undefined, notifyForm?: boolean, notifyFields?: boolean) {
        if (dirty !== this.dirty) {
            const updatedPaths = notifyFields !== false ? this.updatedStatePaths(this.dirty, dirty ?? {}) : [];

            this.dirty = dirty ?? {};

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: true,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: true,
                            errors: false,
                            focused: false,
                            initialValue: false,
                            touched: false,
                            value: false
                        }
                    );
                });
            }

            return true;
        }

        return false;
    }

    public getFormDirty() {
        return this.dirty;
    }

    public setFormFocused(focused: Record<string, boolean> | null | undefined, notifyForm?: boolean, notifyFields?: boolean) {
        if (focused !== this.focused) {
            const validateOnBlur = this.validateOn === 'onBlur';
            const updatedPaths = notifyFields !== false || validateOnBlur ? this.updatedStatePaths(this.focused, focused ?? {}) : [];
            let validate = false;

            if (validateOnBlur) {
                validate = updatedPaths.some(path => !!(focused ?? {})[path] && this.focused[path] === true)
            }

            this.focused = focused ?? {};

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: true,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: false,
                            errors: false,
                            focused: true,
                            initialValue: false,
                            touched: false,
                            value: false
                        }
                    );
                });
            }

            if (validate) {
                this.validate();
            }

            return true;
        }

        return false;
    }

    public getFormFocused() {
        return this.focused;
    }

    public setFormErrors(errors: Record<string, TError[]> | null | undefined, notifyForm?: boolean, notifyFields?: boolean) {
        if (errors !== this.errors) {
            const updatedPaths = notifyFields !== false ? this.updatedStatePaths(this.errors, errors ?? {}) : [];

            this.errors = errors ?? {};

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: true,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            if (notifyFields !== false) {
                updatedPaths.forEach(updatedPath => {
                    this.notifyFieldChange(
                        updatedPath,
                        {
                            dirty: false,
                            errors: true,
                            focused: false,
                            initialValue: false,
                            touched: false,
                            value: false
                        }
                    );
                });
            }

            return true;
        }

        return false;
    }

    public getFormErrors() {
        return this.errors;
    }

    public setFieldValue<TValue = any>(path: string, value: TValue | null | undefined, silent?: boolean, notifyForm?: boolean, notifyField?: boolean) {
        const existingValue = get(this.values, path);

        if (value !== existingValue) {
            this.values = { ...set(this.values, path, value) };

            const formChanges: IFormSubscription = {
                dirty: false,
                errors: false,
                fields: false,
                focused: false,
                initialValues: false,
                touched: false,
                values: true,
                submitting: false,
                validating: false
            }

            const fieldChanges: IFieldSubscription = {
                dirty: false,
                errors: false,
                focused: false,
                initialValue: false,
                touched: false,
                value: true
            };

            if (this.setFieldDirty(path, true, false, false)) {
                formChanges.dirty = true;
                fieldChanges.dirty = true;
            }

            if (silent !== true) {
                // enable for touch on value change
                //if (this.setFieldTouched(path, true, false, false)) {
                //    formChanges.touched = true;
                //    fieldChanges.touched = true;
                //}

                if (this.setFieldFocused(path, true, false, false, true)) {
                    formChanges.focused = true;
                    fieldChanges.focused = true;
                }

            }

            if (notifyForm !== false) {
                this.notifyFormChange(formChanges);
            }

            if (notifyField !== false) {
                this.notifyFieldChange(path, fieldChanges);

                this.fieldListeners
                    .filter(fieldListener => fieldListener.path.startsWith(path) && fieldListener.path.length != path.length)
                    .forEach(fieldListener => {
                        this.notifyFieldChange(fieldListener.path, {
                            dirty: false,
                            errors: false,
                            focused: false,
                            initialValue: false,
                            touched: false,
                            value: true
                        });
                    });
            }

            if (this.validateOn === 'onChange') {
                this.validate();
            }

            return true;
        }

        return false;
    }

    public getFieldValue<TValue = any>(path: string) {
        return get(this.values, path) as TValue;
    }

    public getFieldInitialValue<TValue = any>(path: string) {
        return get(this.initialValues, path) as TValue;
    }

    public setFieldTouched(path: string, touched: boolean, notifyForm?: boolean, notifyField?: boolean) {
        if (touched !== this.touched[path]) {
            this.touched = { ...this.touched, [path]: touched };

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: true,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            if (notifyField !== false) {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: false,
                        errors: false,
                        focused: false,
                        initialValue: false,
                        touched: true,
                        value: false
                    }
                );
            }

            return true;
        }

        return false;
    }

    public getFieldTouched(path: string) {
        return this.touched[path] ?? false;
    }

    public setFieldDirty(path: string, dirty: boolean, notifyForm?: boolean, notifyField?: boolean) {
        if (dirty !== this.dirty[path]) {
            this.dirty = { ...this.dirty, [path]: dirty };

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: true,
                    errors: false,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            if (notifyField !== false) {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: true,
                        errors: false,
                        focused: false,
                        initialValue: false,
                        touched: false,
                        value: false
                    }
                );
            }

            return true;
        }

        return false;
    }

    public getFieldDirty(path: string) {
        return this.dirty[path] ?? false;
    }

    public setFieldFocused(path: string, focused: boolean, notifyForm?: boolean, notifyField?: boolean, notifyPreviousFields?: boolean) {
        if (focused !== this.focused[path]) {
            const previouslyFocused = Object.keys(this.focused).filter(key => this.focused[key] === true && key !== path);
            let validate = false;
            let touched = false;
            let previousFieldsTouched = false;

            if (!focused) {
                if (this.focused[path]) {
                    touched = this.setFieldTouched(path, true, false, false);

                    validate = true;
                }

                this.focused = {};
            }
            else {
                this.focused = { [path]: focused };
            }

            if (notifyField !== false) {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: false,
                        errors: false,
                        focused: true,
                        initialValue: false,
                        touched: touched,
                        value: false
                    }
                );
            }

            if (previouslyFocused.length > 0) {
                previouslyFocused.forEach(previousFocus => {
                    const previousTouched = this.setFieldTouched(previousFocus, true, false, false);

                    if (notifyPreviousFields !== false) {
                        this.notifyFieldChange(
                            previousFocus,
                            {
                                dirty: false,
                                errors: false,
                                focused: true,
                                initialValue: false,
                                touched: previousTouched,
                                value: false
                            }
                        );
                    }

                    previousFieldsTouched = previousFieldsTouched || previousTouched;
                });

                validate = true;
            }

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: false,
                    fields: false,
                    focused: true,
                    initialValues: false,
                    touched: touched || previousFieldsTouched,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            if (this.validateOn === 'onBlur' && validate) {
                this.validate();
            }

            return true;
        }

        return false;
    }

    public getFieldFocused(path: string) {
        return this.focused[path] ?? false;
    }

    public setFieldErrors(path: string, errors: TError[] | null, notifyForm?: boolean, notifyField?: boolean) {
        if (errors !== this.errors[path]) {
            this.errors = { ...this.errors, [path]: errors ?? [] };

            if (notifyForm !== false) {
                this.notifyFormChange({
                    dirty: false,
                    errors: true,
                    fields: false,
                    focused: false,
                    initialValues: false,
                    touched: false,
                    values: false,
                    submitting: false,
                    validating: false
                });
            }

            if (notifyField !== false) {
                this.notifyFieldChange(
                    path,
                    {
                        dirty: false,
                        errors: true,
                        focused: false,
                        initialValue: false,
                        touched: false,
                        value: false
                    }
                );
            }

            return true;
        }

        return false;
    }

    public getFieldErrors(path: string) {
        return this.errors[path] ?? [];
    }

    public notifyFormChange(changes: IFormSubscription) {
        const listeners = this.formListeners.filter(l => this.requiresFormUpdate(changes, l.subscription));

        if (listeners.length === 0) {
            return;
        }

        const values = this.getFormValues();
        const errors = this.getFormErrors();
        const focused = this.getFormFocused();
        const initialValues = this.getFormInitialValues();
        const touched = this.getFormTouched();
        const dirty = this.getFormDirty();
        const submitting = this.getFormSubmitting();
        const validating = this.getFormValidating();
        const fields = this.getFields();

        listeners.forEach(l => {
            l.subscriber({
                values,
                dirty,
                errors,
                fields,
                focused,
                initialValues,
                touched,
                submitting,
                validating
            });
        });
    }

    public notifyFieldChange(path: string, changes: IFieldSubscription) {
        const listeners = this.fieldListeners.filter(l => l.path === path && this.requiresFieldUpdate(changes, l.subscription));

        if (listeners.length === 0) {
            return;
        }

        const value = this.getFieldValue(path);
        const errors = this.getFieldErrors(path);
        const focused = this.getFieldFocused(path);
        const initialValue = this.getFieldInitialValue(path);
        const touched = this.getFieldTouched(path);
        const dirty = this.getFieldDirty(path);

        listeners.forEach(l => {
            l.subscriber({
                value,
                dirty,
                errors,
                focused,
                initialValue,
                touched
            });
        });
    }

    public subscribeToForm(subscriber: IFormSubscriber<TValues, TError>, subscription?: IFormSubscription | null): IUnsubscribe {
        const listener: IFormListener<TValues, TError> = {
            subscriber,
            subscription: subscription ?? {
                dirty: true,
                errors: true,
                fields: true,
                focused: true,
                initialValues: true,
                touched: true,
                values: true,
                submitting: true,
                validating: true
            }
        }

        this.formListeners.push(listener);

        return () => {
            this.formListeners = this.formListeners.filter(l => l !== listener);
        };
    }

    public subscribeToField<TValue = any>(path: string, subscriber: IFieldSubscriber<TValue, TError>, subscription?: IFieldSubscription | null): IUnsubscribe {
        const listener: IFieldListener<TValue, TError> = {
            path,
            subscriber,
            subscription: subscription ?? {
                dirty: true,
                errors: true,
                focused: true,
                initialValue: true,
                touched: true,
                value: true
            }
        }

        this.fieldListeners.push(listener);

        return () => {
            this.fieldListeners = this.fieldListeners.filter(l => l !== listener);
        };
    }

    public async validate() {
        this.setFormValidating(true, true);

        if (this.onValidate) {
            const errors = await this.onValidate({
                values: this.getFormValues(),
                dirty: this.getFormDirty(),
                touched: this.getFormTouched(),
                fields: this.getFields(),
                errors: this.getFormErrors(),
                focused: this.getFormFocused(),
                initialValues: this.getFormInitialValues(),
                submitting: this.getFormSubmitting(),
                validating: this.getFormValidating()
            });

            this.setFormErrors(errors);

            this.setFormValidating(false, true);

            return;
        }

        this.setFormErrors({});

        this.setFormValidating(false, true);
    }

    private setAllTouched() {
        let touchedUpdate = false;

        this.fields.forEach(path => {
            const updated = this.setFieldTouched(path, true, false, true);

            touchedUpdate = touchedUpdate || updated;
        });

        if (touchedUpdate) {
            this.notifyFormChange({
                dirty: false,
                errors: false,
                fields: false,
                focused: false,
                initialValues: false,
                touched: true,
                values: false,
                submitting: false,
                validating: false
            })
        }
    }

    public getActions() {
        return {
            getValues: this.getFormValues,
            getTouched: this.getFormTouched,
            getDirty: this.getFormDirty,
            getFocused: this.getFormFocused,
            getErrors: this.getFormErrors,
            getInitialValues: this.getFormInitialValues,
            getSubmitting: this.getFormSubmitting,
            getValidating: this.getFormValidating,
            setValues: this.setFormValues,
            setTouched: this.setFormTouched,
            setDirty: this.setFormDirty,
            setFocused: this.setFormFocused,
            setErrors: this.setFormErrors,
            setValidating: this.setFormValidating,
            setSubmitting: this.setFormSubmitting,
            getFieldValue: this.getFieldValue,
            getFieldTouched: this.getFieldTouched,
            getFieldDirty: this.getFieldDirty,
            getFieldFocused: this.getFieldFocused,
            getFieldErrors: this.getFieldErrors,
            getFieldInitialValue: this.getFieldInitialValue,
            setFieldValue: this.setFieldValue,
            setFieldTouched: this.setFieldTouched,
            setFieldDirty: this.setFieldDirty,
            setFieldFocused: this.setFieldFocused,
            setFieldErrors: this.setFieldErrors,
            reset: this.reset,
            submit: this.submit,
            validate: this.validate,
            subscribe: this.subscribeToForm,
            subscribeToField: this.subscribeToField,
            getFields: this.getFields,
            registerField: this.registerField,
            unregisterField: this.unregisterField
        };
    }

    public async submit() {
        if (this.getFormSubmitting()) {
            return;
        }

        this.setFormSubmitting(true, true);

        let continueSubmit = true;
        let validationFailure = false;

        try {
            await this.validate();

            this.setAllTouched();

            if (this.allowSubmit) {
                continueSubmit = await this.allowSubmit({
                    values: this.getFormValues(),
                    dirty: this.getFormDirty(),
                    touched: this.getFormTouched(),
                    fields: this.getFields(),
                    errors: this.getFormErrors(),
                    focused: this.getFormFocused(),
                    initialValues: this.getFormInitialValues(),
                    submitting: this.getFormSubmitting(),
                    validating: this.getFormValidating()
                }, this.getActions());
            }
            else {
                continueSubmit = !this.hasErrors();
            }
        }
        catch (error) {
            continueSubmit = false;
            validationFailure = true;

            if (isDevelopment) {
                console.error(error)
            }
        }

        if (!continueSubmit) {
            this.setFormSubmitting(false, true);



            if (this.onSubmitValidationFailed) {
                try {
                    const result = await this.onSubmitValidationFailed({
                        values: this.getFormValues(),
                        dirty: this.getFormDirty(),
                        touched: this.getFormTouched(),
                        fields: this.getFields(),
                        errors: this.getFormErrors(),
                        focused: this.getFormFocused(),
                        initialValues: this.getFormInitialValues(),
                        submitting: this.getFormSubmitting(),
                        validating: this.getFormValidating()
                    }, validationFailure);

                    if (result) {
                        this.setFormErrors(result);
                    }
                }
                catch (error) {

                    if (isDevelopment) {
                        console.error(error)
                    }

                }
            }

            return;
        }

        if (this.onSubmit) {
            try {
                const result = await this.onSubmit({
                    values: this.getFormValues(),
                    dirty: this.getFormDirty(),
                    touched: this.getFormTouched(),
                    fields: this.getFields(),
                    errors: this.getFormErrors(),
                    focused: this.getFormFocused(),
                    initialValues: this.getFormInitialValues(),
                    submitting: this.getFormSubmitting(),
                    validating: this.getFormValidating()
                });

                if (result) {
                    this.setFormErrors(result);
                }
            }
            catch (error) {

                if (isDevelopment) {
                    console.error(error)
                }

                if (this.onSubmitFailed) {
                    try {
                        const result = await this.onSubmitFailed({
                            values: this.getFormValues(),
                            dirty: this.getFormDirty(),
                            touched: this.getFormTouched(),
                            fields: this.getFields(),
                            errors: this.getFormErrors(),
                            focused: this.getFormFocused(),
                            initialValues: this.getFormInitialValues(),
                            submitting: this.getFormSubmitting(),
                            validating: this.getFormValidating()
                        });

                        if (result) {
                            this.setFormErrors(result);
                        }
                    }
                    catch (error) {
                        if (isDevelopment) {
                            console.error(error)
                        }
                    }
                }
            }
        }

        this.setFormSubmitting(false, true);
    }

    private requiresFieldUpdate(changes: IFieldSubscription, subscription: IFieldSubscription) {
        return (changes.dirty && subscription.dirty) ||
            (changes.errors && subscription.errors) ||
            (changes.focused && subscription.focused) ||
            (changes.initialValue && subscription.initialValue) ||
            (changes.touched && subscription.touched) ||
            (changes.value && subscription.value);
    }

    private requiresFormUpdate(changes: IFormSubscription, subscription: IFormSubscription) {
        return (changes.dirty && subscription.dirty) ||
            (changes.errors && subscription.errors) ||
            (changes.focused && subscription.focused) ||
            (changes.initialValues && subscription.initialValues) ||
            (changes.touched && subscription.touched) ||
            (changes.values && subscription.values) ||
            (changes.submitting && subscription.submitting) ||
            (changes.validating && subscription.validating) ||
            (changes.fields && subscription.fields);
    }

    private updatedStatePaths(previous: Record<string, any>, next: Record<string, any>) {
        const previousKeys: string[] = previous ? Object.keys(previous) : [];
        const nextKeys: string[] = next ? Object.keys(next) : [];

        const changedPaths: string[] = previousKeys
            .filter(previousKey => !nextKeys.includes(previousKey) || previous[previousKey] !== next[previousKey])
            .concat(nextKeys.filter(nextKey => !previousKeys.includes(nextKey)));

        return changedPaths;
    }

    private updateValuePaths(previous: TValues, next: TValues) {
        const previousPaths: string[] = previous ? this.getValuePaths(previous) : [];
        const nextPaths: string[] = next ? this.getValuePaths(next) : [];

        const changedPaths: string[] = previousPaths
            .filter(previousPath => !nextPaths.includes(previousPath) || get(previous, previousPath) !== get(next, previousPath))
            .concat(nextPaths.filter(nextKey => !previousPaths.includes(nextKey)));

        return changedPaths;
    }

    private getValuePaths(values: TValues) {
        return getDeepKeys(values);
    }

    private hasErrors() {
        const errorPaths = Object.keys(this.errors);

        return errorPaths.some(path => this.errors[path]?.length > 0);
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Form;