/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';

import { IFormSubscription, IFormState } from '../Form';
import useFormActions from '../hooks/useFormActions';
import ConditionContext from '../contexts/ConditionContext';
import Observable from '../../utilities/Observable';
import { makeStyles } from '@material-ui/core';
import useFieldState from '../hooks/useFieldState';
import FieldContext, { IFieldContext } from '../contexts/FieldContext';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the Form component.
 */
export interface IConditionProps<TValues extends object = any, TError = any> {
    condition: (state: IFormState<TValues, TError>, parentPropertyPath?: string | null) => boolean;
    subscription?: Partial<IFormSubscription>;
    mode?: 'Enable' | 'Show';
    children?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    hide: {
        display: 'none'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const Condition = <TValues extends object = any, TError = any>({
    condition,
    subscription,
    mode,
    children
}: IConditionProps<TValues, TError>) => {
    const formActions = useFormActions<TValues, TError>();

    const parentContext = React.useContext(ConditionContext);

    const fieldContext: IFieldContext<any, TError> | null = React.useContext(FieldContext);

    const parentFieldName = fieldContext?.name;

    const [conditionMet, setConditionMet] = React.useState<boolean>(() => {
        if (parentContext?.conditionMet === false) {
            return false;
        }

        return condition({
            values: formActions.getValues(),
            dirty: formActions.getDirty(),
            errors: formActions.getErrors(),
            fields: formActions.getFields(),
            focused: formActions.getFocused(),
            initialValues: formActions.getInitialValues(),
            touched: formActions.getTouched(),
            validating: formActions.getValidating(),
            submitting: formActions.getSubmitting()
        }, parentFieldName);
    });

    React.useEffect(() => {
        let met = false;

        if (parentContext?.conditionMet !== false) {
            met = condition({
                values: formActions.getValues(),
                dirty: formActions.getDirty(),
                fields: formActions.getFields(),
                errors: formActions.getErrors(),
                focused: formActions.getFocused(),
                initialValues: formActions.getInitialValues(),
                touched: formActions.getTouched(),
                validating: formActions.getValidating(),
                submitting: formActions.getSubmitting()
            }, parentFieldName);
        }

        setConditionMet(met);
    }, [formActions, setConditionMet, condition, parentContext?.conditionMet, parentFieldName]);


    React.useEffect(() => {
        const unsubscribe = formActions.subscribe(
            (formState) => {

                let met = false;

                if (parentContext?.conditionMet !== false) {
                    met = condition(formState, parentFieldName);
                }

                setConditionMet(met);
            },
            subscription as IFormSubscription | undefined
        );

        return () => {
            unsubscribe();
        }
    }, [formActions.subscribe, subscription, condition, parentContext?.conditionMet, parentFieldName]);

    const observableNames = React.useMemo(() => {
        return new Observable<string[]>([])
    }, []);

    const addName = React.useCallback((name: string) => {
        parentContext?.addName(name);

        const currentNames = observableNames.get();

        if (currentNames.includes(name)) {
            return;
        }

        observableNames.set([...currentNames, name]);
    }, [observableNames.set, observableNames.get, parentContext?.addName]);

    const removeName = React.useCallback((name: string) => {
        parentContext?.removeName(name);

        const currentNames = observableNames.get();

        if (!currentNames.includes(name)) {
            return;
        }

        observableNames.set(currentNames.filter(n => n !== name));
    }, [observableNames.set, observableNames.get, parentContext?.removeName]);

    const [names, setNames] = React.useState(observableNames.get());

    React.useEffect(() => {
        setNames(observableNames.get());

        const unsubscribe = observableNames.subscribe((newNames) => {
            setNames(newNames);
        });

        return () => {
            unsubscribe();
        }
    }, [observableNames?.subscribe, observableNames?.get, setNames]);

    const context = React.useMemo(() => {
        if (parentContext && parentContext?.conditionMet === false) {
            return parentContext;
        }

        return {
            names,
            conditionMet,
            addName,
            removeName
        }
    }, [names, addName, removeName, conditionMet, parentContext]);

    React.useEffect(() => {
        if (!conditionMet) {
            names?.forEach(name => {
                formActions?.setFieldValue(name, undefined, true, true, true);
            })
        }
    }, [conditionMet, names, formActions?.setFieldValue]);

    const classes = useStyles();

    return (
        <ConditionContext.Provider value={context}>
            {
                !context.conditionMet && mode === 'Show' ?
                    <>
                        <span className={classes.hide}>
                            {children}
                        </span>
                        <span className={classes.hide}>
                        </span>
                    </>:
                    children
            }
        </ConditionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default Condition;