/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the class used to set up observable values.
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Import - External
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Functions
 * --------------------------------------------------------------------------------
 */


type Listener<T> = (val: T) => void;
type Unsubscriber = () => void;

class Observable<T> {
    private _listeners: Listener<T>[] = [];
    private _val: T;

    constructor(val: T) {
        this._val = val;

        this.get = this.get.bind(this);
        this.set = this.set.bind(this);
        this.subscribe = this.subscribe.bind(this);
    }

    public get(): T {
        return this._val;
    }

    public set(val: T) {
        if (this._val !== val) {
            this._val = val;
            this._listeners.forEach(l => {
                l(val)
            });
        }
    }

    public subscribe(listener: Listener<T>): Unsubscriber {
        this._listeners.push(listener);
        return () => {
            this._listeners = this._listeners.filter(l => l !== listener);
        };
    }
}


/*
 * --------------------------------------------------------------------------------
 * Default Export
 * --------------------------------------------------------------------------------
 */

export default Observable;
