/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt as deleteIcon } from '@fortawesome/pro-duotone-svg-icons/faTrashAlt';
import { faEdit as updateIcon } from '@fortawesome/pro-duotone-svg-icons/faEdit';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import {
    ICollapsibleTableProps,
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    TrialContextSelector,
    IFormLabel
} from '@ngt/opms';
import { Column } from 'material-table';
import { Link, useParams, useHistory } from 'react-router-dom';
import { RequestState } from '@ngt/request-utilities';
import { makeStyles, Theme, Button, IconButton, PropTypes, Grid } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useScreeningLogs } from '../hooks/useScreeningLogs';
import { ScreeningLog } from '../api/screeningLog';
import { ScreeningLogDialogMode } from './ScreeningLogDialog';
import { ScreeningLogDialog } from '..';
import ScreeningLogResolver from './ScreeningLogResolver';



/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;

interface IScreeningLogsCollapsibleTableProps<TScreeningLog extends ScreeningLog = ScreeningLog> extends OmitData<ICollapsibleTableProps<TScreeningLog>> {
    data?: TScreeningLog[];
    columns: Array<Column<TScreeningLog>>;
    title?: string;
    loading?: boolean;
    formFieldsComponent?: any;
    formLabels?: IFormLabel[];
    downloadButtons?: JSX.Element;

}

interface IScreeningLogButtonProps {
    icon: IconProp;
    color?: PropTypes.Color;
    onClickFn: () => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    createGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-Start',
        alignItems: 'flex-end'
    },
    createGridBtn: {
        '&:first-child': {
            marginRight: theme.spacing(1)
        },
        width: '100%'
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mr1: {
        marginRight: theme.spacing(1)
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3),
    },
    textWrap: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningLogButton: React.FunctionComponent<IScreeningLogButtonProps> = ({
    icon,
    color,
    onClickFn
}) => {
    const classes = useStyles();

    return <>
        <IconButton
            color={color ?? "primary"}
            onClick={onClickFn}
            className={classes.button}
            size="small"
        >
            <FontAwesomeIcon icon={icon} fixedWidth />
        </IconButton>
    </>
};

const useScreeningLogsColumns = <TScreeningLog extends ScreeningLog = ScreeningLog>(
    columns: Array<Column<TScreeningLog>>,
    classes: Record<string, string>,
    handleUpdate: (id?: number) => void,
    handleDelete: (id?: number) => void,
    canUpdateScreeningLog: boolean
) => {

    const screeningLogColumns = React.useMemo(() => {
        const actionColumns: Array<Column<TScreeningLog>> = [];

        if (canUpdateScreeningLog) {
            actionColumns.push({
                field: 'id',
                title: '',
                render: screeningLog => {
                    return (
                        <div className={classes.textWrap}>
                            {
                                <ScreeningLogButton
                                    icon={updateIcon}
                                    onClickFn={() => handleUpdate(screeningLog.id)}
                                />
                            }
                            {
                                <ScreeningLogButton
                                    icon={deleteIcon}
                                    color="secondary"
                                    onClickFn={() => handleDelete(screeningLog.id)}
                                />
                            }
                        </div>
                    )
                },
                width: 100,
                sorting: false,
                align: 'center'
            });
        }
        
        return columns.concat(actionColumns);
    }, [columns, classes, handleUpdate, handleDelete])

    return screeningLogColumns;
};

const permissions: Permission[] = [
    Permission.ScreeningLogView,
    Permission.ScreeningLogUpdate,
];

const ScreeningLogsCollapsibleTable = <TScreeningLog extends ScreeningLog = ScreeningLog> ({
    data,
    loading,
    title,
    columns,
    formFieldsComponent,
    formLabels,
    downloadButtons,
    ...tableProps
}: IScreeningLogsCollapsibleTableProps<TScreeningLog>) => {

    const classes = useStyles();

    const history = useHistory();

    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [[canViewScreeningLog, canUpdateScreeningLog], permissionLoadState, actions] = usePermissionsByIds(permissions, null, null, institution?.id, null, institutionCode ? true : false);

    React.useEffect(() => {
        if (contextInstitutions?.loadState.state === RequestState.Success &&
            institutions && institutions.length === 1)

            if (institutions && institutions.length === 1) {
                history.replace(`/screeninglog/${institutions[0].code}`)
            }
    }, [contextInstitutions?.loadState, institutions, institution])

    React.useEffect(() => {
        if (permissionLoadState.state !== RequestState.Pending &&
            permissionLoadState.state !== RequestState.None) {
            if (!canViewScreeningLog) {
                history.replace(`/error/403`);
            }
        }
    }, [canViewScreeningLog, permissionLoadState, history]);

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history?.push(`/screeninglog/${newInstitutionCode}`);
        }
        else {
            history?.push(`/screeninglog`);
        }

        return;
    }, [history]);

    const [screeningLogs, screeningLogsLoadState, screeningLogsActions] = useScreeningLogs<TScreeningLog>(institution?.id, data === undefined);

    const screeningLogsToUse = React.useMemo(() => {
        if (!screeningLogs && !data) {
            return [];
        }

        return (data ?? screeningLogs ?? []);
    }, [screeningLogs, data]);

    const screeningLogsLoadingToUse = React.useMemo(() => {
        return data === undefined && loading === undefined ?
            screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending :
            loading ?? false;
    }, [data, loading, screeningLogsLoadState]);

    const titleToUse = title ?? 'Screening Log';

    const [dialogMode, setDialogMode] = React.useState(0);

    const [screeningLogId, setScreeningLogId] = React.useState<number|undefined>(undefined);

    const handleAdd = () => {
        setDialogMode(ScreeningLogDialogMode.create);
    };

    const handleUpdate = (id?: number) => {
        if (!id) {
            throw new Error('Screening log ID was not provided.');
        }
        setScreeningLogId(id);
        setDialogMode(ScreeningLogDialogMode.update);
    };

    const handleDelete = (id?: number) => {
        if (!id) {
            throw new Error('Screening log ID was not provided.');
        }

        setScreeningLogId(id);
        setDialogMode(ScreeningLogDialogMode.delete);
    };

    const handleCancel = () => {
        setScreeningLogId(undefined);
        setDialogMode(ScreeningLogDialogMode.cancel)
    };

    const downloadButtonsToUse = React.useMemo(() => {
        return downloadButtons ?
            downloadButtons :
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                className={classes.createGridBtn}
                href={`/print/screeninglogs/` + (institution?.code ?? 'all-institutions')}
            >
                Download
            </Button>;
    }, [downloadButtons]);

    const columnsToUse = useScreeningLogsColumns(columns, classes, handleUpdate, handleDelete, canUpdateScreeningLog);

    return (
        <>
            {
                <Grid item xs={12} className={classes.mt3}>
                    <TrialContextSelector
                        onChange={onSelect}
                        allowAllMasterGroups={false}
                        allowAllCollaboratingGroups={false}
                        allowAllCountries={false}
                        allowAllInstitutions={true}
                        hideMasterGroups={true}
                        hideCollaboratingGroups={true}
                        hideCountries={true}
                        masterGroupCode={null}
                        collaboratingGroupCode={null}
                        countryCode={null}
                        institutionCode={institution?.code ?? ALL_INSTITUTIONS_CODE}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.createGrid}
                        >
                            {
                                canUpdateScreeningLog && <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={handleAdd}
                                    className={classes.createGridBtn}
                                    disabled={!institution}
                                >
                                    Add Screening Log Entry
                                </Button>
                            }
                            {
                                canViewScreeningLog &&
                                downloadButtonsToUse
                            }
                        </Grid>
                    </TrialContextSelector>
                </Grid>
            }
            {
                <CollapsibleTable
                    title={titleToUse}
                    entityName="Screening Logs"
                    loading={screeningLogsLoadingToUse}
                    data={screeningLogsToUse ?? []}
                    columns={columnsToUse}
                    {...tableProps}
                />
            }
            {
                (!!institution?.id || !!screeningLogId) && <ScreeningLogResolver id={screeningLogId} institutionId={institution?.id}>
                    <ScreeningLogDialog
                        mode={dialogMode}
                        formCancel={handleCancel}
                        formFieldGroup={formFieldsComponent}
                        formLabels={formLabels}
                        />
                </ScreeningLogResolver>
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningLogsCollapsibleTable;

