/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for sae routes.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type properties and get access to routes. 
 */
import { RouteProps } from 'react-router';


/*
 * Used to get access to application routing based on paths.
 */
import { Route, useParams, match } from 'react-router-dom';

/**
 * Used to get OPMS settings
 */
import { OnlinePatientManagementContext, ALL_INSTITUTIONS_CODE, AutoKeyRoute } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to load events into the EventByCodeContext
 */
import SaeByPatientStudyNumberResolver from './SaeByPatientStudyNumberResolver';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the SaeByStudyNumberRoute component.
 */
export interface ISaeByStudyNumberRouteProps extends RouteProps {
    institutionCode?: string;
    patientStudyNumber?: string;
    saeNumber?: number;

    computedMatch?: match<Record<string, string>>;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the sae review system.
 * @param param0 component properties.
 */

const SaeByPatientStudyNumberRoute: React.FunctionComponent<ISaeByStudyNumberRouteProps> = ({
    institutionCode,
    patientStudyNumber,
    saeNumber,
    resolveBeforeLoad,
    children,
    ...routeProps
}) => {

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext)
    const params = routeProps.computedMatch?.params ?? useParams<Record<string, string>>();
    const paramInstitutionCode = params[onlinePatientManagement.routeParameters.institutionCode];
    const paramPatientStudyNumber = params[onlinePatientManagement.routeParameters.patientStudyNumber];
    const paramSaeNumber = params['saeNumber'] as unknown as number;

    const institutionCodeToUse = paramInstitutionCode ?? institutionCode ?? null;

    return <AutoKeyRoute {...routeProps}>
        <SaeByPatientStudyNumberResolver
            institutionCode={institutionCodeToUse === ALL_INSTITUTIONS_CODE ? null : institutionCodeToUse}
            patientStudyNumber={paramPatientStudyNumber ?? patientStudyNumber}
            saeNumber={paramSaeNumber ?? saeNumber}
            resolveBeforeLoad={resolveBeforeLoad}
        >
            {children}
        </SaeByPatientStudyNumberResolver>
    </AutoKeyRoute>
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeByPatientStudyNumberRoute;

