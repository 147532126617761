/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook used to initialise a form..
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import Form, { IFormOptions, IFormActions } from '../Form';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseFormOptions<TValues extends object = any, TError = any> extends IFormOptions<TValues, TError> {
}

export interface IUseFormReturn<TValues extends object = any, TError = any> extends IFormActions<TValues, TError> {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useForm = <TValues extends object = any, TError = any>(options?: IUseFormOptions<TValues, TError>) => {
    const form = React.useMemo(() => {
        return new Form<TValues, TError>(options);
    }, []);

    React.useEffect(() => {
        form.setAllowSubmit(options?.allowSubmit);
    }, [form.setAllowSubmit, options?.allowSubmit])

    React.useEffect(() => {
        form.setInitialValues(options?.initialValues);
    }, [form.setInitialValues, options?.initialValues])

    React.useEffect(() => {
        form.setOnSubmit(options?.onSubmit);
    }, [form.setOnSubmit, options?.onSubmit])

    React.useEffect(() => {
        form.setOnValidate(options?.onValidate);
    }, [form.setOnValidate, options?.onValidate])

    React.useEffect(() => {
        form.setValidateOn(options?.validateOn);
    }, [form.setValidateOn, options?.validateOn])

    const formMethods: IUseFormReturn<TValues, TError> = React.useMemo(() => {
        return form.getActions();
    }, [form]);

    return formMethods;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useForm;