/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected event.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass event context to the rest of the app.
 */
import EventByCodeContext from '../../../contexts/data/EventContext';


/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';
import useEventDefinitionByCode from '../../../hooks/configuration/useEventDefinitionByCode';
import EventDefinitionContext from '../../../contexts/configuration/EventDefinitionContext';
import useFormDefinitionByCode from '../../../hooks/configuration/useFormDefinitionByCode';
import FormDefinitionContext from '../../../contexts/configuration/FormDefinitionContext';
import usePatientByStudyNumber from '../../../hooks/data/usePatientByStudyNumber';
import PatientContext from '../../../contexts/data/PatientContext';
import useInstitutionByCode from '../../../hooks/data/useInstitutionByCode';
import useInstitutionById from '../../../hooks/data/useInstitutionById';
import InstitutionContext from '../../../contexts/data/InstitutionContext';
import useCountryByCode from '../../../hooks/data/useCountryByCode';
import useCountryById from '../../../hooks/data/useCountryById';
import CountryContext from '../../../contexts/data/CountryContext';
import useCollaboratingGroupByCode from '../../../hooks/data/useCollaboratingGroupByCode';
import useCollaboratingGroupById from '../../../hooks/data/useCollaboratingGroupById';
import CollaboratingGroupContext from '../../../contexts/data/CollaboratingGroupContext';
import useMasterGroupByCode from '../../../hooks/data/useMasterGroupByCode';
import useMasterGroupById from '../../../hooks/data/useMasterGroupById';
import MasterGroupContext from '../../../contexts/data/MasterGroupContext';
import useEventByCodes from '../../../hooks/data/useEventByCodes';
import EventContext from '../../../contexts/data/EventContext';
import useFormByCodes from '../../../hooks/data/useFormByCodes';
import FormContext from '../../../contexts/data/FormContext';
import useLookupsByFormDefinitionId from '../../../hooks/utility/useLookupsByFormDefinitionId';
import LookupsContext from '../../../contexts/utility/LookupsContext';
import useEventDefinitions from '../../../hooks/configuration/useEventDefinitions';
import useFormDefinitions from '../../../hooks/configuration/useFormDefinitions';
import EventDefinitionsContext from '../../../contexts/configuration/EventDefinitionsContext';
import FormDefinitionsContext from '../../../contexts/configuration/FormDefinitionsContext';
import usePatientValidationByStudyNumber from '../../../hooks/utility/usePatientValidationByStudyNumber';
import PatientValidationContext from '../../../contexts/utility/PatientValidationContext';
import PatientStateContext from '../../../contexts/configuration/PatientStateContext';
import usePatientStateById from '../../../hooks/configuration/usePatientStateById';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPatientSummaryByStudyNumberResolverProps {
    masterGroupCode?: string | null;
    collaboratingGroupCode?: string | null;
    countryCode?: string | null;
    institutionCode?: string | null;
    patientStudyNumber?: string | null;
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientSummaryByStudyNumberResolver: React.FunctionComponent<IPatientSummaryByStudyNumberResolverProps> = ({
    masterGroupCode,
    collaboratingGroupCode,
    countryCode,
    institutionCode,
    patientStudyNumber,
    resolveBeforeLoad,
    children
}) => {

    if (!patientStudyNumber) {
        throw new Error('Route Invalid: Patient study number was not provided.');
    }

    // Event Definition Loading.
    const [eventDefinitions, eventDefinitionsLoadState, eventDefinitionsActions] = useEventDefinitions(true);

    const eventDefinitionsContext = React.useMemo(() => {
        return {
            eventDefinitions,
            loadState: eventDefinitionsLoadState,
            actions: eventDefinitionsActions
        };
    }, [eventDefinitions, eventDefinitionsLoadState, eventDefinitionsActions]);

    // Form Definition Loading.
    const [formDefinitions, formDefinitionsLoadState, formDefinitionsActions] = useFormDefinitions(true);

    const formDefinitionsContext = React.useMemo(() => {
        return {
            formDefinitions,
            loadState: formDefinitionsLoadState,
            actions: formDefinitionsActions
        };
    }, [formDefinitions, formDefinitionsLoadState, formDefinitionsActions]);

    let conditionalContexts: React.ReactNode = children;


    // Patient Loading
    const [patient, patientLoadState, patientSaveState, patientActions] = usePatientByStudyNumber(patientStudyNumber, true);

    const patientContext = React.useMemo(() => {
        return {
            patient,
            loadState: patientLoadState,
            saveState: patientSaveState,
            actions: patientActions
        };
    }, [patient, patientLoadState, patientActions]);


    const [patientValidation, patientValidationLoadState, patientValidationActions] = usePatientValidationByStudyNumber(patientStudyNumber, true);

    const patientValidationContext = React.useMemo(() => {
        return {
            validation: patientValidation,
            loadState: patientValidationLoadState,
            actions: patientValidationActions
        };
    }, [patientValidation, patientValidationLoadState, patientValidationActions]);

    //Load patient state 

    const loadPatientState = !!patient?.patientStateId;

    const [patientState, patientStateLoadState, patientStateActions] = usePatientStateById(patient?.patientStateId as any, loadPatientState);

    const patientStateContext = React.useMemo(() => {
        return {
            patientState,
            loadState: patientStateLoadState,
            actions: patientStateActions
        };
    }, [patientState, patientStateLoadState, patientStateActions]);

    if (loadPatientState) {
        conditionalContexts = (
            <PatientStateContext.Provider value={patientStateContext}>
                {conditionalContexts}
            </PatientStateContext.Provider>
        );
    }

    // Load institution
    const loadInstitutionByCode = !!institutionCode;
    const loadInstitutionById = !!patientContext.patient?.institutionId && !institutionCode;

    const [institutionByCode, institutionByCodeLoadState, institutionByCodeActions] = useInstitutionByCode(institutionCode as any, loadInstitutionByCode);
    const [institutionById, institutionByIdLoadState, institutionByIdActions] = useInstitutionById(patientContext.patient?.institutionId as any, loadInstitutionById);

    const institutionContext = React.useMemo(() => {
        return {
            institution: loadInstitutionByCode ?
                institutionByCode :
                institutionById,
            loadState: loadInstitutionByCode ?
                institutionByCodeLoadState :
                institutionByIdLoadState,
            actions: loadInstitutionByCode ?
                institutionByCodeActions :
                institutionByIdActions
        };
    }, [institutionByCode, institutionByCodeLoadState, institutionByCodeActions, institutionById, institutionByIdLoadState, institutionByIdActions, loadInstitutionByCode]);

    // load Country
    const loadCountryByCode = !!countryCode;
    const loadCountryById = !!institutionContext.institution?.countryId && !countryCode;

    const [countryByCode, countryByCodeLoadState, countryByCodeActions] = useCountryByCode(countryCode as any, loadCountryByCode);
    const [countryById, countryByIdLoadState, countryByIdActions] = useCountryById(institutionContext.institution?.countryId as any, loadCountryById);

    const countryContext = React.useMemo(() => {
        return {
            country: loadCountryByCode ?
                countryByCode :
                countryById,
            loadState: loadCountryByCode ?
                countryByCodeLoadState :
                countryByIdLoadState,
            actions: loadCountryByCode ?
                countryByCodeActions :
                countryByIdActions
        };
    }, [countryByCode, countryByCodeLoadState, countryByCodeActions, countryById, countryByIdLoadState, countryByIdActions, loadCountryByCode]);

    if (loadCountryById || loadCountryByCode) {
        conditionalContexts = (
            <CountryContext.Provider value={countryContext}>
                {conditionalContexts}
            </CountryContext.Provider>
        );
    }

    // load CollaboratingGroup
    const loadCollaboratingGroupByCode = !!collaboratingGroupCode;
    const loadCollaboratingGroupById = !!institutionContext.institution?.collaboratingGroupId && !collaboratingGroupCode;

    const [collaboratingGroupByCode, collaboratingGroupByCodeLoadState, collaboratingGroupByCodeActions] = useCollaboratingGroupByCode(collaboratingGroupCode as any, loadCollaboratingGroupByCode);
    const [collaboratingGroupById, collaboratingGroupByIdLoadState, collaboratingGroupByIdActions] = useCollaboratingGroupById(institutionContext.institution?.collaboratingGroupId as any, loadCollaboratingGroupById);

    const collaboratingGroupContext = React.useMemo(() => {
        return {
            collaboratingGroup: loadCollaboratingGroupByCode ?
                collaboratingGroupByCode :
                collaboratingGroupById,
            loadState: loadCollaboratingGroupByCode ?
                collaboratingGroupByCodeLoadState :
                collaboratingGroupByIdLoadState,
            actions: loadCollaboratingGroupByCode ?
                collaboratingGroupByCodeActions :
                collaboratingGroupByIdActions
        };
    }, [collaboratingGroupByCode, collaboratingGroupByCodeLoadState, collaboratingGroupByCodeActions, collaboratingGroupById, collaboratingGroupByIdLoadState, collaboratingGroupByIdActions, loadCollaboratingGroupByCode]);

    if (loadCollaboratingGroupById || loadCollaboratingGroupByCode) {
        conditionalContexts = (
            <CollaboratingGroupContext.Provider value={collaboratingGroupContext}>
                {conditionalContexts}
            </CollaboratingGroupContext.Provider>
        );
    }

    // load MasterGroup
    const loadMasterGroupByCode = !!masterGroupCode;
    const loadMasterGroupById = !!institutionContext.institution?.masterGroupId && !masterGroupCode;

    const [masterGroupByCode, masterGroupByCodeLoadState, masterGroupByCodeActions] = useMasterGroupByCode(masterGroupCode as any, loadMasterGroupByCode);
    const [masterGroupById, masterGroupByIdLoadState, masterGroupByIdActions] = useMasterGroupById(institutionContext.institution?.masterGroupId as any, loadMasterGroupById);

    const masterGroupContext = React.useMemo(() => {
        return {
            masterGroup: loadMasterGroupByCode ?
                masterGroupByCode :
                masterGroupById,
            loadState: loadMasterGroupByCode ?
                masterGroupByCodeLoadState :
                masterGroupByIdLoadState,
            actions: loadMasterGroupByCode ?
                masterGroupByCodeActions :
                masterGroupByIdActions
        };
    }, [masterGroupByCode, masterGroupByCodeLoadState, masterGroupByCodeActions, masterGroupById, masterGroupByIdLoadState, masterGroupByIdActions, loadMasterGroupByCode]);

    if (loadMasterGroupById || loadMasterGroupByCode) {
        conditionalContexts = (
            <MasterGroupContext.Provider value={masterGroupContext}>
                {conditionalContexts}
            </MasterGroupContext.Provider>
        );
    }
    


    const notLoading =
        eventDefinitionsContext.loadState.state !== RequestState.None &&
        eventDefinitionsContext.loadState.state !== RequestState.Pending &&
        formDefinitionsContext.loadState.state !== RequestState.None &&
        formDefinitionsContext.loadState.state !== RequestState.Pending &&
        patientContext.loadState.state !== RequestState.None &&
        patientContext.loadState.state !== RequestState.Pending &&
        patientValidationContext.loadState.state !== RequestState.None &&
        patientValidationContext.loadState.state !== RequestState.Pending &&
        institutionContext.loadState.state !== RequestState.None &&
        institutionContext.loadState.state !== RequestState.Pending &&
        ((!loadCountryById && !loadCountryByCode) ||
            (countryContext.loadState.state !== RequestState.None &&
            countryContext.loadState.state !== RequestState.Pending)) &&
        ((!loadCollaboratingGroupById && !loadCollaboratingGroupByCode) ||
            (collaboratingGroupContext.loadState.state !== RequestState.None &&
            collaboratingGroupContext.loadState.state !== RequestState.Pending)) &&
        ((!loadMasterGroupById && !loadMasterGroupByCode) ||
            (masterGroupContext.loadState.state !== RequestState.None &&
            masterGroupContext.loadState.state !== RequestState.Pending)) &&
        (!loadPatientState ||
            (patientStateContext.loadState.state !== RequestState.None &&
            patientStateContext.loadState.state !== RequestState.Pending));

    return (
        <EventDefinitionsContext.Provider value={eventDefinitionsContext}>
            <FormDefinitionsContext.Provider value={formDefinitionsContext}>
                <InstitutionContext.Provider value={institutionContext}>
                    <PatientContext.Provider value={patientContext}>
                        <PatientValidationContext.Provider value={patientValidationContext}>
                            {
                                !resolveBeforeLoad || notLoading ?
                                    conditionalContexts :
                                    <RouteLoading />
                            }
                        </PatientValidationContext.Provider>
                    </PatientContext.Provider>
                </InstitutionContext.Provider>
            </FormDefinitionsContext.Provider>
        </EventDefinitionsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientSummaryByStudyNumberResolver;