/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected event.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';


/*
 * Used to show loading view.
 */
import { RouteLoading, usePatientByStudyNumber, PatientContext, useInstitutionByCode, useInstitutionById, InstitutionContext } from '@ngt/opms';

import SaeContext from '../context/SaeContext';

import { useSaeByContext } from '../hooks/useSaeByContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ISaeByPatientStudyNumberResolverProps {
    institutionCode?: string | null;
    patientStudyNumber?: string | null;
    saeNumber?: number | null;
    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const SaeByPatientStudyNumberResolver: React.FunctionComponent<ISaeByPatientStudyNumberResolverProps> = ({
    institutionCode,
    patientStudyNumber,
    saeNumber,
    resolveBeforeLoad,
    children
}) => {

    let conditionalContexts: React.ReactNode = children;

    if (!patientStudyNumber) {
        throw new Error('Route Invalid: Patient study number was not provided.');
    }

    if (!saeNumber) {
        throw new Error('Route Invalid: SAE number was not provided.');
    }

    // Patient Loading
    const [patient, patientLoadState, patientSaveState, patientActions] = usePatientByStudyNumber(patientStudyNumber, true);

    const patientContext = React.useMemo(() => {
        return {
            patient,
            loadState: patientLoadState,
            saveState: patientSaveState,
            actions: patientActions
        };
    }, [patient, patientLoadState, patientActions]);

    // Load institution
    const loadInstitutionByCode = !!institutionCode;
    const loadInstitutionById = !!patientContext.patient?.institutionId && !institutionCode;

    const [institutionByCode, institutionByCodeLoadState, institutionByCodeActions] = useInstitutionByCode(institutionCode as any, loadInstitutionByCode);
    const [institutionById, institutionByIdLoadState, institutionByIdActions] = useInstitutionById(patientContext.patient?.institutionId as any, loadInstitutionById);

    const institutionContext = React.useMemo(() => {
        return {
            institution: loadInstitutionByCode ?
                institutionByCode :
                institutionById,
            loadState: loadInstitutionByCode ?
                institutionByCodeLoadState :
                institutionByIdLoadState,
            actions: loadInstitutionByCode ?
                institutionByCodeActions :
                institutionByIdActions
        };
    }, [institutionByCode, institutionByCodeLoadState, institutionByCodeActions, institutionById, institutionByIdLoadState, institutionByIdActions, loadInstitutionByCode]);

    // Load SAE
    const [sae, saeLoadState, saeActions] = useSaeByContext(patientStudyNumber, saeNumber, true);

    const saeContext = React.useMemo(() => {
        return {
            sae,
            loadState: saeLoadState,
            actions: saeActions
        };
    }, [sae, saeLoadState, saeActions]);




    const notLoading =
        patientContext.loadState.state !== RequestState.None &&
        patientContext.loadState.state !== RequestState.Pending &&
        institutionContext.loadState.state !== RequestState.None &&
        institutionContext.loadState.state !== RequestState.Pending

    return (
        <InstitutionContext.Provider value={institutionContext}>
            <PatientContext.Provider value={patientContext}>
                <SaeContext.Provider value={saeContext}>
                    {
                        !resolveBeforeLoad || notLoading ?
                            conditionalContexts :
                            <RouteLoading />
                    }
                </SaeContext.Provider>
            </PatientContext.Provider>
        </InstitutionContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeByPatientStudyNumberResolver;