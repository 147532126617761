/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected patient.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type request states.
 */
import { RequestState } from '@ngt/request-utilities';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * Used to pass patient context to the rest of the app.
 */
import PatientsContext from '../../../contexts/data/PatientsContext';

/*
 * Used to load collaborating groups
 */
import usePatientsByCodes from '../../../hooks/data/usePatientsByCodes';

/*
 * Used to show loading view.
 */
import RouteLoading from '../../route/RouteLoading';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPatientsByCodesResolverProps {
    masterGroupCode?: string | null;
    collaboratingGroupCode?: string | null;
    countryCode?: string | null;
    institutionCode?: string | null;
    patientStateIds?: number[] | null;

    resolveBeforeLoad?: boolean | null;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientsByCodesResolver: React.FunctionComponent<IPatientsByCodesResolverProps> = ({
    masterGroupCode,
    collaboratingGroupCode,
    countryCode,
    institutionCode,
    patientStateIds,
    resolveBeforeLoad,
    children
}) => {
    const [patients, loadState, actions] = usePatientsByCodes(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds, true);

    const context = React.useMemo(() => {
        return {
            patients,
            loadState,
            actions
        };
    }, [patients, loadState, actions]);

    return (
        <PatientsContext.Provider value={context}>
            {
                !resolveBeforeLoad || (loadState.state !== RequestState.None && loadState.state !== RequestState.Pending) ?
                    children :
                    <RouteLoading />
            }
        </PatientsContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientsByCodesResolver;