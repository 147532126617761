/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';
import FormErrorHandler from '../../FormErrorHandler';
import FormErrorDisplay, { ShowErrorFunction } from '../../FormErrorDisplay';
import { TableCell, TableRow } from '@material-ui/core';
import StaticGridContext from '../../../../contexts/field/StaticGridContext';
import StaticGridCell, { IStaticGridCellProps } from './StaticGridCell';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IStaticGridRowProps {
    cells: Array<IStaticGridCellProps>;
    noErrors?: boolean;
    showError?: ShowErrorFunction;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    valueRow: {
        borderBottom: (props: any) => !props.noErrors ? 'none' : '',

        '& td': {
            borderBottom: (props: any) => !props.noErrors ? 'none' : ''
        }
    },
    errorRow: {
        padding: theme.spacing(0),

        '& td': {
            padding: theme.spacing(0)
        }
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */


const StaticGridRow: React.FunctionComponent<IStaticGridRowProps> = ({
    cells,
    noErrors,
    showError
}) => {

    //const isGrouped = React.useContext(FieldGroupContext);

    const classes = useStyles({ noErrors });

    const context = React.useContext(StaticGridContext);

    const calculatedCells: Array<IStaticGridCellProps> = React.useMemo(() => {
        return context.map(column => {
            const cell = cells.find(c => column.columnName === c.columnName);

            if (cell) {
                return cell;
            }

            return {
                columnName: column.columnName,
                fieldName: null,
                content: null
            }
        });
    }, [context, cells])

    return (
        <>
            <FormErrorHandler
                forceNew
            >
                <TableRow
                        className={classes.valueRow}
                >
                    {
                        calculatedCells.map(cell => {
                            return (
                                <StaticGridCell
                                    key={cell.columnName}
                                    {...cell}
                                />
                            );
                        })
                    }
                </TableRow>
                {
                    !noErrors && (
                        <TableRow
                            className={classes.errorRow}
                        >
                            <TableCell colSpan={(context?.length ?? 0)}>
                                <FormErrorDisplay showError={showError} />
                            </TableCell>
                        </TableRow>
                    )
                }
            </FormErrorHandler>
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default StaticGridRow;
