/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently loaded sae.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used for typing request states.
 */
import { IRequestState } from '@ngt/request-utilities';

import { ResponseStatus, IPatient } from '@ngt/opms'

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../api/dtos';

/*
 * Used to set default values for the context
 */
import { initialIndividualScreeningLogState } from '../store/screeningLog';

import { ScreeningLog } from '../api/screeningLog';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningLogContext {
    screeningLog: ScreeningLog | null;
    loadState: IRequestState<ResponseStatus>,
    saveState: IRequestState<ResponseStatus>,
    deleteState: IRequestState<ResponseStatus>,
    actions: IScreeningLogActions;
}

interface IScreeningLogActions {
    load: () => void;
    clear: () => void;

    asyncSave: (screeningLog: ScreeningLog) => Promise<ScreeningLog | undefined>;
    asyncDelete: (screeningLog: ScreeningLog) => Promise<ScreeningLog | undefined>;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const ScreeningLogContext = React.createContext<IScreeningLogContext>({
    screeningLog: initialIndividualScreeningLogState.screeningLog,
    loadState: initialIndividualScreeningLogState.loadState,
    saveState: initialIndividualScreeningLogState.saveState,
    deleteState: initialIndividualScreeningLogState.deleteState,
    actions: {
        load: () => undefined,
        clear: () => undefined,

        asyncSave: (form) => new Promise<ScreeningLog | undefined>((resolve, screeningLog) => {
            resolve(undefined)
        }),

        asyncDelete: (form) => new Promise<ScreeningLog | undefined>((resolve, screeningLog) => {
            resolve(undefined)
        })
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default ScreeningLogContext;