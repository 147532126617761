/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/*
 * Required to use React components.
 */
import * as React from 'react';
import { List, makeStyles, ListItem, Collapse, ListItemIcon, ListItemText, darken, lighten, Button, useMediaQuery, useTheme } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronCircleDown } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleDown';

import { faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons/faChevronCircleUp';

import { faFileMedicalAlt } from '@fortawesome/pro-duotone-svg-icons/faFileMedicalAlt';

import { faFileUser } from '@fortawesome/pro-duotone-svg-icons/faFileUser';

import { faSlash } from '@fortawesome/pro-duotone-svg-icons/faSlash';

import { Link, useParams } from 'react-router-dom';

import classNames from 'classnames';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../api/dtos';

import ValidationErrorSummary from '../../utility/ValidationErrorSummary';

import FormDefinitionsContext from '../../../contexts/configuration/FormDefinitionsContext';
import ValidationErrorFlag from '../../utility/ValidationErrorFlag';
import OnlinePatientManagementContext from '../../../contexts/OnlinePatientManagementContext';
import { ALL_INSTITUTIONS_CODE } from '../../../constants/institution';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the PatientSummaryForm component.
 */
export interface IPatientSummaryFormProps {
    patient: Dtos.IPatient;
    eventDefinition: Dtos.IEventDefinition;
    eventRepeat: number;
    validation: Dtos.IFormValidationResult;
}
/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => {
    const getColor = theme.palette.type === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;
    const getBorderColor = theme.palette.type === 'light' ? lighten : darken;

    return {
        container: {
            display: 'flex',
            marginTop: -1,
        },
        item: {
            border: `1px solid ${theme.palette.grey[300]}`
        },
        itemDisabled: {
            background: theme.palette.grey[100]
        },
        questions: {
            minWidth: 140,
            display: 'flex',
            padding: theme.spacing(0, 2),
            borderWidth: 1,
            borderStyle: 'solid',

            borderRadius: 0,

            color: getColor(theme.palette.grey[700], 0.6),
            backgroundColor: getBackgroundColor(theme.palette.grey[700], 0.9),
            borderColor: getBorderColor(theme.palette.grey[700], 0.8),
        },
        answeredQuestions: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        divider: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        totalQuestions: {
            maxWidth: 40,
            minWidth: 40,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        success: {
            color: getColor(theme.palette.success.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
            borderColor: getBorderColor(theme.palette.success.main, 0.8),

            cursor: 'default',

            '& > *': {
                color: theme.palette.success.main,
            }
        },
        note: {
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            borderColor: getBorderColor(theme.palette.info.main, 0.8),
            '& > *': {
                color: theme.palette.info.main,
            }
        },
        eligibility: {
            color: getColor(theme.palette.warning.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
            borderColor: getBorderColor(theme.palette.warning.main, 0.8),
            '& > *': {
                color: theme.palette.warning.main
            }
        },
        error: {
            color: getColor(theme.palette.error.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.error.main, 0.9),
            borderColor: getBorderColor(theme.palette.error.main, 0.8),
            '& > *': {
                color: theme.palette.error.main,
            }
        },
        stratification: {
            color: '#fff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.warning.main,
            borderColor: getColor(theme.palette.warning.main, 0.1),
        },
        critical: {
            color: '#fff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.error.main,
            borderColor: getColor(theme.palette.error.main, 0.1),
        },
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const PatientSummaryForm: React.FunctionComponent<IPatientSummaryFormProps> = ({
    patient,
    eventDefinition,
    eventRepeat,
    validation,
    children
}) => {
    const classes = useStyles();

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const params = useParams<Record<string, string>>();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const institutionCode = params[onlinePatientManagement.routeParameters.institutionCode] ?? ALL_INSTITUTIONS_CODE;

    const { formDefinitions } = React.useContext(FormDefinitionsContext);

    const formDefinition = React.useMemo(() => {
        return formDefinitions?.find(fd => fd.id === validation.formDefinitionId);

    }, [formDefinitions, validation.formDefinitionId]);

    const eventDefinitionFormDefinition = React.useMemo(() => {
        return eventDefinition.formDefinitions?.find(edfd => edfd.formDefinitionId === validation.formDefinitionId);
    }, [eventDefinition, validation.formDefinitionId]);

    const [expand, setExpand] = React.useState(false);

    const onClick = React.useCallback(() => {
        setExpand(!expand);
    }, [setExpand, expand])



    const notes = React.useMemo(() => validation?.errors?.filter(e => e.type === Dtos.ValidationErrorType.Warning), [validation?.errors]);

    const eligibilities = React.useMemo(() => validation?.errors?.filter(e => e.type === Dtos.ValidationErrorType.Ineligible), [validation?.errors]);

    const errors = React.useMemo(() => validation?.errors?.filter(e => e.type === Dtos.ValidationErrorType.Normal), [validation?.errors]);

    const stratifications = React.useMemo(() => validation?.errors?.filter(e => e.type === Dtos.ValidationErrorType.StratificationFailure), [validation?.errors]);

    const criticals = React.useMemo(() => validation?.errors?.filter(e => e.type === Dtos.ValidationErrorType.Critical), [validation?.errors]);

    const hasNotes = notes && notes.length > 0;
    const hasEligibilities = eligibilities && eligibilities.length > 0;
    const hasErrors = errors && errors.length > 0;
    const hasStratifications = stratifications && stratifications.length > 0;
    const hasCriticals = criticals && criticals.length > 0;

    if (!validation.visible) {
        return null;
    }

    const showQuestions = isDesktop && (validation.read || validation.validate);

    const listItemProps = validation.read ?
        {
            component: Link,
            to: `/registration/${institutionCode}/${patient.studyNumber}/${eventDefinition.code}/${eventRepeat}/${formDefinition?.code}/${validation.formRepeat}`,
            className: classes.item
        } :
        {
            component: 'div',
            className: `${classes.item} ${classes.itemDisabled}`
        }

    return (
        <>
            <div
                className={classes.container}
            >
                <ListItem
                    button={validation.read as any}
                    {...listItemProps}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon fixedWidth icon={formDefinition?.type === Dtos.FormType.Pro ? faFileUser : faFileMedicalAlt} size="2x" />
                    </ListItemIcon>
                    <ListItemText primary={eventDefinitionFormDefinition?.repeating ? `${formDefinition?.name} (${validation.formRepeat})` : formDefinition?.name} />
                </ListItem>
                {
                    showQuestions && (
                        <>
                            <ValidationErrorFlag
                                validationErrors={validation.errors}
                                showMultiple
                                size="large"
                                onClick={onClick}
                                hoverIcon={expand ? faChevronCircleUp : faChevronCircleDown}
                            />
                            <Button
                                disableRipple={!hasNotes && !hasEligibilities && !hasErrors && !hasStratifications && !hasCriticals}
                                className={classNames(
                                    classes.questions,
                                    { [classes.success]: !hasNotes && !hasEligibilities && !hasErrors && !hasStratifications && !hasCriticals },
                                    { [classes.note]: hasNotes && !hasEligibilities && !hasErrors && !hasStratifications && !hasCriticals },
                                    { [classes.eligibility]: hasEligibilities && !hasErrors && !hasStratifications && !hasCriticals },
                                    { [classes.error]: hasErrors && !hasStratifications && !hasCriticals },
                                    { [classes.stratification]: hasStratifications && !hasCriticals },
                                    { [classes.critical]: hasCriticals }
                                )}
                                onClick={hasNotes || hasEligibilities || hasErrors || hasStratifications || hasCriticals ? onClick : undefined}
                            >
                                <div
                                    className={classes.answeredQuestions}
                                >
                                    {validation?.answeredQuestions}
                                </div>
                                <div
                                    className={classes.divider}
                                >
                                    <FontAwesomeIcon fixedWidth icon={faSlash} flip="horizontal" />
                                </div>
                                <div
                                    className={classes.totalQuestions}
                                >
                                    {validation?.totalQuestions}
                                </div>
                            </Button> 
                        </>
                    )
                }
            </div>
            <Collapse
                in={expand}
            >
                <ValidationErrorSummary
                    validationErrors={validation?.errors}
                    detailedMessage
                />
            </Collapse>
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientSummaryForm;