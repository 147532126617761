/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the treatment reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IIndividualTreatmentState {
    treatment: Dtos.ITreatment | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface ITreatmentState {
    byId: Record<number, IIndividualTreatmentState>;
}

export interface ITreatmentStore {
    treatment: ITreatmentState;
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all treatment reducer state changes and actions. 
 */
export class TreatmentReducer extends ImmerReducer<ITreatmentState>
{
    public loadById(id: number) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualTreatmentState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Pending
        }
    }

    public loadByIdSuccess(id: number, treatment?: Dtos.ITreatment) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualTreatmentState };
        }

        this.draftState.byId[id].treatment = treatment ? treatment : null;

        this.draftState.byId[id].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdFailure(id: number, responseStatus: Dtos.ResponseStatus) {
        if (!this.draftState.byId[id]) {
            this.draftState.byId[id] = { ...initialIndividualTreatmentState };
        }

        this.draftState.byId[id].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearById(id: number) {
        if (this.draftState.byId[id]) {
            delete this.draftState.byId[id];
        }
    }

    public clearAll() {
        this.draftState = { ...initialTreatmentState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialIndividualTreatmentState: IIndividualTreatmentState = {
    treatment: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialTreatmentState: ITreatmentState = {
    byId: {}
}

export const treatmentActions = createActionCreators(TreatmentReducer);
export const treatmentReducer = createReducerFunction(TreatmentReducer, initialTreatmentState);

const createTreatmentApi = (client: JsonServiceClient) => ({
    loadById: (id: number) => {
        return client.get(new Dtos.TreatmentGetSingleById({ id }));
    }
});

export const createTreatmentLogic = (api: ReturnType<typeof createTreatmentApi>) => {
    const logics = {
        loadById: createLogic<ITreatmentStore, {}, undefined, string, ReturnType<typeof treatmentActions.loadById>>({
            type: treatmentActions.loadById.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.loadById(action.payload);

                    dispatch(treatmentActions.loadByIdSuccess(
                        action.payload,
                        response.treatment
                    ));
                }
                catch (error) {
                    dispatch(treatmentActions.loadByIdFailure(action.payload, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logics.loadById
    ]
}

export const useTreatmentSelector: TypedUseSelectorHook<ITreatmentStore> = useSelector;

export const treatmentSelectors = {
    treatmentById: (state: ITreatmentStore, id: number) => state.treatment.byId[id]?.treatment ?? null,
    loadStateById: (state: ITreatmentStore, id: number) => state.treatment.byId[id]?.loadState ?? initialIndividualTreatmentState.loadState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerTreatmentReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createTreatmentApi(client);

    const logic = createTreatmentLogic(api);

    reducerRegistry.register('treatment', treatmentReducer as Reducer, logic as any);
};

export default registerTreatmentReducer;