/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae notification form component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import Typography from '@material-ui/core/Typography';

import { Permission } from '@ngt/opms-bctapi';

import {
    Checkbox,
    InstitutionContext,
    Form,
    SubmitButton,
    Field,
    Text,
    TextArea,
    FieldGroup,
    FormGrid,
    IFormGridCell,
    Input,
    FileUpload,
    ResponseStatus,
    RouteLoading
} from '@ngt/opms';

import { makeStyles, Button } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { useSaeReviewers } from '../hooks/useSaeReviewers';

import useSaeNotificationForm from '../hooks/useSaeNotificationForm';

import { CheckboxListWrapper } from './CheckboxListWrapper';
import { RequestState } from '@ngt/request-utilities';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeNotificationFormProps {
    investigatorRoles: number[],
    medicalReviewerRoles: number[],
    notifyMode: string
}

interface INotificationForm {
    recipients: number[];
    ccRecipients: Record<number, boolean>;
    notes: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    },
    alert: {
        margin: theme.spacing(0, 0, 3, 0),
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform
];

const SaeNotificationForm: React.FunctionComponent<ISaeNotificationFormProps> = ({
    investigatorRoles,
    medicalReviewerRoles,
    notifyMode
}) => {
    const classes = useStyles();

    const { institution } = React.useContext(InstitutionContext);

    const notificationType = React.useMemo(() => {
        if (notifyMode === 'investigators') {
            return Dtos.SaeNotificationType.NotifyInvestigator
        } else if (notifyMode === 'mreviewer') {
            return Dtos.SaeNotificationType.NotifyMedicalReviewer
        }
    }, [notifyMode])

    const [saeReviewers, saeReviewersLoadState, saeReviewersActions] = useSaeReviewers(notificationType === Dtos.SaeNotificationType.NotifyInvestigator ? institution?.code ?? '' : '', notificationType === Dtos.SaeNotificationType.NotifyInvestigator ? investigatorRoles : medicalReviewerRoles, true);

    const reviewersValues = React.useMemo(() => {
        if (!saeReviewers) {
            return [];
        }

        return Object.keys(saeReviewers).map(i => ({ value: parseInt(i, 10), label: (saeReviewers as any)[i] }));
    }, [saeReviewers]);

    const {
        labels,
        handleSubmit,
        onFormCancel,
        onFormSubmit,
        onFormSubmitFailure,
        onFormSubmitValidationFailure,
        validate
    } = useSaeNotificationForm({ formType: undefined, afterFormSave: undefined, onCancel: undefined, readOnly: false });

    const columns: Array<IFormGridCell<Dtos.SaeDocumentForm>> = [
        {
            name: 'name',
            content: (
                <Input component={Text} />
            )
        },
        {
            name: 'file',
            content: (
                <Input
                    component={FileUpload}
                    existingLink={undefined}
                    existingDownloadLink={undefined} 
                />
            )
        }
    ]

    const initialNotificationForm: Dtos.SaeNotificationForm = {
        recipients: [],
        ccRecipients: [],
        notificationType: notificationType as Dtos.SaeNotificationType,
        notes: '', attachments: []
    }

    if (saeReviewersLoadState.state === RequestState.None || saeReviewersLoadState.state === RequestState.Pending) {
        return (
            <>
                <RouteLoading />
            </>
        );
    }

    return (
        reviewersValues?.length ?
        <>
            <Form
                initialValues={initialNotificationForm}
                onValidate={validate}
                validateOn="onChange"
                allowSubmit={undefined}
                fieldErrors={'default'}
                onSubmit={handleSubmit}
                labels={labels}
                lookups={undefined}
                readOnly={false}
                onSubmitFailed={onFormSubmitFailure}
                onSubmitValidationFailed={onFormSubmitValidationFailure}
            >
                <div
                    className={classes.container}
                >
                    <Typography
                        variant="h2"
                        color="primary"
                    >
                        Please select recipients from the list below, enter notes if required, and click Send.
                    </Typography>
                </div>
                
                <FieldGroup>
                    <Field
                        name="recipients"
                        component={CheckboxListWrapper}
                        values={reviewersValues}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        simpleLabel
                    />

                    <Field
                        name="ccRecipients"
                        component={CheckboxListWrapper}
                        values={reviewersValues}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        simpleLabel
                    />

                    <Field
                        name="notes"
                        component={TextArea}
                        rows={10}
                        rowsMax={20}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        variant="outlined"
                        simpleLabel
                    />
                
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Attach Documents
                    </Typography>

                    <FormGrid
                        name="attachments"
                        columns={columns}
                        type={Dtos.SaeDocumentForm}
                    />
                </FieldGroup>

                <div
                    className={classes.buttonGroup}
                >
                    {
                        <SubmitButton
                            variant="contained"
                            color="primary"
                            onClick={onFormSubmit}
                        >
                            Send
                        </SubmitButton>
                    }

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onFormCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
        </>
        :
        <>
            <div
                className={classes.buttonGroup}
            >
                <Alert
                    icon={<></>}
                    severity="error"
                    className={classes.alert}
                >
                        There are currently no investigators connected to { institution?.name }. Please contact your system administrator.
                </Alert>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onFormCancel}
                >
                    Cancel
                </Button>
            </div>
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default SaeNotificationForm;