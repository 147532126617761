/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the patientStates reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPatientStatesState {
    patientStates: Dtos.IPatientState[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IPatientStatesStore {
    patientStates: IPatientStatesState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createContext = (patientStateId?: number, collaboratingGroupId?: number, countryId?: number) => {
    return `${(patientStateId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}`
}

/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all patientStates reducer state changes and actions. 
 */
export class PatientStatesReducer extends ImmerReducer<IPatientStatesState>
{
    public load() {
        this.draftState.loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(patientStates?: Dtos.IPatientState[]) {
        this.draftState.patientStates = patientStates ? patientStates : null;

        this.draftState.loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(responseStatus?: Dtos.ResponseStatus) {
        this.draftState.loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clear() {
        this.draftState = { ...initialPatientStatesState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialPatientStatesState: IPatientStatesState = {
    patientStates: null,
    loadState: {
        state: RequestState.None
    }
}

export const patientStatesActions = createActionCreators(PatientStatesReducer);
export const patientStatesReducer = createReducerFunction(PatientStatesReducer, initialPatientStatesState);

const createPatientStatesApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.PatientStateGetCollection());
    }
});

const createPatientStatesLogic = (api: ReturnType<typeof createPatientStatesApi>) => {
    const logic = {
        load: createLogic<IPatientStatesStore, {}, undefined, string, ReturnType<typeof patientStatesActions.load>>({
            type: patientStatesActions.load.type,
            process: async ({ action }, dispatch, done) => {
                try {
                    const response = await api.load();

                    dispatch(patientStatesActions.loadSuccess(
                        response.patientStates
                    ));
                }
                catch (error) {
                    dispatch(patientStatesActions.loadFailure(error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

export const usePatientStatesSelector: TypedUseSelectorHook<IPatientStatesStore> = useSelector;

export const patientStatesSelectors = {
    patientStates: (state: IPatientStatesStore) => state.patientStates.patientStates,
    loadState: (state: IPatientStatesStore) => state.patientStates.loadState
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerPatientStatesReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPatientStatesApi(client);

    const logic = createPatientStatesLogic(api);

    reducerRegistry.register('patientStates', patientStatesReducer as Reducer, logic as any);
};

export default registerPatientStatesReducer;