/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the patients reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IIndividualPatientsState {
    patients: Dtos.IPatient[] | null;
    loadState: IRequestState<Dtos.ResponseStatus>;
}

export interface IPatientsState {
    byIds: Record<string, IIndividualPatientsState>;
    byCodes: Record<string, IIndividualPatientsState>;
}

export interface IPatientsStore {
    patients: IPatientsState;
}


/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdsContext = (masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, institutionId?: number | null, patientStateIds?: number[] | null) => {
    const patientStateToken = patientStateIds && patientStateIds.length > 0 ? 
        patientStateIds.filter(i => !!i).sort().reduce((a, b) => `${a}|${b}`, '') :
        undefined;

    return `${(masterGroupId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}-${(institutionId ?? 'null')}-${(patientStateToken ?? 'null')}`;
}

const createCodesContext = (masterGroupId?: string | null, collaboratingGroupId?: string | null, countryId?: string | null, institutionCode?: string | null, patientStateIds?: number[] | null) => {
    const patientStateToken = patientStateIds && patientStateIds.length > 0 ?
        patientStateIds.filter(i => !!i).sort().reduce((a, b) => `${a}|${b}`, '') :
        undefined;

    return `${(masterGroupId ?? 'null')}-${(collaboratingGroupId ?? 'null')}-${(countryId ?? 'null')}-${(institutionCode ?? 'null')}-${(patientStateToken ?? 'null')}`;
}


/*
 * ---------------------------------------------------------------------------------
 * Classes
 * ---------------------------------------------------------------------------------
 */

/**
 * This class is used as a short had to describe all patients reducer state changes and actions. 
 */
export class PatientsReducer extends ImmerReducer<IPatientsState>
{
    public loadByIds(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, institutionId?: number | null, patientStateIds?: number[] | null) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId, institutionId, patientStateIds);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualPatientsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByIdsSuccess(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, institutionId?: number | null, patientStateIds?: number[] | null, patients?: Dtos.IPatient[]) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId, institutionId, patientStateIds);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualPatientsState };
        }

        this.draftState.byIds[context].patients = patients ? patients : null;

        this.draftState.byIds[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByIdsFailure(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, institutionId?: number | null, patientStateIds?: number[] | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId, institutionId, patientStateIds);

        if (!this.draftState.byIds[context]) {
            this.draftState.byIds[context] = { ...initialIndividualPatientsState };
        }

        this.draftState.byIds[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByIds(masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, institutionId?: number | null, patientStateIds?: number[] | null) {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId, institutionId, patientStateIds);

        this.draftState.byIds[context] = { ...initialIndividualPatientsState }
    }

    public loadByCodes(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutionCode?: string | null, patientStateIds?: number[] | null) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualPatientsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadByCodesSuccess(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutionCode?: string | null, patientStateIds?: number[] | null, patients?: Dtos.IPatient[]) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualPatientsState };
        }

        this.draftState.byCodes[context].patients = patients ? patients : null;

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadByCodesFailure(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutionCode?: string | null, patientStateIds?: number[] | null, responseStatus?: Dtos.ResponseStatus) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);

        if (!this.draftState.byCodes[context]) {
            this.draftState.byCodes[context] = { ...initialIndividualPatientsState };
        }

        this.draftState.byCodes[context].loadState = {
            state: RequestState.Failure,
            responseStatus: responseStatus
        };
    }

    public clearByCodes(masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutionCode?: string | null, patientStateIds?: number[] | null) {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);

        this.draftState.byCodes[context] = { ...initialIndividualPatientsState }
    }

    public clearAll() {
        this.draftState = { ...initialPatientsState };
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

export const initialPatientsState: IPatientsState = {
    byIds: {},
    byCodes: {}
}

export const initialIndividualPatientsState: IIndividualPatientsState = {
    patients: null,
    loadState: {
        state: RequestState.None
    }
}

export const patientsActions = createActionCreators(PatientsReducer);
export const patientsReducer = createReducerFunction(PatientsReducer, initialPatientsState);

const createPatientsApi = (client: JsonServiceClient) => ({
    loadByIds: (masterGroupId?: number, collaboratingGroupId?: number, countryId?: number, institutionId?: number, patientStateIds?: number[]) => {
        if (institutionId) {
            return client.get(new Dtos.PatientGetCollectionByInstitutionId({ institutionId, patientStateIds }));
        }
        else if (collaboratingGroupId) {
            return client.get(new Dtos.PatientGetCollectionByCollaboratingGroupId({ collaboratingGroupId, countryId, patientStateIds }));
        }
        else if (masterGroupId) {
            return client.get(new Dtos.PatientGetCollectionByMasterGroupId({ masterGroupId, countryId, patientStateIds }));
        }
        else if (countryId) {
            return client.get(new Dtos.PatientGetCollectionByCountryId({ countryId, patientStateIds }));
        }
        else {
            return client.get(new Dtos.PatientGetCollection({ patientStateIds }));
        }
    },
    loadByCodes: (masterGroupCode?: string, collaboratingGroupCode?: string, countryCode?: string, institutionCode?: string, patientStateIds?: number[]) => {
        
        if (institutionCode) {
            return client.get(new Dtos.PatientGetCollectionByInstitutionCode({ institutionCode, patientStateIds }));
        }
        else if (collaboratingGroupCode) {
            return client.get(new Dtos.PatientGetCollectionByCollaboratingGroupCode({ collaboratingGroupCode, countryCode, patientStateIds }));
        }
        else if (masterGroupCode) {
            return client.get(new Dtos.PatientGetCollectionByMasterGroupCode({ masterGroupCode, countryCode, patientStateIds }));
        }
        else if (countryCode) {
            return client.get(new Dtos.PatientGetCollectionByCountryCode({ countryCode, patientStateIds }));
        }
        else {
            return client.get(new Dtos.PatientGetCollection({ patientStateIds }));
        }
    }
});

const createPatientsLogic = (api: ReturnType<typeof createPatientsApi>) => {
    const logic = {
        loadByIds: createLogic<IPatientsStore, {}, undefined, string, ReturnType<typeof patientsActions.loadByIds>>({
            type: patientsActions.loadByIds.type,
            process: async ({ action }, dispatch, done) => {
                const [masterGroupId, collaboratingGroupId, countryId, institutionId, patientStateIds] = action.payload;

                try {
                    const response = await api.loadByIds(masterGroupId ?? undefined, collaboratingGroupId ?? undefined, countryId ?? undefined, institutionId ?? undefined, patientStateIds ?? undefined);

                    dispatch(patientsActions.loadByIdsSuccess(
                        masterGroupId,
                        collaboratingGroupId,
                        countryId,
                        institutionId,
                        patientStateIds,
                        response.patients
                    ));
                }
                catch (error) {
                    dispatch(patientsActions.loadByIdsFailure(masterGroupId, collaboratingGroupId, countryId, institutionId, patientStateIds, error ? error.responseStatus : undefined));
                }

                done();
            }
        }),
        loadByCodes: createLogic<IPatientsStore, {}, undefined, string, ReturnType<typeof patientsActions.loadByCodes>>({
            type: patientsActions.loadByCodes.type,
            process: async ({ action }, dispatch, done) => {
                const [masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds] = action.payload;

                try {
                    const response = await api.loadByCodes(masterGroupCode ?? undefined, collaboratingGroupCode ?? undefined, countryCode ?? undefined, institutionCode ?? undefined, patientStateIds ?? undefined);

                    dispatch(patientsActions.loadByCodesSuccess(
                        masterGroupCode,
                        collaboratingGroupCode,
                        countryCode,
                        institutionCode,
                        patientStateIds,
                        response.patients
                    ));
                }
                catch (error) {
                    dispatch(patientsActions.loadByCodesFailure(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds, error ? error.responseStatus : undefined));
                }

                done();
            }
        })
    }

    return [
        logic.loadByIds,
        logic.loadByCodes
    ]
};

export const usePatientsSelector: TypedUseSelectorHook<IPatientsStore> = useSelector;

export const patientsSelectors = {
    patientsByIds: (state: IPatientsStore, masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, institutionId?: number | null, patientStateIds?: number[] | null) => {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId, institutionId, patientStateIds);

        if (!state.patients.byIds[context]) {
            return initialIndividualPatientsState.patients;
        }

        return state.patients.byIds[context].patients;
    },
    loadStateByIds: (state: IPatientsStore, masterGroupId?: number | null, collaboratingGroupId?: number | null, countryId?: number | null, institutionId?: number | null, patientStateIds?: number[] | null) => {
        const context = createIdsContext(masterGroupId, collaboratingGroupId, countryId, institutionId, patientStateIds);

        if (!state.patients.byIds[context]) {
            return initialIndividualPatientsState.loadState;
        }

        return state.patients.byIds[context].loadState;
    },
    patientsByCodes: (state: IPatientsStore, masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutionCode?: string | null, patientStateIds?: number[] | null) => {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);

        if (!state.patients.byCodes[context]) {
            return initialIndividualPatientsState.patients;
        }

        return state.patients.byCodes[context].patients;
    },
    loadStateByCodes: (state: IPatientsStore, masterGroupCode?: string | null, collaboratingGroupCode?: string | null, countryCode?: string | null, institutionCode?: string | null, patientStateIds?: number[] | null) => {
        const context = createCodesContext(masterGroupCode, collaboratingGroupCode, countryCode, institutionCode, patientStateIds);

        if (!state.patients.byCodes[context]) {
            return initialIndividualPatientsState.loadState;
        }

        return state.patients.byCodes[context].loadState;
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerPatientsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPatientsApi(client);

    const logic = createPatientsLogic(api);

    reducerRegistry.register('patients', patientsReducer as Reducer, logic as any);
};

export default registerPatientsReducer;