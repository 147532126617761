/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use an collaboratingGroup by id.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to type actions.
 */
import { ActionCreators } from 'immer-reducer';

/*
 * Used to get access to dispatch to dispatch actions to the store.
 */
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to backend types.
 */
import * as Dtos from '../../api/dtos';

/*
 * used to type actions.
 */
import { CollaboratingGroupReducer, collaboratingGroupActions, useCollaboratingGroupSelector, collaboratingGroupSelectors, ICollaboratingGroupStore } from '../../store/modules/data/collaboratingGroup';

/*
 * Used to remove the first parameter from the base action creator function type.
 */
import { OmitFirstArg } from '../../utilities/OmitArgs';

/*
 * Used to type action creators with first parameter bound to a static. 
 */
import { BoundActionCreator } from '../../utilities/BoundActionCreator';

/*
 * Used to bind action creators to the store but keep their assigned type property.
 */
import bindActionCreatorsWithType from '../../utilities/bindActionCreatorsWithType';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IUseCollaboratingGroupByIdActions {
    load: BoundActionCreator<OmitFirstArg<typeof collaboratingGroupActions.loadById>>;
    clear: BoundActionCreator<OmitFirstArg<typeof collaboratingGroupActions.clearById>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useCollaboratingGroupById = <CollaboratingGroupType extends Dtos.ICollaboratingGroup>(id: number, autoLoad?: boolean): [
    CollaboratingGroupType | null,
    IRequestState<Dtos.ResponseStatus>,
    IUseCollaboratingGroupByIdActions
] => {
    const dispatch = useDispatch();

    const actions: IUseCollaboratingGroupByIdActions = React.useMemo(() => {
        const load = () => collaboratingGroupActions.loadById(id);
        load.type = collaboratingGroupActions.loadById.type;

        const clear = () => collaboratingGroupActions.clearById(id);
        clear.type = collaboratingGroupActions.clearById.type;

        return bindActionCreatorsWithType({
            load,
            clear
        }, dispatch);
    }, [collaboratingGroupActions, id, dispatch]);

    const collaboratingGroupSelector = React.useCallback((state: ICollaboratingGroupStore) => {
        return collaboratingGroupSelectors.collaboratingGroupById(state, id);
    }, [collaboratingGroupSelectors.collaboratingGroupById, id]);

    const loadStateSelector = React.useCallback((state: ICollaboratingGroupStore) => {
        return collaboratingGroupSelectors.loadStateById(state, id);
    }, [collaboratingGroupSelectors.loadStateById, id]);

    const collaboratingGroup = useCollaboratingGroupSelector(collaboratingGroupSelector);

    const loadState = useCollaboratingGroupSelector(loadStateSelector);

    React.useEffect(() => {
        if (autoLoad) {
            actions.load();

            return () => {
                actions.clear();
            };
        }

        return () => { };
    }, [autoLoad, id]);

    return [
        collaboratingGroup as CollaboratingGroupType | null,
        loadState,
        actions
    ];
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useCollaboratingGroupById;